import { Component, Input, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtensionMRPUpdateService } from '../../../services/extensionMRPupdate.service';
import { RequestExtMRPUpdateActionItemDetailsDto } from '../../../models/change-sar/extension-MRP-update-Item-list.model';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { ExtMRPUpdateActionItem } from '../../../models/change-sar/extension-MRP-update-line-item.model';
import { CommonService } from 'src/app/core/services/common.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { ExtensionMRPUpdateConst } from 'src/app/core/constants/extensionMRPupdate.const';
import { ExistingROPMinMaxDto, ExtensionMRPUpdate } from '../../../models/change-sar/extension-MRP-update-item.model';
import { PlantDetailsDto } from '../../../models/common/plant-detail.model';
import { SPKService } from 'src/app/features/setup/services/spk.service';
import { SPKDto } from 'src/app/features/setup/models/spk/spk.model';
import { ExtnMRPUpdateStorageLocationsDto } from '../../../models/change-sar/request-line-storage-location.model';
import { ValuationTypeService } from 'src/app/features/setup/services/valuationtype.service';
import { ValuationTypeDto } from 'src/app/features/setup/models/valuationtype/valuationtype.model';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { StorageLocationDetailsDto } from '../../../models/common/storage-location-detail.model';
import { TempActionRequestDto } from '../../../models/temp-actions/temp-action-request.module';
import { UserGroupsService } from 'src/app/features/setup/services/user-groups.service';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { BussinessUnitService } from 'src/app/features/setup/services/bussinessunit.service';
import { BusinessUnitDto } from 'src/app/features/setup/models/bussinessunits/bussinessunits.model';
import { RequestIssueService } from '../../../services/request-issue.service';
import { RequestIssueTypeDto } from '../../../models/sar-request/request-issue/request-issue-types.model';
import { forkJoin } from 'rxjs';
import { AddNewActionItemsAttachmentDetails, AddNewActionItemsAttachmentDetailsDisplay } from '../../../models/common/action-attachment.model';
import { TempActionService } from '../../../services/temp-action.service';
import { DeleteLineItemDto } from '../../../models/common/delete-line-item.model';
import { ExtMRPUpdateActionItemStorageLocations } from '../../../models/change-sar/extension-MRP-Item-detail.model';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { RequestLineService } from '../../../services/request-line.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { BulkCancelComponent } from 'src/app/core/components/bulk-cancel/bulk-cancel.component';
import { MMDActionDto } from '../../../models/approval-details/mmd-approval-caction.mode';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalDetailService } from '../../../services/approvalDetail/approval-details.service';
import { AddNewItemConstant } from 'src/app/core/constants/add-new-item.constant';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { requestConst } from '../../../../../core/constants/request.const';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import * as saveAs from 'file-saver';
import { ExtnMRPUpdateExcelColumnGOMBU } from 'src/app/core/constants/excel-columns-const';
import * as JSZip from 'jszip';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';

@Component({
  selector: 'app-extn-mrp-update',
  templateUrl: './extn-mrp-update.component.html',
  styleUrls: ['./extn-mrp-update.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ExtnMRPUpdateComponent implements OnInit {
  @Input() public requiredParams: NewActionInputParam = {};
  requestIssueId: number = 0;
  materialNumbers: number[] = [];
  materialList: any[] = [];
  requestExtMRPUpdateActionItemDetails: RequestExtMRPUpdateActionItemDetailsDto | undefined;
  selectedItems: any = [];
  isNewWithData = false;
  uploadErrors: string = '';
  countErrors: number = 0;
  actionItems: ExtensionMRPUpdate[] = [];
  plantList: PlantDetailsDto[] = [];
  SPKList: SPKDto[] = [];
  valuationTypeData: ValuationTypeDto[] = [];
  buName: string;
  duplicatePlant_StorageLocation: boolean = false;
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  fileList: File[] = [];
  tempActionRequest!: TempActionRequestDto;
  @Output() TempActionRequestEvent = new EventEmitter<TempActionRequestDto>();
  lineAssignment!: LineAssignmentDto;
  @Output() lineAssignmentEvent = new EventEmitter<LineAssignmentDto>();
  @ViewChild('fileInput') fileInput: any;
  @Output() cancelEvent = new EventEmitter<DeleteLineItemDto>();
  comment: string = '';
  isDraftavailable: boolean = false;
  @Output() isDraftavailableEvent = new EventEmitter<boolean>();
  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  enabledAssignToUser: boolean = false;
  mmdLineItems: number[] = [];
  psaLineItems: number[] = [];
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 0,
    name: "Select Group"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };
  selectedUser = 0;
  businessUnitId: number = 0;
  requestIssueTypeId: number = 0;
  @Input() public isGoupSelected: boolean = true;
  attachmentDetails?: AddNewActionItemsAttachmentDetails[] = [];
  attachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
  isBoth: boolean = false;
  currentLineMumbers: number[] = []
  isShowDraftMessage: boolean = false;
  extnMRPUpdateApprovalExcelColumnGOBU: any = ExtnMRPUpdateExcelColumnGOMBU;
  excelFilePath: string = `ExtensionMRPUpdateTemplate`;
  constructor(public router: Router,
    private route: ActivatedRoute,
    private extensionMRPUpdateService: ExtensionMRPUpdateService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private materialInfoService: MaterialInfoService,
    private spkService: SPKService,
    private valuationTypeService: ValuationTypeService,
    private userGroupsService: UserGroupsService,
    private sarUsersService: SarUsersService,
    private buService: BussinessUnitService,
    private requestIssueService: RequestIssueService,
    private tempActionService: TempActionService,
    public requestLineService: RequestLineService,
    public approvalDetailService: ApprovalDetailService) {
    this.buName = this.commonService.getUserBUName();

  }

  ngOnInit(): void {
    this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
    this.requestIssueId = this.requiredParams.requestIssueId!;
    this.isShowDraftMessage = true;
    this.getExtnMRPUpdateDetail();
    this.getMaterialList();
    this.getPlantList();
    this.getValuationTypeList();
  }

  getExtnMRPUpdateDetail() {
    if (this.commonService.isNullOrEmptyOrUndefined(this.requiredParams.requestId) ||
      this.commonService.isNullOrEmptyOrUndefined(this.requiredParams.requestIssueId)) {
      return false;
    }

    const getMRPUpdateDetailAPI = this.extensionMRPUpdateService.getMRPUpdateDetail(this.requiredParams.requestId!, this.requiredParams.requestIssueId!);
    const getBUAllAPI = this.buService.getBUAll();
    const getRequestIssueTypesAPI = this.requestIssueService.getRequestIssueTypes(true);
    forkJoin([getMRPUpdateDetailAPI, getBUAllAPI, getRequestIssueTypesAPI]).
      subscribe(([getMRPUpdateDetailAPIData, getBUAllAPIData, getRequestIssueTypesAPIData]) => {
        if (getMRPUpdateDetailAPIData.items == null || (getMRPUpdateDetailAPIData.items != null && getMRPUpdateDetailAPIData!.items!.length == 0)) {
          this.requestExtMRPUpdateActionItemDetails = new RequestExtMRPUpdateActionItemDetailsDto();
          const path = `false/false/${this.requiredParams?.requestId}`
          this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
            state: { requestId: this.requiredParams.requestId }, relativeTo: this.route.parent
          });
          return false;
        }
        this.requestExtMRPUpdateActionItemDetails = getMRPUpdateDetailAPIData;
        this.attachmentDetails = getMRPUpdateDetailAPIData.attachments;
        this.attachmentDetailsDisplay = getMRPUpdateDetailAPIData.attachments ? getMRPUpdateDetailAPIData.attachments : [];
        getMRPUpdateDetailAPIData.items.forEach((element: ExtMRPUpdateActionItem, i) => {
          element.extMRPUpdateActionItemStorageLocations.forEach((subEle: ExtMRPUpdateActionItemStorageLocations, j) => {
            let plantName = subEle.plantName!.length >= 4 ? subEle.plantName!.substring(0, 4) : subEle.plantName!
            this.getExistingROPMinAndMaxValue(element.materialItemNumber, plantName, subEle.storageLocatinName!,
              i, j)
          })
        });
        this.materialNumbers = this.requestExtMRPUpdateActionItemDetails.items.map(x => { return x.materialItemNumber });
        let requestLineIdList = this.requestExtMRPUpdateActionItemDetails.items.map(x => { return x.requestLineId });
        let deleteLineItem: DeleteLineItemDto = {
          requestIssueId: this.requiredParams.requestIssueId!,
          requestLineIds: requestLineIdList
        }
        this.cancelEvent.emit(deleteLineItem);
        let draftstotal = getMRPUpdateDetailAPIData.items.filter((item) => item.status.toLocaleLowerCase() === 'draft').length;
        this.setBuId(getBUAllAPIData);
        if (draftstotal !== 0) {
          this.isDraftavailable = true;
          if (getMRPUpdateDetailAPIData.items.length === draftstotal && this.isShowDraftMessage) {
            this.commonService.showNotificationMessage(`Request  Action ${this.requiredParams.actionNumber?.toString()} have all line items are in Draft state and has not submitted for approval yet. Please submit for approval.`, 'info', 'center');
          }
          this.isDraftavailableEvent.emit(true);
          if (this.requestExtMRPUpdateActionItemDetails.assignmentDetails && this.requestExtMRPUpdateActionItemDetails.assignmentDetails.comment) {
            this.comment = this.requestExtMRPUpdateActionItemDetails.assignmentDetails.comment;
          }
          this.setRequestIssueTypeId(getRequestIssueTypesAPIData);
          this.settempActionRequest();
          this.TempActionRequestEvent.emit(this.tempActionRequest);
          this.setLineAssignment();
          if (!this.requiredParams.isNew) {
            this.getAssignmentGroups();
          }
          this.lineAssignmentEvent.emit(this.lineAssignment);
        }
        this.isShowDraftMessage = false;
      })
  }


  AddExtMRPNewItem() {
    let isDataExit = false;
    if (this.requestExtMRPUpdateActionItemDetails! && this.requestExtMRPUpdateActionItemDetails!.items.length > 0) {
      isDataExit = true;
    }
    this.router.navigate(["../" + 'changeSARextMRPaddnewitem'], {
      state: {
        isNew: true, requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId, requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType, lineItem: 0,
        requestLineId: 0, materialNumbers: this.materialNumbers,
        isDataExist: isDataExit
      },
      relativeTo: this.route
    },
    );
  }

  rejectLineItem(dataItem: ExtMRPUpdateActionItem, index: number) {
    let delItem = new DeleteLineItemDto();
    delItem.requestIssueId = this.requestIssueId;
    delItem.requestLineIds = [];
    delItem.requestLineIds.push(dataItem.requestLineId);
    this.deleteLineItem = delItem;
    this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
      this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'info', 'right');
      this.getExtnMRPUpdateDetail();
    })
  }

  editLineItem(dataItem: ExtMRPUpdateActionItem, index: number) {
    this.router.navigate(["../" + 'changeSARextMRPaddnewitem'], {
      state: {
        isNew: false, requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId, requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType, lineItem: dataItem.lineNumber,
        requestLineId: dataItem.requestLineId, materialNumber: dataItem.materialItemNumber, itemStatus: dataItem.status
        , buId: this.businessUnitId
      },
      relativeTo: this.route
    },
    );
  }
  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      if (this.requiredParams.isNew === true) {
        const filteredData = outputDtata.filter(x => !this.materialNumbers.includes(x));
        this.materialList = filteredData;
      } else {
        this.materialList = outputDtata;
      }
    });
  }
  getPlantList() {
    this.extensionMRPUpdateService.getPlantDetail(this.buName).subscribe((response: PlantDetailsDto[]) => {
      this.plantList = response;
      this.getSPKList();
    });
  }
  getSPKList() {
    this.spkService.getSPKAll().subscribe((outputDtata: SPKDto[]) => {
      this.SPKList = outputDtata;
    });
  }
  getValuationTypeList() {
    this.valuationTypeService.getValuationTypeAll().subscribe((outputDtata: ValuationTypeDto[]) => {
      this.valuationTypeData = outputDtata;
    });
  }
  navGenerateTemplate() {
    this.router.navigate(['./extensionmrpupdateexceltemplate'], { state: { isNew: this.requiredParams.isNew, requestId: this.requiredParams.requestId, requestNumber: this.requiredParams.requestNumber, requestIssueId: this.requiredParams.requestIssueId, actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType }, relativeTo: this.route.parent })
  }
  public import(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === "Save" && r.isEdit) {
        this.editExcelData(r);
      }
      else if (r.action === "Save" && !r.isEdit) {
        this.addExcelData(r);
      }
    });
  }

  addExcelData(r: any) {
    this.actionItems = [];
    this.countErrors = 0;
    this.uploadErrors = '';
    this.duplicatePlant_StorageLocation = false;
    let arr = JSON.parse(r.value)['Item Inventory'] as Array<any>;
    let filteredArr = arr.filter(i => !(Object.keys(i).length === 2 && i['__EMPTY_2'] === '' && i['__EMPTY_12'] === ''));
    filteredArr = filteredArr.slice(2);
    if (filteredArr.length === 0) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.noRows, 'warning', 'center');
    }
    else {
      let index = -1;
      for (let item of filteredArr) {
        index++;
        let _extensionMRPUpdate: ExtensionMRPUpdate = this.getExtnMRPUpdateItem();
        let extnMRPUpdateStorageLocationsDtos: ExtnMRPUpdateStorageLocationsDto;
        let plantIndex = -1;
        let indexItem: number = -1;
        if (this.invalidMaterialNumber(item['__EMPTY'] ? item['__EMPTY'].toString() : '')) {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.invalidMaterialNumber);
        }
        else if (this.duplicateMaterialNumber(item)) {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.duplicateMaterialNumber);
        }
        else {
          _extensionMRPUpdate.materialNumber = item['__EMPTY'].toString();
          _extensionMRPUpdate.itemNumber = item['__EMPTY'].toString();
          indexItem = this.ItemIndex(_extensionMRPUpdate.materialNumber);
        }

        if (indexItem > -1) {
          extnMRPUpdateStorageLocationsDtos = this.setExtnMRPUpdateStorageLocation(item, index, plantIndex, r.isEdit);
          if (this.isAvailablePlantStorageCombo(indexItem, extnMRPUpdateStorageLocationsDtos.plantName, extnMRPUpdateStorageLocationsDtos.storageLocatinName)) {
            this.duplicatePlant_StorageLocation = true;
          }
          this.actionItems[indexItem].extnMRPUpdateStorageLocationsDtos.push(extnMRPUpdateStorageLocationsDtos);
        }
        else {
          extnMRPUpdateStorageLocationsDtos = this.setExtnMRPUpdateStorageLocation(item, index, plantIndex, r.isEdit)
          _extensionMRPUpdate.extnMRPUpdateStorageLocationsDtos.push(extnMRPUpdateStorageLocationsDtos);
          _extensionMRPUpdate.requesterNotes = this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_13']) ? '' : item['__EMPTY_13'].toString();
          this.actionItems.push(_extensionMRPUpdate);
        }
      }
      if (this.countErrors !== 0) {
        let errormessage: string = '';
        if (this.countErrors > 8) errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
        else errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
        errormessage += this.uploadErrors;
        this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
      }
      else if (this.duplicatePlant_StorageLocation) {
        this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.plantAndStorageLocationDuplication, 'error', 'center');
      }
      else {
        this.extensionMRPUpdateService.importExtensionMRPUpdateAction(this.actionItems).subscribe((response: any) => {
          this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.successfulUploadMessage, 'success', 'right');
          this.requiredParams.requestIssueId = response.requestIssueId;
          this.requiredParams.isNew = false;
          this.getExtnMRPUpdateDetail();
          this.updateSessionStorage(response.requestIssueId);
        });
      }
    }
  }

  editExcelData(r: any) {
    this.actionItems = [];
    this.countErrors = 0;
    this.uploadErrors = '';
    this.duplicatePlant_StorageLocation = false;
    let arr = JSON.parse(r.value)['Item Inventory'] as Array<any>;
    let filteredArr = arr.filter(i => !(Object.keys(i).length === 2 && i['__EMPTY_2'] === '' && i['__EMPTY_12'] === ''));
    filteredArr = filteredArr.slice(2);
    if (filteredArr.length === 0) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.noRows, 'warning', 'center');
    }
    else {
      let index = -1;
      for (let item of filteredArr) {
        index++;
        let _extensionMRPUpdate: ExtensionMRPUpdate = this.getExtnMRPUpdateItem();
        let extnMRPUpdateStorageLocationsDtos: ExtnMRPUpdateStorageLocationsDto;
        let plantIndex = -1;
        let indexItem: number = -1;
        if (this.invalidMaterialNumber(item['__EMPTY'] ? item['__EMPTY'].toString() : '')) {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.invalidMaterialNumber);
        }
        else if (this.duplicateMaterialNumber(item)) {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.duplicateMaterialNumber);
        }
        else {
          _extensionMRPUpdate.materialNumber = item['__EMPTY'].toString();
          _extensionMRPUpdate.itemNumber = item['__EMPTY'].toString();
          indexItem = this.ItemIndex(_extensionMRPUpdate.materialNumber);
        }

        if (indexItem > -1) {
          extnMRPUpdateStorageLocationsDtos = this.setExtnMRPUpdateStorageLocation(item, index, plantIndex, r.isEdit);
          _extensionMRPUpdate.id = extnMRPUpdateStorageLocationsDtos.requestLineId;
          if (this.isAvailablePlantStorageCombo(indexItem, extnMRPUpdateStorageLocationsDtos.plantName, extnMRPUpdateStorageLocationsDtos.storageLocatinName)) {
            this.duplicatePlant_StorageLocation = true;
          }
          this.actionItems[indexItem].extnMRPUpdateStorageLocationsDtos.push(extnMRPUpdateStorageLocationsDtos);
        }
        else {
          extnMRPUpdateStorageLocationsDtos = this.setExtnMRPUpdateStorageLocation(item, index, plantIndex, r.isEdit)
          _extensionMRPUpdate.extnMRPUpdateStorageLocationsDtos.push(extnMRPUpdateStorageLocationsDtos);
          _extensionMRPUpdate.id = extnMRPUpdateStorageLocationsDtos.requestLineId;
          _extensionMRPUpdate.requesterNotes = this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_13']) ? '' : item['__EMPTY_13'].toString();
          this.actionItems.push(_extensionMRPUpdate);
        }
      }
      if (this.countErrors !== 0) {
        let errormessage: string = '';
        if (this.countErrors > 8) errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
        else errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
        errormessage += this.uploadErrors;
        this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
      }
      else if (this.duplicatePlant_StorageLocation) {
        this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.plantAndStorageLocationDuplication, 'error', 'center');
      }
      else {
        //update case
        this.actionItems.forEach((x: ExtensionMRPUpdate) => {
          let storageLocations: Array<ExtnMRPUpdateStorageLocationsDto> = x.extnMRPUpdateStorageLocationsDtos?.filter((storageLocations: ExtnMRPUpdateStorageLocationsDto) => storageLocations.requestLineId === x.id && storageLocations.id !== 0);
          if (storageLocations !== null && storageLocations.length > 0) {
            storageLocations.forEach((location: ExtnMRPUpdateStorageLocationsDto, index: number) => {
              storageLocations[index].operation = 2;
            });
            x.operation = 2;
          }

          //delete case
          this.requestExtMRPUpdateActionItemDetails?.items.forEach((items: ExtMRPUpdateActionItem) => {
            items.extMRPUpdateActionItemStorageLocations.forEach((item: ExtMRPUpdateActionItemStorageLocations) => {
              if (x.id !== 0 && items.materialItemNumber.toString() === x.itemNumber) {
                let deletedItem = x.extnMRPUpdateStorageLocationsDtos?.filter((loc: ExtnMRPUpdateStorageLocationsDto) => loc.id !== 0).find((y: ExtnMRPUpdateStorageLocationsDto) => y.id === item.id);
                if (deletedItem === null || deletedItem === undefined) {
                  let _extnMRPUpdateStorageLocation: ExtnMRPUpdateStorageLocationsDto = this.getStorageLocation();
                  _extnMRPUpdateStorageLocation.operation = 3;
                  _extnMRPUpdateStorageLocation.id = item.id;
                  _extnMRPUpdateStorageLocation.plantName = item.plantName;
                  _extnMRPUpdateStorageLocation.requestLineId = items.requestLineId;
                  _extnMRPUpdateStorageLocation.minimum = item.minimum;
                  _extnMRPUpdateStorageLocation.storageLocatinName = item.storageLocatinName;
                  _extnMRPUpdateStorageLocation.maximum = item.maximum;
                  x.extnMRPUpdateStorageLocationsDtos.push(_extnMRPUpdateStorageLocation);
                  x.operation = 2;
                }
              }
            });
          });

          //Add case
          let storageLocationForAddition: Array<ExtnMRPUpdateStorageLocationsDto> = x.extnMRPUpdateStorageLocationsDtos.filter((storageLocations: ExtnMRPUpdateStorageLocationsDto) => storageLocations.requestLineId !== 0 && storageLocations.id === 0);
          if (storageLocationForAddition != null && storageLocationForAddition.length > 0) {
            storageLocationForAddition.forEach((location: ExtnMRPUpdateStorageLocationsDto, index: number) => {
              storageLocationForAddition[index].operation = 1;
            });
            x.operation = 2;
          }

          //Add Request Line        
          let storageLoc: Array<ExtnMRPUpdateStorageLocationsDto> = x.extnMRPUpdateStorageLocationsDtos?.filter((storageLocations: ExtnMRPUpdateStorageLocationsDto) => storageLocations.requestLineId === null || storageLocations.requestLineId === undefined || storageLocations.requestLineId === 0);
          if (storageLoc !== null && storageLoc.length > 0) {
            storageLoc.forEach((location: ExtnMRPUpdateStorageLocationsDto) => {
              location.operation = 1;
            });
          }
        });

        this.extensionMRPUpdateService.importExtensionMRPUpdateAction(this.actionItems).subscribe((response: any) => {
          this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.excelvalidations.successfulUploadMessage, 'success', 'right');
          this.requiredParams.requestIssueId = response.requestIssueId;
          this.requiredParams.isNew = false;
          this.getExtnMRPUpdateDetail();
          this.updateSessionStorage(response.requestIssueId);
        });
      }

    }
  }

  setExtnMRPUpdateStorageLocation(item: any, index: number, plantIndex: number, isEdit: boolean) {
    let _extnMRPUpdateStorageLocation: ExtnMRPUpdateStorageLocationsDto = this.getStorageLocation();
    if (!this.commonService.isNullOrEmptyOrUndefined(item[' Change SAR - Extension/MRP Updates'])) {
      if (item[' Change SAR - Extension/MRP Updates'] !== null && item[' Change SAR - Extension/MRP Updates'].toString().includes('-')) {
        _extnMRPUpdateStorageLocation.requestLineId = parseInt(item[' Change SAR - Extension/MRP Updates'].split('-')[0]);
        if (_extnMRPUpdateStorageLocation.requestLineId) {
          _extnMRPUpdateStorageLocation.id = item[' Change SAR - Extension/MRP Updates'].split('-')[1] === '' ? 0 : parseInt(item[' Change SAR - Extension/MRP Updates'].split('-')[1]);
        }
        if (_extnMRPUpdateStorageLocation.requestLineId === 0) {
          _extnMRPUpdateStorageLocation.id = item[' Change SAR - Extension/MRP Updates'].split('-')[1] === '' ? 0 : parseInt(item[' Change SAR - Extension/MRP Updates'].split('-')[1]);
          if (_extnMRPUpdateStorageLocation.id > 0) {
            this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.mmsKeyNotPresent);
          }
        }
        if (_extnMRPUpdateStorageLocation.requestLineId > 0 && _extnMRPUpdateStorageLocation.id! > 0 && this.invalidMMSKey(_extnMRPUpdateStorageLocation.id, _extnMRPUpdateStorageLocation.requestLineId)) {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.mmsKeyNotPresent);
        }
      }
      else if ((item[' Change SAR - Extension/MRP Updates'] !== null && item[' Change SAR - Extension/MRP Updates'] !== undefined) && !(item[' Change SAR - Extension/MRP Updates'].toString().includes('-'))) {
        this.updateUploadError(index, ExtensionMRPUpdateConst.storageLocationErrorMessage);
      }
    }
    if (!this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY'])) {
      _extnMRPUpdateStorageLocation.itemNumber = item['__EMPTY'].toString();
    }

    if (this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_1'])) {
      this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.plantValidation);
    }
    else {
      plantIndex = this.plantList.findIndex((e: PlantDetailsDto) => e.plantName === this.getPlantCode(item['__EMPTY_1']));
      if (plantIndex > -1) {
        _extnMRPUpdateStorageLocation.plantName = this.plantList[plantIndex].plantName;
        _extnMRPUpdateStorageLocation.plantDescription = this.plantList[plantIndex].plantDiscription;
      }
      else {
        this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.plantValidation);
      }
    }
    // if (this.commonService.isEmptyOrUndefined(item['__EMPTY_2'])) {
    //   this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.unitPriceValidation);
    // }
    if (this.buName === 'SJVBU') {
      if (!this.commonService.isEmptyOrUndefined(item['__EMPTY_3'])) {
        let spkCode = this.getCode(item['__EMPTY_3']);
        if (isEdit) {
          _extnMRPUpdateStorageLocation.spkId = this.SPKList.filter((spk: SPKDto) => spk.name === spkCode)[0].id;
        }
        else {
          _extnMRPUpdateStorageLocation.spkId = this.SPKList.filter((spk: SPKDto) => spk.code === spkCode)[0].id;
        }
      }
    }
    else {
      if (!this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_3'])) {
        this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.spkForSJVBUValidation);
      }
    }
    _extnMRPUpdateStorageLocation.isPlantForDeletion = (!this.commonService.isEmptyOrUndefined(item['__EMPTY_4']) && item['__EMPTY_4'].toLocaleLowerCase() === 'y') ? true : false;
    if (!this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_5'])) {
      if (plantIndex > -1) {
        let strLocation = this.plantList[plantIndex].storageLocationDetails.filter((strLocation: StorageLocationDetailsDto) => strLocation.storageLocation === item['__EMPTY_5']);
        if (strLocation.length !== 0) {
          _extnMRPUpdateStorageLocation.storageLocatinName = item['__EMPTY_5'];
          _extnMRPUpdateStorageLocation.storageLocationDescription = strLocation[0].storageLocationDiscription;
        }
        else {
          this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.storageLocationValidation);
        }
      }
      else {
        // let exist = (if storage location exists in current BU ) ? true : false;
        // if (!exist) {
        //   this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.storageLocationValidation);
        // }
      }
    }
    _extnMRPUpdateStorageLocation.isStorageLocationForDeletion = (!this.commonService.isEmptyOrUndefined(item['__EMPTY_6']) && item['__EMPTY_6'].toLocaleLowerCase() === 'y') ? true : false;
    let reg = new RegExp('^[0-9]*$');
    if (this.commonService.isNullOrEmptyOrUndefinedOnly(item['__EMPTY_7']) || item['__EMPTY_7'].toString().trim() === '') {
      _extnMRPUpdateStorageLocation.minimum = 0;
    }
    else if (reg.test(item['__EMPTY_7'])) {
      _extnMRPUpdateStorageLocation.minimum = this.commonService.isNAN(item['__EMPTY_7']) ? 0 : parseInt(item['__EMPTY_7']);
    }
    else {
      this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.minimumValidation);
    }
    if (this.commonService.isNullOrEmptyOrUndefinedOnly(item['__EMPTY_8']) || item['__EMPTY_8'].toString().trim() === '') {
      _extnMRPUpdateStorageLocation.maximum = 0;
    }
    else if (reg.test(item['__EMPTY_8'])) {
      _extnMRPUpdateStorageLocation.maximum = this.commonService.isNAN(item['__EMPTY_8']) ? 0 : parseInt(item['__EMPTY_8']);
    }
    else {
      this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.maximumValidation);
    }
    if (_extnMRPUpdateStorageLocation.maximum! < _extnMRPUpdateStorageLocation.minimum!) {
      this.updateUploadError(index, ExtensionMRPUpdateConst.excelvalidations.maximumValueGreaterValidation);
    }
    _extnMRPUpdateStorageLocation.bin = this.commonService.isNullOrEmptyOrUndefined(item['__EMPTY_9']) ? '' : item['__EMPTY_9'].toString();
    let valuationtypeindex = this.valuationTypeData.findIndex(x => x.name === item['__EMPTY_10']);
    if (valuationtypeindex > -1) {
      _extnMRPUpdateStorageLocation.valuationTypeId = this.valuationTypeData[valuationtypeindex].id;
    }
    _extnMRPUpdateStorageLocation.isValuationTypeForDeletion = (!this.commonService.isEmptyOrUndefined(item['__EMPTY_11']) && item['__EMPTY_11'].toLocaleLowerCase() === 'y') ? true : false;
    return _extnMRPUpdateStorageLocation
  }
  getStorageLocation(): ExtnMRPUpdateStorageLocationsDto {
    return {
      id: 0,
      requestLineId: 0,
      plantName: '',
      plantDescription: '',
      storageLocatinName: '',
      storageLocationDescription: '',
      bin: '',
      createdBy: this.commonService.getUserEmailId(),
      modifiedBy: this.commonService.getUserEmailId(),
      isPlantForDeletion: false,
      isStorageLocationForDeletion: false,
      isValuationTypeForDeletion: false,
      minimum: 0,
      maximum: 0,
      estimatedInventoryValue: 0,
      operation: 1,
      existingMinimum: '0',
      existingMaximum: '0'
    }
  }
  getExtnMRPUpdateItem(): ExtensionMRPUpdate {
    return {
      requesterNotes: '',
      itemNumber: '',
      createdBy: this.commonService.getUserEmailId(),
      modifiedBy: this.commonService.getUserEmailId(),
      lineNumber: 0,
      id: 0,
      requestId: this.requiredParams.requestId ? this.requiredParams.requestId : 0,
      requestIssueId: this.requiredParams.isNew ? 0 : this.requiredParams.requestIssueId,
      requestIssueTypeId: 1,
      materialNumber: '',
      operation: 1,
      extnMRPUpdateStorageLocationsDtos: [],
    };
  }
  invalidMaterialNumber(matNum: string) {
    if (this.commonService.isNAN(matNum)) {
      return true;
    }
    if (!this.materialList.includes(matNum)) {
      return true;
    }
    return false;
  }

  duplicateMaterialNumber(excelItem: any) {
    let filteredDataLength: boolean = false;
    let matNum = excelItem['__EMPTY'].toString();
    let mmsKey = excelItem[' Change SAR - Extension/MRP Updates'];
    if (this.requestExtMRPUpdateActionItemDetails) {
      let filteredRecord = this.requestExtMRPUpdateActionItemDetails?.items.filter((item: ExtMRPUpdateActionItem) => item.materialItemNumber.toString() === matNum && (mmsKey === null || mmsKey === undefined));
      filteredDataLength = filteredRecord!.length >= 1 ? true : false;
    }
    return filteredDataLength;
  }

  invalidMMSKey(id: number | undefined, requestLineId: number) {
    let isStorageLocationPresent: boolean = true;
    if (this.requestExtMRPUpdateActionItemDetails) {
      this.requestExtMRPUpdateActionItemDetails?.items.filter((y: ExtMRPUpdateActionItem) => y.requestLineId === requestLineId).forEach((item: ExtMRPUpdateActionItem) => {
        let filteredRecord = item.extMRPUpdateActionItemStorageLocations.filter((x: ExtMRPUpdateActionItemStorageLocations) => x.id === id);
        isStorageLocationPresent = filteredRecord.length >= 1 ? false : true;
      });
    }
    return isStorageLocationPresent;
  }

  ItemIndex(materialNumber: string): number {
    const index: number = this.actionItems.findIndex(obj =>
      obj.materialNumber === materialNumber
    );
    return index;
  }
  getExistingROPMinAndMaxValue(matNumber: number, plantName: string, storageLocationName: string, index: number, subIndex: number) {
    if ((this.commonService.isNullOrEmptyOrUndefined(plantName)
    ) ||
      (this.commonService.isNullOrEmptyOrUndefined(storageLocationName))) {
      this.requestExtMRPUpdateActionItemDetails!.items[index].extMRPUpdateActionItemStorageLocations[subIndex].existingMinimum = 0;
      this.requestExtMRPUpdateActionItemDetails!.items[index].extMRPUpdateActionItemStorageLocations[subIndex].existingMaximum = 0;
      return false;
    }
    this.extensionMRPUpdateService.getExistingROPMinAndMaxValue(plantName, storageLocationName, matNumber)
      .subscribe((existingROPMinMaxDto: ExistingROPMinMaxDto) => {
        this.requestExtMRPUpdateActionItemDetails!.items[index].extMRPUpdateActionItemStorageLocations[subIndex].existingMaximum = parseFloat(existingROPMinMaxDto.max?.toString()!);
        this.requestExtMRPUpdateActionItemDetails!.items[index].extMRPUpdateActionItemStorageLocations[subIndex].existingMinimum = parseFloat(existingROPMinMaxDto.min?.toString()!);
      })
  }
  updateUploadError(rowIndex: number, message: string) {
    this.countErrors++;
    if (this.countErrors <= 8) {
      this.uploadErrors += `Row ${rowIndex + 4}: ${message}<br/>`
    }
  }
  getPlantCode(st: string) {
    return st.split('-')[0].trim();
  }
  getCode(st: string) {
    return st.split(':')[0].trim();
  }
  isAvailablePlantStorageCombo(indexItem: number, plantCode?: string, storageCode?: string) {
    let index = this.actionItems[indexItem].extnMRPUpdateStorageLocationsDtos!.findIndex(item =>
      item.plantName === plantCode
      && item.storageLocatinName === storageCode)
    return index > -1 ? true : false;
  }

  updateSessionStorage(requestIssueId: number) {
    let sarnewaction = JSON.parse(sessionStorage.getItem('sarnewaction')!);
    sarnewaction.isNew = false;
    sarnewaction.changeType = 'Extension MRP Update';
    sarnewaction.requestIssueId = requestIssueId;
    sessionStorage.setItem('sarnewaction', JSON.stringify(sarnewaction));
  }
  onFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.attachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.TempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  settempActionRequest() {
    this.tempActionRequest = new TempActionRequestDto();
    this.tempActionRequest.requestId = this.requiredParams.requestId;
    this.tempActionRequest.requestIssueId = this.requiredParams.requestIssueId;
    this.tempActionRequest.requestIssueTypeId = this.requestIssueTypeId;
    this.tempActionRequest.attachments = [];
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequest.createdBy = this.commonService.getUserEmailId();
    this.tempActionRequest.modifiedBy = this.commonService.getUserEmailId();
  }

  setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
    const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }
  removeFile(index: number) {
    if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.attachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.TempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.attachmentDetailsDisplay!.splice(index, 1);
    }
  }
  downloadFile(index: number) {
    if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }
  onChangeComment() {
    this.lineAssignment.comment = this.comment;
    this.tempActionRequest.comment = this.comment;
    this.TempActionRequestEvent.emit(this.tempActionRequest);
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }
  setBuId(BUData: BusinessUnitDto[]) {
    let bu: BusinessUnitDto[] = BUData.filter(e => e.shortName === this.buName);
    if (bu.length > 0) {
      this.businessUnitId = bu[0].id;
    }
  }
  setRequestIssueTypeId(RequestIssueTypes: RequestIssueTypeDto[]) {
    let actionType: string = 'Extension MRP Update';
    let requestIssueType: RequestIssueTypeDto[] = RequestIssueTypes.filter(e => e.label?.toLocaleLowerCase() === actionType.toLocaleLowerCase()) as RequestIssueTypeDto[];
    if (requestIssueType.length > 0) {
      this.requestIssueTypeId = requestIssueType[0].id as number;
    }
  }
  setLineAssignment() {
    this.lineAssignment = new LineAssignmentDto();
    this.lineAssignment.requestId = this.requiredParams.requestId;
    this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
    this.lineAssignment.requestIssueTypeId = this.requestIssueTypeId;
    this.lineAssignment.businessUnitId = this.businessUnitId;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    this.lineAssignment.state = 0;
    this.lineAssignment.isGeneralComment = false;
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.createdBy = this.commonService.getUserEmailId();
    this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
    this.lineAssignment.attachments = [];
  }
  getAssignmentGroups() {
    let isMMD = false;
    let isSA = false;
    this.isBoth = false;
    let groupType = 1;
    this.psaLineItems = [];
    this.mmdLineItems = [];
    this.requestExtMRPUpdateActionItemDetails!.items.forEach((element, index) => {
      if (element.status === 'Draft') {
        if (element.extMRPUpdateActionItemStorageLocations.reduce((sum, current) => sum + current.maximum!, 0) > 0) {
          isSA = true;
          this.psaLineItems.push(element.lineNumber);
        }
        else {
          isMMD = true;
          this.mmdLineItems.push(element.lineNumber);
        }
      }
    })

    if (isSA && !isMMD) {
      groupType = 2
    }
    if (isSA && isMMD) {
      this.isBoth = true;
      this.tab('psa');
    }
    if (!this.isBoth) {
      this.userGroupsService.getAssignmentGroups(groupType).subscribe((data: any) => {
        this.assignToGroups = data;
      })
    }
  }

  tab(eventName: string) {
    this.lineAssignment.isMixedValues = true;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    if (eventName === 'psa') {
      this.currentLineMumbers = this.psaLineItems;
    }
    else {
      this.currentLineMumbers = this.mmdLineItems;
    }
  }
  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }
  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = 0;
    this.selectedUser = 0;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }
  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }
  bulkCancel() {
    let fItems = this.requestExtMRPUpdateActionItemDetails!.items
      .filter(({ requestLineId }) => this.selectedItems.includes(requestLineId));
    let draftCount = fItems.filter(x => x.status === 'Draft').length;
    let rejectCount = fItems.filter(x => x.status === 'Rejected').length;
    if (draftCount > 0 && rejectCount > 0) {
      this.commonService.showNotificationMessage(AddNewItemConstant.bulkActionError, 'error', 'right');
      return false;
    }
    const dialog: DialogRef = this.dialogService.open({
      content: BulkCancelComponent
    });
    const inc = dialog.content.instance as BulkCancelComponent;
    inc.isCancel = draftCount > 0 ? false : true;
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Cancel') {
        let i = 0;
        let mmd: MMDActionDto[] = [];
        for (let dataContext of this.selectedItems) {
          let dataItem = this.requestExtMRPUpdateActionItemDetails!.items.find(x => x.requestLineId === dataContext)
          if (dataItem && (dataItem.status === 'Draft' || dataItem.status === 'Rejected')) {
            mmd.push(this.getmmdAction(dataItem!, i, r.comment))
          }
          i++;
        }
        this.mmdBulkAction(mmd);
      }
      else if (r.action === 'Delete') {
        let delItem = new DeleteLineItemDto();
        delItem.requestIssueId = this.requestIssueId;
        delItem.requestLineIds = [];
        delItem.requestLineIds = fItems.map(x => { return x.requestLineId })
        this.deleteLineItem = delItem;
        this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
          this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'success', 'right');
          this.getExtnMRPUpdateDetail();
        })
      }
      else {
        return false;
      }
    });
  }

  cancelLineItems(dataItem: ExtMRPUpdateActionItem, index: number, comment: string) {
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let mmd: MMDActionDto[] = [];
          mmd.push(this.getmmdAction(dataItem, index, comment))
          this.mmdBulkAction(mmd);
        }
      });
  }


  getmmdAction(dataItem: ExtMRPUpdateActionItem, index: number, comment: string) {
    let mmdAction: MMDActionDto = new MMDActionDto();
    mmdAction.comment = comment;
    mmdAction.createdBy = this.commonService.getUserEmailId();
    mmdAction.modifiedBy = this.commonService.getUserEmailId();
    mmdAction.requestIssueId = this.requestIssueId;
    mmdAction.requestIssueTypeId = this.requestIssueTypeId;
    mmdAction.requestLineId = dataItem.requestLineId;
    mmdAction.MMDAction = 3;
    mmdAction.requestId = this.requiredParams.requestId!
    mmdAction.isEditLineItem = false
    return mmdAction;
  }

  mmdBulkAction(mmdAction: MMDActionDto[]) {
    this.approvalDetailService.bulkmmdCancelAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage(SharedConst.cancelLineItem, 'success', 'right');
      this.getExtnMRPUpdateDetail();
    });
  }
  containsReject() {
    if (this.requestExtMRPUpdateActionItemDetails!.items.findIndex(x => x.status === 'Rejected' || x.status === 'Draft') >= 0) {
      return false;
    }
    return true;
  }


  accessForEditAction(dataItem: ExtMRPUpdateActionItem) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === dataItem.createdBy) {
        return true;
      }
      return false;
    }
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.status === 'Draft' && this.currentLineMumbers.includes(context.dataItem.lineNumber)) {
      return { border: true };
    } else {
      return { border: false };
    }
  }

  editAndDownload() {
    this.getUnitPriceList();
  }
  getUnitPriceList() {
    let matNumList: number[] = this.requestExtMRPUpdateActionItemDetails!.items.map((x) => {
      return x.materialItemNumber;
    })
    this.approvalDetailService.getUnitPriceList(matNumList, this.commonService.getUserBUName()).subscribe((response: any) => {
      let listData = [];
      for (let listItem of response) {
        let unitCost = listItem.unitPrice === '0.00' ? 0 : listItem.unitPrice;
        let item: any = {
          'Material Number | Plant Code': listItem.materialNumber + '|' + listItem.plantId,
          'Material Numebr': listItem.materialNumber, 'Plant Code': listItem.plantId, 'Unit Cost': unitCost
        };
        listData.push(item);
      }
      this.newFileDownload(listData);
    });
  }
  newFileDownload(response: any) {
    const zip = new JSZip();
    zip.file(this.requiredParams.requestNumber + ' ' + "ExtensionMRPUpdate_Raw_Data.xlsx", this.commonService.exportAsExcelFile(this.exportToExcel(), this.extnMRPUpdateApprovalExcelColumnGOBU,
      'ExtensionMRPUpdate_Raw_Data', true, response)
    );
    this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
      this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
        const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
        zip.file(this.requiredParams.requestNumber + '-' + 'ExtensionMRPUpdateTemplate_' + this.commonService.getUserBUName() + '_edit.xlsx', blobdownload)
        this.tempActionService.fetchBlobUrl(BlobURlsDetails.extensionMRPUpdateTemplateUserGuide).subscribe((responseUrl: string) => {
          this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
            const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
            zip.file(BlobURlsDetails.extensionMRPUpdateTemplateUserGuide + '.docx', blobdownload);
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, this.requiredParams.requestNumber + ' ' + "ExternsionMRPUpdate_edit.zip");
            });
          })
        })
      })
    });
  }

  public exportToExcel(): any {
    let finalAddNewItemList: ExtMRPUpdateActionItem[] = [];
    let tempAddNewItem: ExtMRPUpdateActionItem[] = JSON.parse(JSON.stringify(this.requestExtMRPUpdateActionItemDetails!.items.filter((x) => x.status === 'Draft')));
    for (let tempDataObj of tempAddNewItem) {
      let tempSLObj = JSON.parse(JSON.stringify(tempDataObj));
      tempDataObj.extMRPUpdateActionItemStorageLocations?.forEach((slValue: any) => {
        tempSLObj.extMRPUpdateActionItemStorageLocations = [];
        tempSLObj.extMRPUpdateActionItemStorageLocations.push(slValue);
        finalAddNewItemList.push(JSON.parse(JSON.stringify(tempSLObj)));
      })
    }
    let convertedArr = [];
    for (let itemList of finalAddNewItemList) {
      let plantDetails = itemList.extMRPUpdateActionItemStorageLocations[0].plantName?.split('-');
      let obj = {
        mmsKey: itemList.requestLineId + '-' + itemList.extMRPUpdateActionItemStorageLocations[0].id,
        materialItemNumber: itemList.materialItemNumber,
        plantName: plantDetails![0] + ' - ' + plantDetails![1],
        unitPrice: itemList.extMRPUpdateActionItemStorageLocations[0].unitPrice === null ? 0 : itemList.extMRPUpdateActionItemStorageLocations[0].unitPrice,
        specialProcurementKey: itemList.extMRPUpdateActionItemStorageLocations[0].specialProcurementKey,
        isPlantForDeletion: itemList.extMRPUpdateActionItemStorageLocations[0].isPlantForDeletion === true ? 'Y' : 'N',
        storageLocatinName: itemList.extMRPUpdateActionItemStorageLocations[0].storageLocatinName
          === 'Select Storage Location' ? '' : itemList.extMRPUpdateActionItemStorageLocations[0].storageLocatinName,
        isStorageLocationForDeletion: itemList.extMRPUpdateActionItemStorageLocations[0].isStorageLocationForDeletion === true ? 'Y' : 'N',
        minimum: itemList.extMRPUpdateActionItemStorageLocations[0].minimum,
        maximum: itemList.extMRPUpdateActionItemStorageLocations[0].maximum,
        bin: itemList.extMRPUpdateActionItemStorageLocations[0].bin,
        valuationType: itemList.extMRPUpdateActionItemStorageLocations[0].valuationType,
        isValuationTypeForDeletion: itemList.extMRPUpdateActionItemStorageLocations[0].isValuationTypeForDeletion === true ? 'Y' : 'N',
        estimatedInventoryValue: itemList.extMRPUpdateActionItemStorageLocations[0].estimatedInventoryValue,
        comment: itemList.comment

      }
      convertedArr.push(obj)
    }
    return convertedArr;
  }
}
