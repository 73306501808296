import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { requestConst } from 'src/app/core/constants/request.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { RequestDetailsDto } from '../../models/sar-request/request-details.model';
import { RequestIssueDetails } from '../../models/sar-request/request-issue/request-issue-details.model';
import { RequestLineService } from '../../services/request-line.service';
import { RequestService } from '../../services/request.service';
import { OnDestroy } from "@angular/core"
import { RoleConstant } from 'src/app/core/constants/role.constants';

@Component({
  selector: 'app-view-sar',
  templateUrl: './view-sar.component.html',
  styleUrls: ['./view-sar.component.css']
})
export class ViewSARComponent implements OnInit, OnDestroy {
  isCreate: boolean = true;
  isManage: boolean = false;
  id: number = 0;
  requestDetails: RequestDetailsDto = new RequestDetailsDto();
  modifiedDateTime!: Date;
  createdDateTime!: Date;
  gridData: RequestIssueDetails[] = [];
  actionItemsTotal: number = 0;
  dateFormatWithTime: string = SharedConst.dateFormatWithTime;
  displayedStatus: string = 'Draft';
  states: any = { rejected: 'Rejected', completed: 'Completed', pendingMMD: 'Pending MMD', pendingStockingApproval: 'Pending Stocking Approval', draft: 'Draft', cancelled: 'Cancelled' };
  isBack: boolean = false;
  isNewSARCreated!: boolean;
  isABU: boolean;
  isInventoryLevelUpdate: boolean;
  assetName: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private requestService: RequestService,
    private requestLineService: RequestLineService) {
    const isManageStatus = this.route.snapshot.params['isManageStatus'];
    const isCreateStatus = this.route.snapshot.params['isCreateStatus'];
    const requestId = this.route.snapshot.params['requestId'];
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;

    if (!this.commonService.isEmptyOrUndefined(stateData)) {
      this.isNewSARCreated = stateData['newSARCreated'];
      this.isManage = stateData['isManage'];
      this.isCreate = stateData['isCreate'];
      this.isBack = stateData['isBack'];
      this.id = stateData['requestId'];
      this.isInventoryLevelUpdate = stateData['isInventoryLevelUpdate'];
      sessionStorage.setItem('viewsar', JSON.stringify(stateData));
    } else if (isManageStatus && isCreateStatus && requestId) {
      this.isManage = isManageStatus;
      this.isCreate = isCreateStatus;
      this.id = requestId;
      this.isBack = false;
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('viewsar')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.isManage = sessionState.isManage;
        this.isCreate = sessionState.isCreate;
        this.id = sessionState.requestId;
        this.isBack = sessionState.isBack;
        this.isInventoryLevelUpdate = sessionState.isInventoryLevelUpdate;
      }
    }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('viewsar');
  }

  ngOnInit(): void {
    this.isABU = this.commonService.getUserBUName() === 'ABU';
    this.getSARDetails();
  }
  getSARDetails() {
    this.requestService.GetSARDetailsById(this.id).subscribe((requestDetails: RequestDetailsDto) => {
      this.requestDetails = requestDetails;
      this.assetName = requestDetails.assetName!;
      this.createdDateTime = new Date(this.requestDetails.createdDateTime + 'Z');
      this.modifiedDateTime = new Date(this.requestDetails.modifiedDateTime + 'Z');
      if (this.isManage && !this.isBack) {
        if (!this.isCreate) {
          this.commonService.showNotificationMessage(requestDetails.trackingNumber + ' ' + requestConst.updateMessage, 'success', 'right');
        } else if (this.isNewSARCreated) {
          this.commonService.showNotificationMessage(requestDetails.trackingNumber + ' ' + requestConst.insertMessage, 'success', 'right');
        }
      }
      this.getSARRequestActionsList(requestDetails.trackingNumber);
    });
  }
  getSARRequestActionsList(requestNumber: string) {
    this.requestLineService.getActions(requestNumber).subscribe((actions: RequestIssueDetails[]) => {
      this.gridData = actions;
      this.actionItemsTotal = actions.length;
      let statusList = actions.map(action => { return action.status });
      if (statusList.includes(this.states.rejected)) {
        this.displayedStatus = this.states.rejected;
      }
      else if (statusList.includes(this.states.pendingStockingApproval)) {
        this.displayedStatus = this.states.pendingStockingApproval;
      }
      else if (statusList.includes(this.states.pendingMMD)) {
        this.displayedStatus = this.states.pendingMMD;
      }
      else if (statusList.includes(this.states.completed)) {
        this.displayedStatus = this.states.completed;
      }
      else if (statusList.includes(this.states.cancelled)) {
        this.displayedStatus = this.states.cancelled;
      }
      else {
        this.displayedStatus = this.states.draft;
      }
    });
  }
  manage(isCreate: boolean) {
    this.router.navigate(["../../../../" + requestConst.manageSAR], {
      state: { isCreate: isCreate, requestId: this.requestDetails.id }, relativeTo: this.route
    });
  }

  navAddNewAction(isNew: boolean) {
    this.router.navigate(["../../../../" + requestConst.newAction], {
      state: { isNew: isNew, requestId: this.id, requestNumber: this.requestDetails.trackingNumber, actionNumber: this.actionItemsTotal + 1, assetName: this.requestDetails.assetName }, relativeTo: this.route
    });
  }

  navViewAction(actionItem: RequestIssueDetails, index: number) {
    let changeType: string = actionItem.changeType ? actionItem.changeType.split(' ').join('_') : '';
    if (actionItem.status === 'Draft') {
      if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly || actionItem.createdBy !== this.commonService.getUserEmailId()) {
        return false
      }
      this.router.navigate(['../../../../' + requestConst.newAction], { state: { isNew: false, requestId: actionItem.requestId, requestNumber: this.requestDetails.trackingNumber, requestIssueId: actionItem.requestissueId, actionNumber: actionItem.issueNumber, changeType: actionItem.changeType, createdBY: actionItem?.createdBy }, relativeTo: this.route });
    }
    else {
      this.router.navigate([`../../../../${requestConst.approvalDetail}/${actionItem?.requestId}/${this.requestDetails.trackingNumber}/${actionItem?.requestissueId}/${actionItem?.issueNumber}/${changeType}`],
        { state: { requestId: actionItem.requestId, requestNumber: this.requestDetails.trackingNumber, requestIssueId: actionItem.requestissueId, actionNumber: actionItem.issueNumber, changeType: actionItem.changeType, createdBY: actionItem?.createdBy }, relativeTo: this.route });
    }
  }

  navAction(actionItem: RequestIssueDetails, index: number) {
    this.router.navigate(['../../../../' + requestConst.newAction], { state: { 
      isNew: false, 
      requestId: actionItem.requestId, 
      requestNumber: this.requestDetails.trackingNumber, 
      requestIssueId: actionItem.requestissueId, 
      actionNumber: actionItem.issueNumber, 
      changeType: actionItem.changeType,
      assetName: this.assetName,
      isEdit: true
     }, relativeTo: this.route });
  }

  accessForEdit() {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === this.requestDetails.createdBy) {
        return true;
      }
      return false;
    }
  }

  accessForCreateAction() {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin ||
      this.commonService.getUserRoleName() === RoleConstant.ESAR_MMD) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === this.requestDetails.createdBy) {
        return true;
      }
      return false;
    }
  }

  accessForEditAction(dataItem: RequestIssueDetails) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === dataItem.createdBy) {
        return true;
      }
      return false;
    }
  }
}
