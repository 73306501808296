import { Injectable } from "@angular/core";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { InventoryLevelUpdateDto, InventoryLevelUpdateResponseDto } from "../models/change-sar/inventory-level-update.model";
import { Observable, from } from "rxjs";
import { HttpClient } from "@angular/common/http";


const urls = {
    inventoryLevel: 'api/RequestLine/inventory-level',
}

@Injectable({
    providedIn: 'root'
})
export class InventoryLevelUpdateService {

    constructor(public httpUtilityService: HttpUtilityService, private http: HttpClient) {
    }

    addNewItem(data: InventoryLevelUpdateDto) : Observable<InventoryLevelUpdateResponseDto> {
        return this.httpUtilityService.postRequest<InventoryLevelUpdateDto, InventoryLevelUpdateResponseDto>(urls.inventoryLevel, data);
    }

    updateItem(data: InventoryLevelUpdateDto) : Observable<InventoryLevelUpdateResponseDto> {
        return this.httpUtilityService.putRequest(urls.inventoryLevel, data);
    }

    getInventoryLevelUpdateData(requestId: number) {
        const httpParams = {
            requestId: requestId,
        };
        return this.httpUtilityService.get<InventoryLevelUpdateDto[]>(urls.inventoryLevel, {params : httpParams});
    }
}