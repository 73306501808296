import { NgModule } from '@angular/core';
import { AllSARModule } from '../features/allsar/modules/allsar.module';
import { SetupModule } from '../features/setup/modules/setup.module';
import { SARModule } from '../features/sar/modules/sar.module';
import { AuditModule } from '../features/audit/modules/audit.module';
import { SarAllocationModule } from '../features/sar-allocation/modules/sar-allocation.module';

@NgModule({
    declarations: [
    ],
    imports: [
        SetupModule,
        AllSARModule,
        SARModule,
        AuditModule,
    ],
    exports: [
        SetupModule,
        AllSARModule,
        SARModule,
        SarAllocationModule,
    ],
    providers: [
    ],
})
export class FeaturesModule { }
