<section>
  <div class="mt-20 text-align-right">
    <button kendoButton class="upload-button" [disabled]="requestExtMRPUpdateActionItemDetails!.items.length === 0" (click)="exportToExcel()"> Download Excel </button>
    <button kendoButton class="primary-bulk-button  ml-5 f-size-150" *ngIf="currentActionName !== 'summary'
    " [disabled]="isBulk || requestExtMRPUpdateActionItemDetails!.items.length === 0" (click)="openPopup()"> Bulk
      Action</button>
  </div>
</section>
<section *ngIf="requestExtMRPUpdateActionItemDetails">
  <h3>Item List</h3>

  <kendo-grid [data]="requestExtMRPUpdateActionItemDetails!.items" (selectionChange)="clickCheckBox($event)" #item
    [selectable]="true" kendoGridSelectBy [(selectedKeys)]="mySelection" style="height:auto">

    <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input *ngIf="currentActionName !== 'summary' &&
          (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')"
          type="checkbox" id="checkbox-{{rowIndex}}" [kendoGridSelectionCheckbox]="rowIndex" />
      </ng-template>
      <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
        <input kendoGridSelectAllCheckbox *ngIf="currentActionName !== 'summary'" type="checkbox" id="headerCheckbox" />
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column title="Line No." [width]="100">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem>
        {{dataItem.lineNumber}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="materialItemNumber" title="Material Number"></kendo-grid-column>
    <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    <kendo-grid-column field="totalValue" title="Total Value(USD)"></kendo-grid-column>
    <kendo-grid-column field="changeInValue" title="ChangeIn Value(USD)"></kendo-grid-column>
    <kendo-grid-column title="Action/Status" [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngIf="currentActionName !== 'summary' &&
         (dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed')">
          <span style="display: none;">{{assignEmpty(dataItem)}}</span>
          <kendo-textarea class="mt-5" [rows]="4" placeholder="Add Comment" [cols]="100" [(ngModel)]="dataItem.comment"
            resizable="both"></kendo-textarea>
          <br />
          <span *ngIf="dataItem.isCommentError" style="color: red;">Please enter Comment</span>
          <br />
          <button kendoButton class="border-button ml-5 f-size-150 cancelButton" (click)="cancel(dataItem)"> Cancel
          </button>
          <button kendoButton class="primary-button ml-5 f-size-150 rejectButton" (click)="reject(dataItem)"> Reject
          </button>
          <button kendoButton class="primary-button ml-5 f-size-150 successButton" (click)="complete(dataItem)">
            {{getSAButtonText()}}
          </button>
        </div>
        <div
          *ngIf="dataItem.status ==='Cancelled' || dataItem.status ==='Rejected' || dataItem.status ==='Completed'
        || (currentActionName === 'summary' && (dataItem.status ==='Pending Stocking Approval' || dataItem.status ==='Pending MMD'))">
          <div *ngIf="dataItem.status==='Completed'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' : '' ">
            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
              {{ dataItem?.status }}
            </p>
          </div>

          <div *ngIf="dataItem.status==='Cancelled'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' : '' ">
            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
              {{ dataItem?.status }}
            </p>
          </div>
          <div *ngIf="dataItem.status==='Rejected'" class="d-flex a-center status-box" [ngClass]="dataItem?.status === 'Completed' ? 'greenBTN' : 
        dataItem?.status === 'Rejected' ? 'redBTN' : dataItem?.status === 'Cancelled' ? 'grayBTN' : '' ">
            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
              {{ dataItem?.status }}
            </p>
          </div>
          <div *ngIf="dataItem.status !=='Cancelled' && dataItem.status !=='Rejected' && dataItem.status !=='Completed'"
            class="d-flex a-center status-box">
            <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
              {{ dataItem?.status }}
            </p>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem>
      <section *ngIf="dataItem.extMRPUpdateActionItemStorageLocations">
        <kendo-grid [data]="dataItem.extMRPUpdateActionItemStorageLocations" scrollable="none" #itemdetail
          style="height:auto">
          <kendo-grid-column title="Plant">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.plantName}}</span>
              <br>
              <span *ngIf="dataItem.isPlantForDeletion" class="mandatory">Flagged for deletion</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="unitPrice" title="Unit price"></kendo-grid-column>
          <kendo-grid-column field="specialProcurementKey" title="Special Procurement Key"></kendo-grid-column>
          <kendo-grid-column field="storageLocatinName" title="Storage Location">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.storageLocatinName}}</span>
              <br>
              <span *ngIf="dataItem.isStorageLocationForDeletion" class="mandatory">
                Flagged for
                deletion
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Recommended ROP (Min)">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.minimum}}
              <br />
              Existing ROP (Min)
              <br />
              {{dataItem.existingMinimum?dataItem.existingMinimum:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Recommended  Max">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.maximum}}
              <br />
              Existing Max
              <br />
              {{dataItem.existingMaximum?dataItem.existingMaximum:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Estimated Stock Holding Value (USD)">
            <ng-template kendoGridCellTemplate let-dataItem>
              ${{dataItem.estimatedInventoryValue?dataItem.estimatedInventoryValue:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="bin" title="Bin"></kendo-grid-column>
          <kendo-grid-column field="valuationType" title="Valuation Type">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.valuationType}}</span>
              <br>
              <span *ngIf="dataItem.isValuationTypeForDeletion" class="mandatory">
                Flagged for
                deletion
              </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </section>
    </ng-template>
  </kendo-grid>
</section>