import { Component, OnInit } from '@angular/core';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ExtensionMRPUpdateService } from '../../../services/extensionMRPupdate.service';
import { ValuationTypeService } from 'src/app/features/setup/services/valuationtype.service';
import { ValuationTypeDto } from 'src/app/features/setup/models/valuationtype/valuationtype.model';
import { SPKService } from 'src/app/features/setup/services/spk.service';
import { SPKDto } from 'src/app/features/setup/models/spk/spk.model';
import { PlantDetailsDto } from '../../../models/common/plant-detail.model';
import { StorageLocationDetailsDto } from '../../../models/common/storage-location-detail.model';
import { ExtnMRPUpdateStorageLocationsDtos } from '../../../models/change-sar/request-line-storage-location.model';
import { ExistingROPMinMaxDto, ExtensionMRPUpdateDto } from '../../../models/change-sar/extension-MRP-update-item.model';
import { ExtensionMRPUpdateConst } from 'src/app/core/constants/extensionMRPupdate.const';
import { ActivatedRoute, Router } from '@angular/router';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { ExtnMRPActionDetailsDto } from '../../../models/change-sar/extn-MRP-storage-location-details.model';
import { BehaviorSubject, of, switchMap } from 'rxjs';
import { ReAssignItemComponent } from '../../approval-management/re-assign-item/re-assign-item.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RequestLineService } from '../../../services/request-line.service';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { ReActivateComponent } from '../re-activate/re-activate.component';
import { MaterialNumberJdeService } from '../../../services/material-number-jde.service';
import { StockingLocationsService } from 'src/app/features/setup/services/stocking-locations.service';

@Component({
  selector: 'app-extn-mrp-update-add-new-item',
  templateUrl: './extn-mrp-update-add-new-item.component.html',
  styleUrls: ['./extn-mrp-update-add-new-item.component.scss']
})
export class ExtnMRPUpdateAddNewItemComponent implements OnInit {
  materialList = [];
  selectedMaterial: number = 0;
  selectedPlant = '';
  public virtual: any = {
    itemHeight: 50,
  };
  requesterNotes = '';
  public requestItems: ExtnMRPUpdateStorageLocationsDtos[] = [];
  public tempRequestItems: ExtnMRPUpdateStorageLocationsDtos[] = [];
  removedExistingItem: ExtnMRPUpdateStorageLocationsDtos[] = [];
  materialData: string[];
  gridData = [''];
  plantList: PlantDetailsDto[] = [];
  storageLocationList: StorageLocationDetailsDto[] = [];
  tempArray: any = [];
  valuationTypeData: ValuationTypeDto[] = [];
  SPKList: SPKDto[] = [];
  materialNumbers: number[] = [];
  public defaultPlantItem: { plantName: string; plantDiscription: string } = {
    plantName: "Select Plant",
    plantDiscription: ""
  };
  public defaultSPKItem: { name: string; id: number } = {
    id: 0,
    name: "Select SPK"
  };
  public defaultSLItem: { storageLocation: string; storageLocationDiscription: string; displayName: string } = {
    storageLocation: "Select Storage Location",
    storageLocationDiscription: "Select Storage Location",
    displayName: "Select Plant"
  };
  public defaultVTItem: { name: string; id: number } = {
    id: 0,
    name: "Select Valuation Type"
  };
  errorList = [{ isPlantEmptyError: false, isDuplicateError: false, isMinMaxError: false }];
  matError: boolean = false;
  matValidError: boolean = false;
  newActionInputParam: NewActionInputParam = {};
  lineItem: string = '';
  requestLineId: number = 0;
  private IsAPISCalled$$ = new BehaviorSubject<boolean>(false);
  IsAPISCalled$ = this.IsAPISCalled$$.asObservable();
  materialNumber: number = 0;
  extnMRPActionDetailsDto!: ExtnMRPActionDetailsDto;
  headerText = ';'
  buName: string;
  description: string = '';
  totalValue: number = 0;
  changeInValue: number = 0;
  isDataExist: boolean = false;
  itemStatus: string = '';
  lineAssignment?: LineAssignmentDto = new LineAssignmentDto();
  buId: number = 0;
  deactivatedPlants: any = [];
  deactivatedSLs: any = [];
  selectedDeactivatedPlants: any[] = [];
  selectedDeactivatedSLs: any[] = [];
  prevValue: string | undefined;
  prevSLValue: string = '';
  materialNumValidationError: boolean = false;
  isABU: boolean = false;
  constructor(private materialInfoService: MaterialInfoService,
    public commonService: CommonService,
    private valuationTypeService: ValuationTypeService,
    private extensionMRPUpdateService: ExtensionMRPUpdateService,
    private spkService: SPKService,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private requestLineService: RequestLineService,
    private materialNumberJdeService: MaterialNumberJdeService,
    private stockingLocationsService: StockingLocationsService) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.lineItem = stateData['lineItem'];
      this.requestLineId = stateData['requestLineId'];
      this.materialNumber = stateData['materialNumber'];
      this.materialNumbers = stateData['materialNumbers'];
      this.isDataExist = stateData['isDataExist'];
      this.itemStatus = stateData['itemStatus'];
      this.buId = stateData['buId'];
      sessionStorage.setItem('sarextnMRPUpdate', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('sarextnMRPUpdate')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.lineItem = sessionState.lineItem;
        this.requestLineId = sessionState.requestLineId;
        this.isDataExist = sessionState.isDataExist;
        this.materialNumber = sessionState.materialNumber;
        this.materialNumbers = sessionState.materialNumbers;
        this.itemStatus = sessionState.itemStatus;
        this.buId = sessionState.buId;
      }
    }
    this.headerText = 'New Item'
    if (!this.commonService.isNullOrEmptyOrUndefined(this.lineItem)) {
      this.headerText = 'Item ' + this.lineItem;
    }
    this.buName = this.commonService.getUserBUName();
  }

  ngOnInit(): void {
    if (!this.newActionInputParam.isNew) {
      this.getDeactivatedPlants(this.materialNumber);
      this.IsAPISCalled$.subscribe((value) => {
        if (value) {
          this.getLineItems();
        }
      })
    }
    this.isABU = this.commonService.getUserBUName() === 'ABU';
    this.getDescription(this.materialNumber);
    this.getMaterialList();
    this.getPlantList();
    this.getValuationTypeList();

  }

  getLineItems() {
    this.extensionMRPUpdateService.getMRPUpdateEditDetail(this.requestLineId)
      .subscribe((response: ExtnMRPActionDetailsDto) => {
        this.extnMRPActionDetailsDto = response;
        this.tempRequestItems = Object.assign([], response.extnMRPStorageLocationDetails)
        this.selectedMaterial = this.materialNumber;
        let items = response.extnMRPStorageLocationDetails;
        this.requesterNotes = response.requestorNotes!;
        this.tempArray = items.map(x => { return this.getStorageLocationsByPlant(x.plantName!) })
        console.log(this.tempArray)
        this.requestItems = items;
        let i = 0
        for (let item of this.requestItems) {
          this.getUnitprice(item!.plantName!, i);
          this.getExistingROPMinAndMaxValue(item!.plantName!, item!.storageLocatinName!, i);
          i++;
        }
      })
  }

  calculateInventeryValue(unitPrice: number, maxValue: number, index: number) {
    this.requestItems[index].estimatedInventoryValue = unitPrice * maxValue;
    return this.requestItems[index].estimatedInventoryValue;
  }

  getUnitprice(plantName: string, index: number) {
    if (this.commonService.isNullOrEmptyOrUndefined(plantName) || plantName === this.defaultPlantItem.plantName) {
      this.requestItems[index].unitPrice = 0;
      return false;
    }

    this.extensionMRPUpdateService.gGetPlantUnitPrice(plantName, this.materialNumber).subscribe((unitPrice) => {
      this.requestItems[index].unitPrice = this.commonService.isNullOrEmptyOrUndefined(unitPrice) ? 0 : parseFloat(unitPrice);
    }
    )
  }

  getExistingROPMinAndMaxValue(plantName: string, storageLocationName: string, index: number) {
    if ((this.commonService.isNullOrEmptyOrUndefined(plantName)
      || plantName === this.defaultPlantItem.plantName) ||
      (this.commonService.isNullOrEmptyOrUndefined(storageLocationName) || storageLocationName === this.defaultSLItem.storageLocationDiscription)) {
      this.requestItems[index].existingMaximum = 0;
      this.requestItems[index].existingMinimum = 0
      return false;
    }
    this.extensionMRPUpdateService.getExistingROPMinAndMaxValue(plantName, storageLocationName, this.materialNumber)
      .subscribe((existingROPMinMaxDto: ExistingROPMinMaxDto) => {
        this.requestItems[index].existingMaximum = parseFloat(existingROPMinMaxDto.max?.toString()!);
        this.requestItems[index].existingMinimum = parseFloat(existingROPMinMaxDto.min?.toString()!);

      }
      )
  }

  calculateTotalValue() {
    this.totalValue = this.requestItems.reduce((sum, current) => sum + current.estimatedInventoryValue!, 0);
    let totalUnitPrice = this.requestItems.reduce((sum, current) => sum + current.unitPrice!, 0);
    let totalExistingMax = this.requestItems.reduce((sum, current) => sum + parseFloat(current.existingMaximum!.toString()), 0);
    this.changeInValue = this.totalValue - (totalUnitPrice * totalExistingMax);
  }

  getStorageLocationsByPlant(plantName: string) {
    let index = this.plantList.findIndex((x: PlantDetailsDto) => x.plantName === plantName);
    this.plantList[index].storageLocationDetails.forEach(el => el.displayName = `${el.storageLocation} : ${el.storageLocationDiscription}`)
    return this.plantList[index].storageLocationDetails;
  }

  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      if (this.newActionInputParam.isNew === true) {
        const filteredData = outputDtata.filter(x => !this.materialNumbers.includes(x));
        this.materialList = filteredData;
        this.materialData = filteredData;
      } else {
        this.materialList = outputDtata;
        this.materialData = outputDtata;
      }
    });
  }

  materialChange(value: any) {
    this.matError = false;
    this.tempArray = [];
    this.requestItems = [];
    this.materialNumber = value;
    this.addNewRow();
    this.getDescription(value)
    this.getDeactivatedPlants(value);
  }

  isMaterialNumberValid(materialNumber: any) {
    let buName = this.commonService.getUserBUName();
    this.materialInfoService.isValidMaterialNumber<[]>(buName, materialNumber).subscribe((res: any) => {
      if (res) {
        this.selectedDeactivatedPlants = [];
        this.selectedDeactivatedSLs = [];
        this.matValidError = false;
        this.materialNumValidationError = false;
        if (!this.commonService.isNullOrEmptyOrUndefined(materialNumber)) {
          this.matError = false;
          this.tempArray = [];
          this.requestItems = [];
          this.materialNumber = materialNumber;
          this.addNewRow();
          this.getDescription(materialNumber)
          this.getDeactivatedPlants(materialNumber);
        }
      }
      else {
        this.materialNumValidationError = true;
        this.matValidError = false;
        this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.globalErrorMessage, 'error', 'right')
        return false;
      }
    });
  }

  getPlantList() {
    if (this.isABU) {
      this.stockingLocationsService.getStockingLocations().subscribe((response) => {
        this.plantList = response.map((res) => {
          //plant list downloaded for ABU
          console.log('Plant list downloaded for ABU')
          return new PlantDetailsDto(res.code!, res.description!, null!);
        });
      });
    }
    else {
      let BUName = this.commonService.getUserBUName();
      this.extensionMRPUpdateService.getPlantDetail(BUName).subscribe((response: PlantDetailsDto[]) => {
        this.plantList = response;
        this.getSPKList();
      });
    }
  }

  isPlantDisabled(plantName: string) {
    let indexofDeativated = this.deactivatedPlants.findIndex((x: any) => x.plantName === plantName);
    if (indexofDeativated >= 0) {
      return true;
    }
    else {
      return false;
    }
  }


  isSLDisabled(slName: string) {
    let indexofDeativated = this.deactivatedSLs.findIndex((x: any) => x.storageLocation === slName);
    if (indexofDeativated >= 0) {
      return true;
    }
    else {
      return false;
    }
  }

  plantChange(value: string | undefined, rowIndex: number) {
    this.storageLocationList = [];
    let currentItems = this.requestItems.filter((x: ExtnMRPUpdateStorageLocationsDtos) => x.plantName == this.requestItems[rowIndex].plantName && x.isPlantForDeletion === true);
    if (currentItems.length >= 1) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.ExtensionMRPUpdatesErrorMessage, 'error', 'right');
      this.requestItems[rowIndex].plantName = this.defaultPlantItem.plantName;
      this.requestItems[rowIndex].plantDescription = this.defaultPlantItem.plantDiscription;
      this.requestItems = JSON.parse(JSON.stringify(this.requestItems));
    } else {
      let index = this.plantList.findIndex((x: PlantDetailsDto) => x.plantName === value);
      if (index > -1) {
        this.plantList[index].storageLocationDetails.forEach(el => el.displayName = `${el.storageLocation} : ${el.storageLocationDiscription}`)
        this.tempArray[rowIndex] = this.plantList[index].storageLocationDetails;
        this.requestItems[rowIndex].plantName = this.plantList[index].plantName;
        this.requestItems[rowIndex].plantDescription = this.plantList[index].plantDiscription;
        this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocation;
      }
      else {
        this.tempArray[rowIndex] = this.defaultSLItem;
        this.requestItems[rowIndex].plantName = this.defaultPlantItem.plantName;
        this.requestItems[rowIndex].plantDescription = this.defaultPlantItem.plantDiscription;
        this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocation;
        this.requestItems[rowIndex].storageLocationDescription = this.defaultSLItem.storageLocationDiscription;
      }

      if (this.commonService.isNullOrEmptyOrUndefined(this.requestItems[rowIndex].storageLocatinName)) {
        this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocationDiscription;
      }

      let indexofDeativated = this.deactivatedPlants.findIndex((x: any) => x.plantName === value);
      if (this.selectedDeactivatedPlants.filter(x => x.index === rowIndex && x.plantName === value).length === 0
        && indexofDeativated >= 0) {
        let findIndex = this.selectedDeactivatedPlants.findIndex(x => x.index === rowIndex)
        if (findIndex >= 0) {
          this.selectedDeactivatedPlants.splice(findIndex, 1);
        }
        this.selectedDeactivatedPlants.unshift({
          plantName: this.deactivatedPlants[indexofDeativated].plantName,
          plantDiscription: this.plantList.find(x => x.plantName === value)!.plantDiscription,
          index: rowIndex
        });
      }
      else {
        let findIndex = this.selectedDeactivatedPlants.findIndex(x => x.index === rowIndex)
        this.selectedDeactivatedPlants.splice(findIndex, 1);
      }

      this.getDeactivatedSLs(value!);
    }
  }
  getDescription(matNumber: number) {
    this.extensionMRPUpdateService.getMaterialDescription(matNumber).subscribe((outputDtata: string) => {
      this.description = outputDtata;
    });
  }

  onPlantForDeletionFlagChange(rowIndex: number) {
    let currentItems = this.requestItems.filter((x: ExtnMRPUpdateStorageLocationsDtos) => x.plantName == this.requestItems[rowIndex].plantName);
    if (currentItems.length >= 2 && currentItems.filter((x: ExtnMRPUpdateStorageLocationsDtos) => x.isPlantForDeletion === true).length >= 1) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.ExtensionMRPUpdatesErrorMessage, 'error', 'right');
      this.requestItems[rowIndex].isPlantForDeletion = false;
      this.requestItems = JSON.parse(JSON.stringify(this.requestItems));
    }
    if (this.requestItems[rowIndex].isPlantForDeletion) {
      this.requestItems[rowIndex].spkId = 0;
      this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocation;
      this.requestItems[rowIndex].isStorageLocationForDeletion = false;
      this.requestItems[rowIndex].minimum = 0;
      this.requestItems[rowIndex].maximum = 0;
      this.requestItems[rowIndex].bin = '';
      this.requestItems[rowIndex].valuationTypeId = undefined;
      this.requestItems[rowIndex].isValuationTypeForDeletion = false;
    }
  }
  onStorageLocationForDeletionFlagChange(rowIndex: number) {
    if (this.requestItems[rowIndex].isStorageLocationForDeletion) {
      this.requestItems[rowIndex].minimum = 0;
      this.requestItems[rowIndex].maximum = 0;
    }
  }
  getSPKList() {
    this.spkService.getSPKAll().subscribe((outputDtata: SPKDto[]) => {
      this.SPKList = outputDtata;
      this.IsAPISCalled$$.next(true);
    });
  }

  getValuationTypeList() {
    this.valuationTypeService.getValuationTypeAll().subscribe((outputDtata: ValuationTypeDto[]) => {
      this.valuationTypeData = outputDtata;
    });
  }

  vauluationChange(value: any, index: number) {
    this.requestItems[index].valuationTypeId = value;
  }

  SPKChange(value: any, index: number) {
    this.requestItems[index].spkId = value;
  }


  SLChange(value: any, index: number) {
    this.requestItems[index].storageLocatinName = value;
    let indexofDeativated = this.deactivatedSLs.findIndex((x: any) => x.storageLocation === value);
    if (this.selectedDeactivatedSLs.filter(x => x.index === index && x.slName === value).length === 0
      && indexofDeativated >= 0) {
      let findIndex = this.selectedDeactivatedSLs.findIndex(x => x.index === index)
      if (findIndex >= 0) {
        this.selectedDeactivatedSLs.splice(findIndex, 1);
      }
      this.selectedDeactivatedSLs.unshift({
        slName: this.deactivatedSLs[indexofDeativated].storageLocation,
        slDescription: this.tempArray[index].find((x: any) => x.storageLocation === value)!.storageLocationDiscription,
        index: index
      });
    }
    else {
      let findIndex = this.selectedDeactivatedSLs.findIndex(x => x.index === index)
      this.selectedDeactivatedSLs.splice(findIndex, 1);
    }

  }

  filter(value: any) {
    if (value.length >= 3) {
      this.materialNumberJdeService.getMaterialNumbers(value).pipe(
        switchMap(result => {
          return of(result);
        })
      ).subscribe(result => {
        this.materialData = result;
      });
    }
  }

  addNewRow() {
    this.errorList = [];
    this.tempArray.push({})
    this.requestItems.push({
      createdBy: this.commonService.getUserEmailId(),
      isPlantForDeletion: false,
      isStorageLocationForDeletion: false,
      isValuationTypeForDeletion: false,
      maximum: 0,
      minimum: 0,
      id: 0,
      requestLineId: this.requestLineId,
      estimatedInventoryValue: 0,
      unitPrice: 0,
      existingMaximum: 0,
      existingMinimum: 0,
      bin: '',
      operation: 1
    });
  }

  addNewItem() {
    if (!this.validate()) {
      return false;
    }
    if (this.selectedDeactivatedPlants.length > 0 || this.selectedDeactivatedSLs.length > 0) {
      const dialog: DialogRef = this.dialogService.open({
        content: ReActivateComponent
      });
      const inc = dialog.content.instance as ReActivateComponent;
      inc.plantNames = this.selectedDeactivatedPlants;
      inc.slNames = this.selectedDeactivatedSLs;
      dialog.result.subscribe((r: any) => {
        if (this.commonService.isNullOrEmpty(r.action) || r.action === 'no') {
          return false;
        }
        this.addextnMRPData();
      });
    }
    else {
      this.addextnMRPData();
    }


  }
  
  addextnMRPData() {
    this.removeDefaultSLName(this.requestItems);
    let extensionMRPUpdate: ExtensionMRPUpdateDto = {
      createdBy: this.commonService.getUserEmailId(),
      lineNumber: 0,
      id: 0,
      requestId: this.newActionInputParam.requestId!,
      requestIssueTypeId: 1,
      extnMRPUpdateStorageLocationsDtos: this.requestItems,
      materialNumber: this.selectedMaterial,
      requestIssueId: this.commonService.isNullOrEmptyOrUndefined(this.newActionInputParam.requestIssueId)
        ? 0 : this.newActionInputParam.requestIssueId,
      itemNumber: this.selectedMaterial.toString(),
      requesterNotes: this.requesterNotes,
      description: this.description

    };
    this.extensionMRPUpdateService.addNewItem(extensionMRPUpdate).subscribe((response: any) => {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.successMessage, 'success', 'right')
      this.router.navigate(['./' + 'newaction'], {
        state: {
          isNew: false, requestId: response.requestId,
          requestIssueId: response.requestIssueId, changeType: 'Extension MRP Update',
          requestNumber: this.newActionInputParam.requestNumber,
          actionNumber: this.newActionInputParam.actionNumber
        }, relativeTo: this.route.parent
      });
    });
  }

  removeDefaultSLName(extnMRPUpdateStorageLocations: ExtnMRPUpdateStorageLocationsDtos[]) {
    extnMRPUpdateStorageLocations.forEach((x: ExtnMRPUpdateStorageLocationsDtos) => {
      if (x.storageLocatinName === 'Select Storage Location') { x.storageLocatinName = '' }
    })
  }

  openreAssignment() {
    let grouptype = 1
    if (this.extnMRPActionDetailsDto!.extnMRPStorageLocationDetails.reduce((sum, current) => sum + current.maximum!, 0) > 0) {
      grouptype = 2
    }

    const dialog: DialogRef = this.dialogService.open({
      content: ReAssignItemComponent
    });
    const inc = dialog.content.instance as ReAssignItemComponent;
    inc.requestIssueId = this.newActionInputParam.requestIssueId!;
    inc.lineItemId = this.extnMRPActionDetailsDto.requestLineId!;
    inc.groupType = grouptype;

    inc.listItems = []
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.actionType) || r.actionType === 'Close') {
        return false;
      }
      this.lineAssignment = r.lineItemDtail as LineAssignmentDto;
      this.lineAssignment.requestId = this.newActionInputParam.requestId;
      this.lineAssignment.requestIssueId = this.newActionInputParam.requestIssueId;
      this.lineAssignment.requestIssueTypeId = 1;
      this.lineAssignment.businessUnitId = this.buId;
      this.lineAssignment.state = 0;
      this.lineAssignment.isGeneralComment = false;
      this.lineAssignment.comment = '';
      this.lineAssignment.createdBy = this.commonService.getUserEmailId();
      this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
      this.lineAssignment.attachments = [];
      this.lineAssignment.requestLineIds = []
      this.lineAssignment.requestLineIds.push(this.extnMRPActionDetailsDto.requestLineId!)
      this.finalUpdate();
    });
  }


  update() {
    if (!this.validate()) {
      return false;
    }
    if (this.selectedDeactivatedPlants.length > 0 || this.selectedDeactivatedSLs.length > 0) {
      const dialog: DialogRef = this.dialogService.open({
        content: ReActivateComponent
      });
      const inc = dialog.content.instance as ReActivateComponent;
      inc.plantNames = this.selectedDeactivatedPlants;
      inc.slNames = this.selectedDeactivatedSLs;
      dialog.result.subscribe((r: any) => {
        if (this.commonService.isNullOrEmpty(r.action) || r.action === 'no') {
          return false;
        }
        if (this.itemStatus === 'Rejected') {
          this.openreAssignment();
        }
        else {
          this.finalUpdate();
        }
      })
    }
    else {
      if (this.itemStatus === 'Rejected') {
        this.openreAssignment();
      }
      else {
        this.finalUpdate();
      }
    }


  }

  finalUpdate() {
    let itemToAPI: ExtnMRPUpdateStorageLocationsDtos[] = [];
    let i = 0;
    let validItems = [...this.requestItems, ...this.removedExistingItem];

    this.removeDefaultSLName(validItems);
    let extensionMRPUpdate: ExtensionMRPUpdateDto = {
      createdBy: this.commonService.getUserEmailId(),
      modifiedBy: this.commonService.getUserEmailId(),
      lineNumber: 0,
      id: this.requestLineId,
      requestId: this.newActionInputParam.requestId!,
      requestIssueTypeId: 1,
      extnMRPUpdateStorageLocationsDtos: validItems,
      materialNumber: this.selectedMaterial,
      requestIssueId: this.commonService.isNullOrEmptyOrUndefined(this.newActionInputParam.requestIssueId)
        ? 0 : this.newActionInputParam.requestIssueId,
      itemNumber: this.selectedMaterial.toString(),
      requesterNotes: this.requesterNotes,
      description: this.description
    };
    this.extensionMRPUpdateService.updateExtenionMRPItem(extensionMRPUpdate).subscribe((response: any) => {
      if (this.itemStatus === 'Rejected') {
        this.saveAndAssign();
        return false;
      }
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.updateSuccessMessage, 'success', 'right')
      this.router.navigate(['./' + 'newaction'], {
        state: {
          isNew: false, requestId: response.requestId,
          requestIssueId: response.requestIssueId, changeType: 'Extension MRP Update',
          requestNumber: this.newActionInputParam.requestNumber,
          actionNumber: this.newActionInputParam.actionNumber
        }, relativeTo: this.route.parent
      });
    });
  }

  saveAndAssign() {
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!) && !this.lineAssignment?.isMixedValues) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
    }
    else {
      let userId: any = this.lineAssignment!.assignedUserId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedUserId!.toString())) {
        userId = null;
      }
      let groupId: any = this.lineAssignment!.assignedGroupId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!)) {
        groupId = null;
      }
      const formData = new FormData();
      formData.append('requestId', this.lineAssignment!.requestId!.toString());
      formData.append('requestIssueId', this.lineAssignment!.requestIssueId!.toString());
      formData.append('requestIssueTypeId', this.lineAssignment!.requestIssueTypeId!.toString());
      formData.append('businessUnitId', this.lineAssignment!.businessUnitId!.toString());
      formData.append('assignedGroupId', groupId);
      formData.append('assignedUserId', userId);
      formData.append('state', this.lineAssignment!.state!.toString());
      formData.append('comment', this.lineAssignment!.comment!);
      formData.append('isGeneralComment', this.lineAssignment!.isGeneralComment!.toString());
      formData.append('createdBy', this.lineAssignment!.createdBy!);
      formData.append('modifiedBy', this.lineAssignment!.modifiedBy!);
      for (let attachment of this.lineAssignment!.attachments!) {
        formData.append('attachments', attachment);
      }
      for (let id of this.lineAssignment!.requestLineIds!) {
        formData.append('requestLineIds', id.toString());
      }
      this.requestLineService.updateLineAssignmentForRejected(formData).subscribe(response => {
        this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.updateSuccessMessage, 'success', 'right')
        this.router.navigate(['./' + 'newaction'], {
          state: {
            isNew: false, requestId: this.lineAssignment!.requestId!,
            requestIssueId: this.lineAssignment!.requestIssueId!, changeType: 'Extension MRP Update',
            requestNumber: this.newActionInputParam.requestNumber,
            actionNumber: this.newActionInputParam.actionNumber
          }, relativeTo: this.route.parent
        });
      })
    }
  }
  navToBack() {
    if (this.isDataExist) {
      this.newActionInputParam.isNew = false;
    }
    this.router.navigate(['./' + 'newaction'], {
      state: {
        isNew: this.newActionInputParam.isNew, requestId: this.newActionInputParam.requestId,
        requestIssueId: this.newActionInputParam.requestIssueId, changeType: 'Extension MRP Update',
        requestNumber: this.newActionInputParam.requestNumber,
        actionNumber: this.newActionInputParam.actionNumber
      }, relativeTo: this.route.parent
    });
  }

  validate(): boolean {
    if (this.commonService.isNullOrEmptyOrUndefined(this.selectedMaterial)) {
      this.matError = true;
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    if (!this.commonService.isNullOrEmptyOrUndefined(this.selectedMaterial) && this.materialNumValidationError) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    this.errorList = this.requestItems.map(x => { return { isPlantEmptyError: false, isDuplicateError: false, isMinMaxError: false } })
    let i = 0;
    for (let obj of this.requestItems) {
      this.requestItems[i].createdBy = this.commonService.getUserEmailId();
      this.requestItems[i].modifiedBy = this.commonService.getUserEmailId();
      this.requestItems[i].requestLineId = this.requestLineId;
      if (this.commonService.isNullOrEmptyOrUndefined(obj.plantName) || obj.plantName === this.defaultPlantItem.plantName) {
        this.errorList[i].isPlantEmptyError = true;
        i++;
        continue;
      }
      if (!this.commonService.isNullOrEmptyOrUndefinedOnly(obj.minimum) && !this.commonService.isNullOrEmptyOrUndefinedOnly(obj.maximum)
        && (obj.minimum! > obj.maximum!)) {
        this.errorList[i].isMinMaxError = true;
      }
      var lengthOfDuplicateData = this.requestItems.filter((x: ExtnMRPUpdateStorageLocationsDtos) => x.plantName === obj.plantName
        && x.storageLocatinName === obj.storageLocatinName).length;
      if (i != 0 && !this.commonService.isNullOrEmptyOrUndefined(obj.plantName) && obj.plantName !== this.defaultPlantItem.plantName &&
        !this.commonService.isNullOrEmptyOrUndefined(obj.storageLocatinName) &&
        lengthOfDuplicateData > 1) {
        this.errorList[i].isDuplicateError = true;
      }
      i++;
    }
    if (this.errorList.findIndex(x => x.isDuplicateError === true || x.isMinMaxError === true || x.isPlantEmptyError === true) !== -1) {
      this.commonService.showNotificationMessage(ExtensionMRPUpdateConst.globalErrorMessage, 'error', 'right')
      return false;
    }
    return true;
  }
  removeRow(index: number) {
    this.errorList = [];
    let tempIndex = this.tempRequestItems.findIndex(x => x.id === this.requestItems[index].id)
    if (tempIndex != -1) {
      this.tempRequestItems[tempIndex].operation = 3;
      this.tempRequestItems[tempIndex].createdBy = this.commonService.getUserEmailId();
      this.tempRequestItems[tempIndex].modifiedBy = this.commonService.getUserEmailId();
      this.tempRequestItems[tempIndex].requestLineId = this.requestLineId;
      this.removedExistingItem.push(this.tempRequestItems[tempIndex])
    }
    if (this.selectedDeactivatedPlants.find(x => x.index = index)) {
      this.selectedDeactivatedPlants.splice(index, 1);
    }
    if (this.selectedDeactivatedSLs.find(x => x.index = index)) {
      this.selectedDeactivatedSLs.splice(index, 1);
    }
    this.requestItems.splice(index, 1)
  }

  getDeactivatedPlants(matNumber: number) {
    this.extensionMRPUpdateService.getPlantDeactivationDetails(this.commonService.getUserBUName(), matNumber).subscribe((outputDtata: any) => {
      this.deactivatedPlants = outputDtata;
    });
  }

  getDeactivatedSLs(plantName: string) {
    this.extensionMRPUpdateService.getStorageLocationDeactivationDetails(this.commonService.getUserBUName(), plantName, this.selectedMaterial).subscribe((outputDtata: any) => {
      this.deactivatedSLs = outputDtata;
    });
  }

  updateValue(value: any) {
    if (value == null || value == undefined || value < 0) {
      return 0
    }
    return value;
  }
}
