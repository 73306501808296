import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDataService } from '../../../services/change-data.service';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { ChangeDataActionResponseDto } from '../../../models/change-sar/change-data-action-response.model';
import { ChangeDataItem } from '../../../models/change-sar/change-data-item.model';
import { CommonService } from 'src/app/core/services/common.service';
import { DeleteLineItemDto } from '../../../models/common/delete-line-item.model';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { RequestLineService } from '../../../services/request-line.service';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { TempActionRequestDto } from '../../../models/temp-actions/temp-action-request.module';
import { LineAssignmentDto } from '../../../models/common/line-assignment.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { AddNewActionItemsAttachmentDetails, AddNewActionItemsAttachmentDetailsDisplay } from '../../../models/common/action-attachment.model';
import { BusinessUnitDto } from 'src/app/features/setup/models/bussinessunits/bussinessunits.model';
import { BussinessUnitService } from 'src/app/features/setup/services/bussinessunit.service';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { UserGroupsService } from 'src/app/features/setup/services/user-groups.service';
import { RequestIssueTypeDto } from '../../../models/sar-request/request-issue/request-issue-types.model';
import { RequestIssueService } from '../../../services/request-issue.service';
import { TempActionService } from '../../../services/temp-action.service';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { BulkCancelComponent } from 'src/app/core/components/bulk-cancel/bulk-cancel.component';
import { MMDActionDto } from '../../../models/approval-details/mmd-approval-caction.mode';
import { ApprovalDetailService } from '../../../services/approvalDetail/approval-details.service';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { AddNewItemConstant } from 'src/app/core/constants/add-new-item.constant';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { ChangeDataConst, ChangeDataEnum, ChangeDataEnumObj, ChangeDataOperationConst } from 'src/app/core/constants/change-data.const';
import { MaterialInfoService } from 'src/app/features/setup/services/material-info.service';
import { ChangeTypeDto } from '../../../models/change-sar/change-type.model';
import { ChangeDataDto, ChangeTypeDetailsDto, ChangeTypeValueDto } from '../../../models/change-sar/change-data-new-item-detail.model';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { UnitOfMeasureService } from 'src/app/features/setup/services/unit-of-measure.service';
import { MaterialGroupCodeService } from 'src/app/features/setup/services/material-group-code.service';
import { UnitOfMeasure } from 'src/app/features/setup/models/unitofmeasure/unitofmeasure.model';
import { MaterialGroupCode } from 'src/app/features/setup/models/materialgroupcode/materialgroupcode.model';
import { requestConst } from '../../../../../core/constants/request.const';
import * as saveAs from 'file-saver';
import * as JSZip from 'jszip';
import { ChangeDataExcelColumns } from 'src/app/core/constants/excel-columns-const';
import { BlobURlsDetails } from 'src/app/core/constants/commonConst/blobUrl.cons';

@Component({
  selector: 'app-change-data',
  templateUrl: './change-data.component.html',
  styleUrls: ['./change-data.component.scss']
})
export class ChangeDataComponent implements OnInit {
  changeDataDetails: ChangeDataActionResponseDto = new ChangeDataActionResponseDto();
  downloadAndEditData: ChangeDataItem[] = [];
  @Input() requiredParams: NewActionInputParam = {};
  materialNumbers: number[] = []
  selectedItems: any = [];
  deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  fileList: File[] = [];
  tempActionRequest!: TempActionRequestDto;
  @Output() TempActionRequestEvent = new EventEmitter<TempActionRequestDto>();
  lineAssignment!: LineAssignmentDto;
  @Output() lineAssignmentEvent = new EventEmitter<LineAssignmentDto>();
  @ViewChild('fileInput') fileInput: any;
  @Output() cancelEvent = new EventEmitter<DeleteLineItemDto>();
  comment: string = '';
  isDraftavailable: boolean = false;
  @Output() isDraftavailableEvent = new EventEmitter<boolean>();
  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  enabledAssignToUser: boolean = false;
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 0,
    name: "Select Group"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };
  selectedUser = 0;
  businessUnitId: number = 0;
  requestIssueTypeId: number = 0;
  @Input() public isGoupSelected: boolean = true;
  attachmentDetails?: AddNewActionItemsAttachmentDetails[] = [];
  attachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  buName: string;
  existingData: any = [];
  existingDatabeforeUpload: { id: number, materialNumber?: string, changeType?: string, plantOtherChangeType?: string }[] = [];
  isShowDraftMessage: boolean = false;
  countErrors: number = 0;
  uploadErrors: string = '';
  materialList: any[] = [];
  changeTypes: ChangeTypeDto[] = [];
  uomData: UnitOfMeasure[] = [];
  materialGroupCodeData: MaterialGroupCode[] = [];
  changeDataList: ChangeDataDto[] = [];
  public readonly changeDataEnum: typeof ChangeDataEnum = ChangeDataEnum;
  changeDataExcelColumns: any = ChangeDataExcelColumns;
  excelFileName: string = 'ChangeSARExcelTemplate';
  excelFilePath: string = 'ChangeSARExcelTemplate';
  updatedRequestLineIdList: number[] = [];
  requestLineIdList: number[] = [];
  isUploadNewDataOnly: boolean = true;
  private readonly OldDataExcelIndicator = '__EMPTY_5';
  private readonly NewDataExcelIndicator = '__EMPTY_6';

  constructor(public router: Router,
    private route: ActivatedRoute, private changeDataService: ChangeDataService,
    public commonService: CommonService, private userGroupsService: UserGroupsService,
    private sarUsersService: SarUsersService,
    private buService: BussinessUnitService,
    private requestIssueService: RequestIssueService,
    private tempActionService: TempActionService, public approvalDetailService: ApprovalDetailService,
    public requestLineService: RequestLineService, private dialogService: DialogService,
    private materialInfoService: MaterialInfoService, private unitOfMeasureService: UnitOfMeasureService,
    private materialGroupCodeService: MaterialGroupCodeService) {
    this.buName = this.commonService.getUserBUName();
  }

  ngOnInit(): void {
    this.excelFilePath = this.excelFilePath + this.commonService.getUserBUName();
    this.isShowDraftMessage = true;
    this.getMaterialList();
    this.getChangeTypes();
    const uom = this.unitOfMeasureService.getUnitOfMeasure();
    const materialGroupCode = this.materialGroupCodeService.getMaterialGroupCode();

    combineLatest([uom, materialGroupCode]).subscribe(results => {
      this.uomData = this.addDisplayName(results[0]);
      this.materialGroupCodeData = this.addDisplayName(results[1]);
    })
    if (!this.requiredParams.isNew) {
      this.getChangeData();
      this.getAssignmentGroups();
    }
  }
  getMaterialList() {
    this.materialInfoService.getAllMaterialNumbers<[]>().subscribe((outputDtata: []) => {
      this.materialList = outputDtata;
    });
  }
  getChangeTypes() {
    this.changeDataService.getChangeTypes().subscribe((response: ChangeTypeDto[]) => {
      this.changeTypes = response
    });
  }
  getChangeData() {
    const getChangeDataAPIAPI = this.changeDataService.getChangeDataDetail(this.requiredParams.requestId, this.requiredParams.requestIssueId);
    const getBUAllAPI = this.buService.getBUAll();
    const getRequestIssueTypesAPI = this.requestIssueService.getRequestIssueTypes(true);
    forkJoin([getChangeDataAPIAPI, getBUAllAPI, getRequestIssueTypesAPI]).
      subscribe(([getChangeDataAPIAPI, getBUAllAPIData, getRequestIssueTypesAPIData]) => {
        if (getChangeDataAPIAPI.items == null || (getChangeDataAPIAPI.items != null && getChangeDataAPIAPI!.items!.length == 0)) {
          this.changeDataDetails = new ChangeDataActionResponseDto();
          const path = `false/false/${this.requiredParams.requestId}`
          this.router.navigate([`./${requestConst.viewSAR}/${path}`], {
            state: { requestId: this.requiredParams.requestId }, relativeTo: this.route.parent
          });
          return false;
        }
        this.changeDataDetails = getChangeDataAPIAPI;
        this.downloadAndEditData = getChangeDataAPIAPI.items.map(x => { return { ...x } });
        this.changeDataDetails.items.forEach(ele => {
          if (ele.changeType === 'Unit Of Measure') {
            let oldData = this.uomData.find(x => x.code === ele.oldData);
            if (oldData) {
              ele.oldData = oldData.displayName;
            }
            let newData = this.uomData.find(x => x.code === ele.newData);
            if (newData)
              ele.newData = newData.displayName;
          }
          if (ele.changeType === 'Material Group Code') {
            let oldData = this.materialGroupCodeData.find(x => x.code === ele.oldData);
            if (oldData) {
              ele.oldData = oldData.displayName;
            }
            let newData = this.materialGroupCodeData.find(x => x.code === ele.newData);
            if (newData) {
              ele.newData = newData.displayName;
            }
          }
          if (ele.changeType === 'Lead Time') {
            ele.oldData = ele.oldData ? ele.oldData : '0';
          }
        })
        this.attachmentDetails = getChangeDataAPIAPI.attachments;
        this.attachmentDetailsDisplay = getChangeDataAPIAPI.attachments ? getChangeDataAPIAPI.attachments : [];
        let requestLineIdList = this.changeDataDetails.items.map(x => { return x.id });
        this.requestLineIdList = requestLineIdList;
        this.existingData = this.changeDataDetails.items.map(x => {
          return {
            materialNumber: x.materialNumber
            , changeType: x.changeType,
            plantOtherChangeType: x.plantOtherChangeType
            , status: x.status
          }
        });
        this.existingDatabeforeUpload = this.changeDataDetails.items.filter(x => x.status !== 'Cancelled').map(x => {
          return {
            id: x.id,
            materialNumber: x.materialNumber
            , changeType: x.changeType
            , plantOtherChangeType: x.plantOtherChangeType
          }
        });
        let deleteLineItem: DeleteLineItemDto = {
          requestIssueId: this.requiredParams.requestIssueId!,
          requestLineIds: requestLineIdList
        }
        this.cancelEvent.emit(deleteLineItem);
        let draftstotal = getChangeDataAPIAPI.items.filter((item) => item.status!.toLocaleLowerCase() === 'draft').length;
        this.setBuId(getBUAllAPIData);
        if (draftstotal !== 0) {
          this.isDraftavailable = true;
          if (getChangeDataAPIAPI.items.length === draftstotal && this.isShowDraftMessage) {
            this.commonService.showNotificationMessage(`Request  Action ${this.requiredParams.actionNumber?.toString()} have all line items are in Draft state and has not submitted for approval yet. Please submit for approval.`, 'info', 'center');
          }
          this.isDraftavailableEvent.emit(true);
          if (this.changeDataDetails.assignmentDetails && this.changeDataDetails.assignmentDetails.comment) {
            this.comment = this.changeDataDetails.assignmentDetails.comment;
          }

          this.setRequestIssueTypeId(getRequestIssueTypesAPIData);
          this.settempActionRequest();
          this.TempActionRequestEvent.emit(this.tempActionRequest);
          this.setLineAssignment();
          this.lineAssignmentEvent.emit(this.lineAssignment);
        }
        this.isShowDraftMessage = false;
      });

  }
  addDisplayName(data: UnitOfMeasure[] | MaterialGroupCode[]): any {
    if (data.length > 0) {
      data.forEach(el => el.displayName = `${el.code} : ${el.name}`)
      return data;
    }
    return [];
  }
  rejectLineItem(dataItem: ChangeDataItem, index: number) {
    this.commonService.showConfirmation('Delete', ApprovalDetailsConstants.deleteMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let delItem = new DeleteLineItemDto();
          delItem.requestIssueId = this.requiredParams.requestIssueId!;
          delItem.requestLineIds = [];
          delItem.requestLineIds.push(dataItem.id);
          this.deleteLineItem = delItem;
          this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
            this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'info', 'right');
            this.getChangeData();
          })
        }
      });
  }

  AddChangeSAR() {
    this.router.navigate(["../" + 'changeSARaddnewitem'], {
      state: {
        isNew: true,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId, requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType, lineItem: 0,
        requestLineId: 0, materialNumbers: this.materialNumbers,
        assetName: this.requiredParams.assetName,
        isDataExist: this.changeDataDetails.items.length > 0, exisitingData: this.existingData, status: 'Draft'
      },
      relativeTo: this.route
    },
    );
  }

  editLineItem(dataItem: ChangeDataItem, index: number) {
    this.router.navigate(["../" + 'changeSARaddnewitem'], {
      state: {
        isNew: false, requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId, requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType, lineItem: dataItem.lineNumber,
        requestLineId: dataItem.lineNumber, materialNumber: dataItem.materialNumber, materialNumbers: this.materialNumbers, status: dataItem.status,
        buId: this.businessUnitId
      },
      relativeTo: this.route
    },
    );
  }

  onFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.attachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.TempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  settempActionRequest() {
    this.tempActionRequest = new TempActionRequestDto();
    this.tempActionRequest.requestId = this.requiredParams.requestId;
    this.tempActionRequest.requestIssueId = this.requiredParams.requestIssueId;
    this.tempActionRequest.requestIssueTypeId = this.requestIssueTypeId;
    this.tempActionRequest.attachments = [];
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequest.createdBy = this.commonService.getUserEmailId();
    this.tempActionRequest.modifiedBy = this.commonService.getUserEmailId();
  }

  setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
    const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }
  removeFile(index: number) {
    if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.attachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.TempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.attachmentDetailsDisplay!.splice(index, 1);
    }
  }
  downloadFile(index: number) {
    if (this.attachmentDetailsDisplay && this.attachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.attachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }
  onChangeComment() {
    this.lineAssignment.comment = this.tempActionRequest.comment;
    this.TempActionRequestEvent.emit(this.tempActionRequest);
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }
  setBuId(BUData: BusinessUnitDto[]) {
    let bu: BusinessUnitDto[] = BUData.filter(e => e.shortName === this.buName);
    if (bu.length > 0) {
      this.businessUnitId = bu[0].id;
    }
  }
  setRequestIssueTypeId(RequestIssueTypes: RequestIssueTypeDto[]) {
    let actionType: string = 'Change Data';
    let requestIssueType: RequestIssueTypeDto[] = RequestIssueTypes.filter(e => e.label?.toLocaleLowerCase() === actionType.toLocaleLowerCase()) as RequestIssueTypeDto[];
    if (requestIssueType.length > 0) {
      this.requestIssueTypeId = requestIssueType[0].id as number;
    }
  }
  setLineAssignment() {
    this.lineAssignment = new LineAssignmentDto();
    this.lineAssignment.requestId = this.requiredParams.requestId;
    this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
    this.lineAssignment.requestIssueTypeId = this.requestIssueTypeId;
    this.lineAssignment.businessUnitId = this.businessUnitId;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    this.lineAssignment.state = 0;
    this.lineAssignment.isGeneralComment = false;
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.createdBy = this.commonService.getUserEmailId();
    this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
    this.lineAssignment.attachments = [];
  }
  getAssignmentGroups() {
    this.userGroupsService.getAssignmentGroups(1).subscribe((data: any) => {
      this.assignToGroups = data;
    })
  }
  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }
  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = 0;
    this.selectedUser = 0;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }
  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  bulkCancel() {
    let fItems = this.changeDataDetails!.items
      .filter(({ id }) => this.selectedItems.includes(id));
    let draftCount = fItems.filter(x => x.status === 'Draft').length;
    let rejectCount = fItems.filter(x => x.status === 'Rejected').length;
    if (draftCount > 0 && rejectCount > 0) {
      this.commonService.showNotificationMessage(AddNewItemConstant.bulkActionError, 'error', 'right');
      return false;
    }
    const dialog: DialogRef = this.dialogService.open({
      content: BulkCancelComponent
    });
    const inc = dialog.content.instance as BulkCancelComponent;
    inc.isCancel = draftCount > 0 ? false : true;
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Cancel') {
        let i = 0;
        let mmd: MMDActionDto[] = [];
        for (let dataContext of this.selectedItems) {
          let dataItem = this.changeDataDetails!.items.find(x => x.id === dataContext)
          if (dataItem && (dataItem.status === 'Draft' || dataItem.status === 'Rejected')) {
            mmd.push(this.getmmdAction(dataItem!, i, r.comment))
          }
          i++;
        }
        this.mmdBulkAction(mmd);
      }
      else if (r.action === 'Delete') {
        let delItem = new DeleteLineItemDto();
        delItem.requestIssueId = this.requiredParams.requestIssueId!;
        delItem.requestLineIds = [];
        delItem.requestLineIds = fItems.map(x => { return x.id })
        this.deleteLineItem = delItem;
        this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
          this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'success', 'right');
          this.getChangeData();
        })
      }
      else {
        return false;
      }
    });
  }

  cancelLineItems(dataItem: ChangeDataItem, index: number, comment: string) {
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let mmd: MMDActionDto[] = [];
          mmd.push(this.getmmdAction(dataItem, index, comment))
          this.mmdBulkAction(mmd);
        }
      });
  }
  
  getmmdAction(dataItem: ChangeDataItem, index: number, comment: string) {
    let mmdAction: MMDActionDto = new MMDActionDto();
    mmdAction.comment = comment;
    mmdAction.createdBy = this.commonService.getUserEmailId();
    mmdAction.modifiedBy = this.commonService.getUserEmailId();
    mmdAction.requestIssueId = dataItem.requestIssueId
    mmdAction.requestIssueTypeId = dataItem.requestIssueTypeId
    mmdAction.requestLineId = dataItem.id;
    mmdAction.MMDAction = 3;
    mmdAction.requestId = dataItem.requestId;
    mmdAction.isEditLineItem = false
    return mmdAction;
  }

  mmdBulkAction(mmdAction: MMDActionDto[]) {
    this.approvalDetailService.bulkmmdCancelAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage(SharedConst.cancelLineItem, 'success', 'right');
      this.getChangeData();
    });
  }
  containsReject() {
    if (this.changeDataDetails!.items.findIndex(x => x.status === 'Rejected' || x.status === 'Draft') >= 0) {
      return false;
    }
    return true;
  }


  accessForEditAction(dataItem: ChangeDataItem) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === dataItem.createdBy) {
        return true;
      }
      return false;
    }
  }
  navGenerateTemplate() {
    this.router.navigate(['./changedataexceltemplate'], { state: { isNew: this.requiredParams.isNew, requestId: this.requiredParams.requestId, requestNumber: this.requiredParams.requestNumber, requestIssueId: this.requiredParams.requestIssueId, actionNumber: this.requiredParams.actionNumber, changeType: this.requiredParams.changeType, existingData: this.existingData, assetName: this.requiredParams.assetName }, relativeTo: this.route.parent })
  }

  public import(): void {
    this.isUploadNewDataOnly = true;
    this.changeDataList = [];
    this.updatedRequestLineIdList = [];
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === "Save") {
        let SARName = r.fileName.split('-')[0];
        if (SARName !== this.requiredParams.requestNumber?.toString()) {
          this.commonService.showNotificationMessage(ChangeDataConst.excelvalidations.invalidSARNum, 'error', 'center');
        }

        else {
          this.countErrors = 0;
          this.uploadErrors = '';
          let arr = JSON.parse(r.value)['Change Data'] as Array<any>;
          let filteredArr = arr.slice(2);
          if (filteredArr.length === 0) {
            this.commonService.showNotificationMessage(ChangeDataConst.excelvalidations.noRows, 'warning', 'center');
          }
          else {
            let index = -1;
            let validMaterialNumberAndChangeType: boolean = true;
            let materialNumberList: string[] = [];
            for (let item of filteredArr) {
              if (item.hasOwnProperty('__EMPTY_1') && !this.invalidMaterialNumber(item['__EMPTY_1']?.toString())) {
                if (!materialNumberList.includes(item['__EMPTY_1'].toString()))
                  materialNumberList.push(item['__EMPTY_1'].toString());
              }
            }
            let getChangeDataInfoAPIs: Observable<ChangeTypeDetailsDto[]>[] = []
            for (let num of materialNumberList) {
              getChangeDataInfoAPIs.push(this.changeDataService.getChangeDataInfo(num));
            }
            if (getChangeDataInfoAPIs.length > 0) {
              forkJoin(getChangeDataInfoAPIs).subscribe((ChangeDataInfoList: ChangeTypeDetailsDto[][]) => {
                for (let item of filteredArr) {
                  index++;
                  let isMMSKey: boolean = (item.hasOwnProperty('Change Data SAR') && !this.isEmptyfield(item['Change Data SAR'])) ? true : false;
                  let skipRequestLineValidation: boolean = this.isSkipRequestLineValidation(isMMSKey, item, index);
                  if (!skipRequestLineValidation) {
                    validMaterialNumberAndChangeType = true;
                    if (!item?.hasOwnProperty('__EMPTY_1') || this.invalidMaterialNumber(item['__EMPTY_1'].toString())) {
                      this.validationWithWrongMaterialNumberItem(item, index);
                      validMaterialNumberAndChangeType = false;
                    }
                    if (!item?.hasOwnProperty('__EMPTY_2') || this.invalidChangeType(item['__EMPTY_2'])) {
                      if (validMaterialNumberAndChangeType)
                        this.validationWithWrongChangeTypeItem(item, index);
                      validMaterialNumberAndChangeType = false;
                    }
                    if (validMaterialNumberAndChangeType) {
                      let isDuplicateMatNumAndChangeType: boolean = this.isDuplicateMatNumAndChangeType(isMMSKey, item['__EMPTY_1'], item['__EMPTY_2'], item['__EMPTY_3']);
                      if (isDuplicateMatNumAndChangeType) {
                        this.updateUploadError(index, ChangeDataConst.excelvalidations.duplicateEntry);
                      }
                      let matNumIndex = materialNumberList.findIndex(m => m === item['__EMPTY_1'].toString());
                      let changeTypeValues: ChangeTypeValueDto[] | undefined = this.getchangeTypeValues(ChangeDataInfoList[matNumIndex], item['__EMPTY_2']);
                      this.ChangeDataValidation(true, isDuplicateMatNumAndChangeType, item, index, changeTypeValues);
                    }
                  }
                }
                if (this.countErrors !== 0) {
                  let errormessage: string = '';
                  if (this.countErrors > 8) errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
                  else errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
                  errormessage += this.uploadErrors;
                  this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
                }
                else {
                  this.addDeletedItems()
                  this.changeDataService.importChangeDataAction(this.changeDataList).subscribe((response: any) => {
                    this.commonService.showNotificationMessage(ChangeDataConst.excelvalidations.successfulUploadMessage, 'success', 'right');
                    this.requiredParams.requestIssueId = response.requestIssueId;
                    this.requiredParams.isNew = false;
                    this.getChangeData();
                    this.getAssignmentGroups();
                    this.updateSessionStorage(this.requiredParams.requestIssueId!);
                  });
                }
              });
            }
            else {
              for (let item of filteredArr) {
                index++;
                let isMMSKey: boolean = (item.hasOwnProperty('Change Data SAR') && !this.isEmptyfield(item['Change Data SAR'])) ? true : false;
                let skipRequestLineValidation = this.isSkipRequestLineValidation(isMMSKey, item, index);
                if (!skipRequestLineValidation) {
                  this.validationWithWrongMaterialNumberItem(item, index);
                }
              }
              let errormessage: string = '';
              if (this.countErrors > 8) errormessage = `There are ${this.countErrors} errors in the excel file and the first 8 are:<br/>`;
              else errormessage = (this.countErrors === 1 ? 'There is 1 error' : `There are ${this.countErrors} errors`) + ' in the Excel spreadsheet:<br/>';
              errormessage += this.uploadErrors;
              this.commonService.showNotificationMessage(errormessage, 'error', 'center', 5000);
            }
          }
        }
      }
    });
  }

  isSkipRequestLineValidation(isMMSKey: boolean, item: any, index: number): boolean {
    let isSkipRequestLineValidation: boolean = false;
    if (isMMSKey) {
      let requestLineId = this.getRequestLineId(item);
      let availableRequestLine: any = this.existingDatabeforeUpload.find((x: { id: number, materialNumber?: string, changeType?: string, plantOtherChangeType?: string }) => (x['id'] === requestLineId));
      if (availableRequestLine === undefined) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.invalidSARkey);
        isSkipRequestLineValidation = true;
      }
    }
    return isSkipRequestLineValidation;
  }
  validationWithWrongMaterialNumberItem(item: any, index: number) {
    this.updateUploadError(index, ChangeDataConst.excelvalidations.invalidMaterialNumber);
    if (!item.hasOwnProperty('__EMPTY_2') || this.invalidChangeType(item['__EMPTY_2'])) {
      this.validationWithWrongChangeTypeItem(item, index);
    }
    else {
      this.ChangeDataValidation(false, false, item, index)
    }
  }
  validationWithWrongChangeTypeItem(item: any, index: number) {
    this.updateUploadError(index, ChangeDataConst.excelvalidations.invalidChangeType);
    if (!item.hasOwnProperty('__EMPTY_5')) {
      this.updateUploadError(index, ChangeDataConst.excelvalidations.enterOldDataValue);
    }
    if (!item.hasOwnProperty('__EMPTY_6')) {
      this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
    }
  }
  ChangeDataValidation(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    switch (item['__EMPTY_2'].toString().toLocaleLowerCase()) {
      case 'material group code':
        this.setMaterialGroupCode(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.materialGroupCode, item, index, changeTypeValues);
        break;
      case 'manufacturer part number':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.manufacturerPartNumber, item, index, changeTypeValues);
        break;
      case 'vendor part number':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.vendorPartNumber, item, index, changeTypeValues);
        break;
      case 'supplier number':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.supplierNumber, item, index, changeTypeValues);
        break;
      case 'extend branch plant':
        this.setExtendBranchPlanChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.extendBranchPlant, item, index, changeTypeValues);
        break;
      case 'planner number':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.plannerNumber, item, index, changeTypeValues);
        break;
      case 'unit of measure':
        this.setMaterialGroupCode(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.unitOfMeasure, item, index, changeTypeValues);
        break;
      case 'lead time':
        this.setLeadTimeChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, item, index, changeTypeValues);
        break;
      case 'other':
        this.setOtherChangeData(validMaterialNumber, item, index);
        break;
      case 'quality code':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.qualityCode, item, index, changeTypeValues);
        break;
      case 'description':
        this.setDescriptionChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, item, index, changeTypeValues);
        break;
      case 'manufacturer':
        this.setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, this.changeDataEnum.manufacturer, item, index, changeTypeValues);
        break;
      case 'stocking type':
        this.setStockingTypeChangeData(validMaterialNumber, isDuplicateMatNumAndChangeType, item, index, changeTypeValues!);
        break;
    }
  }

  setMaterialGroupCode(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, changeTypeCode: number, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    if (validMaterialNumber) {
      let oldData: string = '';
      let validData: boolean = true;

      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (validData) {
        oldData = item[this.OldDataExcelIndicator];
        this.insertItemInChangeDataList(item, isDuplicateMatNumAndChangeType, changeTypeCode, oldData);
      }
    }
  }

  private hasValidNewData(item: any): boolean {
    return (item.hasOwnProperty(this.NewDataExcelIndicator) && !this.isEmptyfield(item[this.NewDataExcelIndicator]));
  }

  private isNewDataValidNumeric(item: any): boolean {
    let input = item[this.NewDataExcelIndicator];
    return !isNaN(Number(input));
  }

  private hasValidOldData(item: any): boolean {
    return (item.hasOwnProperty(this.OldDataExcelIndicator) && !this.isEmptyfield(item[this.OldDataExcelIndicator]));
  }

  setManufacturerVendorPartNumberOrQualityCodeOrManufacturerChangeData(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, changeTypeCode: number, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    let oldData: string = '';
    let validData: boolean = true;

    if (validMaterialNumber) {
      if (!this.hasValidOldData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (validData) {
        oldData = item[this.OldDataExcelIndicator];
        this.insertItemInChangeDataList(item, isDuplicateMatNumAndChangeType, changeTypeCode, oldData);
      }
    }
  }

  setExtendBranchPlanChangeData(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, changeTypeCode: number, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    let oldData: string = '';
    let validData: boolean = true;

    if (validMaterialNumber) {
      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (validData) {
        oldData = item[this.OldDataExcelIndicator];
        this.insertItemInChangeDataList(item, isDuplicateMatNumAndChangeType, changeTypeCode, oldData);
      }
    }
  }

  setLeadTimeChangeData(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    let oldData: string = '';
    let validData: boolean = true;

    if (validMaterialNumber) {
      if (!this.hasValidOldData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (!this.isNewDataValidNumeric(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNumericNewDataValue);
        validData = false;
      }

      if (validData && !isDuplicateMatNumAndChangeType) {
        oldData = item[this.OldDataExcelIndicator];
        this.insertItemInChangeDataList(item, isDuplicateMatNumAndChangeType, this.changeDataEnum.leadTime, oldData, null);
      }
    }
  }

  setStockingTypeChangeData(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, item: any, index: number, changeTypeValues: ChangeTypeValueDto[]) {
    let plant: string = !item.hasOwnProperty('__EMPTY_3') ? '' : item['__EMPTY_3'].toString();
    let oldData: string = '';
    let validData: boolean = true;
    if (plant === undefined || plant === '') {
      this.updateUploadError(index, ChangeDataConst.excelvalidations.invalidPlant);
      validData = false;
    }

    if (!item.hasOwnProperty('__EMPTY_5')) {
      this.updateUploadError(index, ChangeDataConst.excelvalidations.enterOldDataValue);
      validData = false;
    }

    if (validMaterialNumber && validData) {
      this.insertItemInChangeDataList(item, false, this.changeDataEnum.stockingType, oldData, plant);
    }
  }

  setOtherChangeData(validMaterialNumber: boolean, item: any, index: number) {
    let validData: boolean = true;
    let oldData: string = '';
    if (validMaterialNumber) {
      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (validData) {
        this.insertItemInChangeDataList(item, false, this.changeDataEnum.other, oldData, '');
      }
    }
  }

  setDescriptionChangeData(validMaterialNumber: boolean, isDuplicateMatNumAndChangeType: boolean, item: any, index: number, changeTypeValues?: ChangeTypeValueDto[]) {
    if (validMaterialNumber) {
      let oldData: string = '';
      let validData: boolean = true;

      if (!this.hasValidOldData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterOldDataValue);
        validData = false;
      }

      if (!this.hasValidNewData(item)) {
        this.updateUploadError(index, ChangeDataConst.excelvalidations.enterNewDataValue);
        validData = false;
      }

      if (validData) {
        oldData = item[this.OldDataExcelIndicator];
        this.insertItemInChangeDataList(item, isDuplicateMatNumAndChangeType, this.changeDataEnum.description, oldData);
      }
    }
  }

  indexOfChangeData(requestLineId: number, materialNumber: string) {
    if (this.changeDataList.length > 0) {
      if (requestLineId) {
        return this.changeDataList.findIndex(x => (x.materialNumber === materialNumber.toString() && x.operation === ChangeDataOperationConst.update));
      }
      else {
        return this.changeDataList.findIndex(x => (x.materialNumber === materialNumber.toString() && x.operation === ChangeDataOperationConst.create));
      }
    }
    else {
      return -1;
    }
  }

  insertItemInChangeDataList(item: any, isDuplicateMatNumAndChangeType: boolean, changeDataCode: number, oldData: string, branchPlantOrOtherChangePlant?: any) {
    let requestLineId = this.getRequestLineId(item);
    let changeDataIndex = this.indexOfChangeData(requestLineId, item['__EMPTY_1'].toString());
    let changeDataItem = new ChangeDataDto();

    if (changeDataIndex >= 0) {
      changeDataItem = this.changeDataList[changeDataIndex];
    }
    else {
      changeDataItem = this.setNewChangeData(item, isDuplicateMatNumAndChangeType, item['__EMPTY_1'].toString(), item.hasOwnProperty('__EMPTY_7') ? item['__EMPTY_7'].toString() : '');
    }
    let changeTypeDetails = this.setchangeTypeDetails(item, changeDataCode, oldData.toString(), item['__EMPTY_6'].toString().trim(), branchPlantOrOtherChangePlant);
    changeDataItem.changeTypeDetails?.push(changeTypeDetails);
    if (changeDataIndex === -1) {
      this.changeDataList.push(changeDataItem);
    }
  }

  getRequestLineId(item: any) {
    let requestLineId: number = 0;
    if (item.hasOwnProperty('Change Data SAR') && item['Change Data SAR'] !== '') {
      requestLineId = parseInt(item['Change Data SAR'].substring(0, item['Change Data SAR'].indexOf("-")));
    }
    return requestLineId;
  }
  setNewChangeData(item: any, isDuplicateMatNumAndChangeType: boolean, materialNumber: string, requesterNotes: string) {
    let changeDataItem = new ChangeDataDto();
    let requestLineId: number = this.getRequestLineId(item);
    if (requestLineId)
      this.isUploadNewDataOnly = false;
    changeDataItem.id = 0;
    let operation = ChangeDataOperationConst.create;
    if (requestLineId) {
      operation = ChangeDataOperationConst.update;
    }
    else if (!isDuplicateMatNumAndChangeType) {
      operation = ChangeDataOperationConst.create
    }
    changeDataItem.operation = operation;
    changeDataItem.createdBy = this.commonService.getUserEmailId();
    changeDataItem.modifiedBy = this.commonService.getUserEmailId();
    changeDataItem.lineNumber = 0;
    changeDataItem.materialNumber = materialNumber;
    changeDataItem.requestId = this.requiredParams.requestId!;
    changeDataItem.requestIssueId = this.commonService.isNullOrEmptyOrUndefined(this.requiredParams.requestIssueId)
      ? 0 : this.requiredParams.requestIssueId;
    changeDataItem.requesterNotes = requesterNotes;
    changeDataItem.requestIssueTypeId = 2;
    changeDataItem.changeTypeDetails = [];
    return changeDataItem;
  }
  setchangeTypeDetails(item: any, changeTypeId: number, oldData: string, newData: string, branchPlantOrOtherChangePlant?: any) {
    let requestLineId: number = this.getRequestLineId(item);
    if (requestLineId) {
      this.updatedRequestLineIdList.push(requestLineId);
    }
    let changeTypeDetails = new ChangeTypeDetailsDto();
    changeTypeDetails.id = requestLineId;
    changeTypeDetails.operation = requestLineId ? ChangeDataOperationConst.update : ChangeDataOperationConst.create;
    changeTypeDetails.requestId = this.requiredParams.requestId!;
    changeTypeDetails.lineNumber = 0;
    changeTypeDetails.changeTypeId = changeTypeId;
    changeTypeDetails.changeType = this.changeTypes.find(x => x.id === changeTypeId)?.label;
    changeTypeDetails.changeTypeValues = [];
    let changeTypeValue = new ChangeTypeValueDto();
    changeTypeValue.changeType = this.changeTypes.find(x => x.id === changeTypeId)?.label;
    changeTypeValue.oldData = oldData;
    changeTypeValue.id = requestLineId;
    changeTypeValue.newData = newData;
    changeTypeValue.branchPlants = [];
    if (branchPlantOrOtherChangePlant)
      changeTypeValue.branchPlantOrOtherChangePlant = branchPlantOrOtherChangePlant.toString();

    changeTypeDetails.changeTypeValues.push(changeTypeValue);
    return changeTypeDetails;
  }

  invalidMaterialNumber(matNum: string) {
    if (this.commonService.isNAN(matNum)) {
      return true;
    }

    return false;
  }

  invalidChangeType(changeType: string) {
    let item = this.changeTypes.find(x => x.label?.toLocaleLowerCase() === changeType.toLocaleLowerCase());
    return item === undefined ? true : false;
  }

  isEmptyfield(str: string) {
    let newStr = str.toString().trim();
    return newStr === '' ? true : false;
  }
  isDuplicateMatNumAndChangeType(isMMSKey: boolean, materialNumber: string, changeType: string, plantOtherChangeType: string) {
    if (changeType !== 'Other' && changeType !== 'Lead Time') {
      let index = this.existingDatabeforeUpload.findIndex((x: any) => x.changeType.toLocaleLowerCase() === changeType.toLocaleLowerCase() && x.materialNumber === materialNumber.toString());
      let isSameDataInChangeDataList: boolean = this.isSameDataInChangeDataList(materialNumber, changeType);
      if (index !== -1) {
        if (isMMSKey && !isSameDataInChangeDataList) return false;
        return true;
      }
      else if (isSameDataInChangeDataList) {
        return true;
      }
    }
    if (changeType === 'Lead Time') {
      let index = this.existingDatabeforeUpload.findIndex((x: any) => x.changeType === changeType && x.materialNumber === materialNumber.toString() && x.plantOtherChangeType === plantOtherChangeType);
      let isSameDataInChangeDataList: boolean = this.isSamePlantInChangeDataList(materialNumber, plantOtherChangeType);
      if (index !== -1) {
        if (isMMSKey && !isSameDataInChangeDataList) return false;
        return true;
      }
      else if (isSameDataInChangeDataList) {
        return true;
      }
    }
    return false;
  }
  isSamePlantInChangeDataList(materialNumber: string, plant: string) {
    let changeData: ChangeDataDto = this.changeDataList.find((x: ChangeDataDto) => (x.materialNumber === materialNumber)) as ChangeDataDto;
    if (changeData && changeData.changeTypeDetails) {
      let changeTypeDetailsDto: ChangeTypeDetailsDto | undefined = changeData.changeTypeDetails.find((x: ChangeTypeDetailsDto) => x.changeTypeId === this.changeDataEnum.leadTime);
      if (changeTypeDetailsDto && changeTypeDetailsDto.changeTypeValues && changeTypeDetailsDto.changeTypeValues.length) {
        let branchPlant = changeTypeDetailsDto.changeTypeValues[0].branchPlantOrOtherChangePlant;
        if (branchPlant === plant) {
          return true;
        }
      }
    }
    return false;
  }
  isSameDataInChangeDataList(materialNumber: string, changeType: string) {
    let changeData: ChangeDataDto = this.changeDataList.find((x: ChangeDataDto) => (x.materialNumber === materialNumber)) as ChangeDataDto;
    let changeTypeData = this.changeTypes.find((x: ChangeTypeDto) => (x.label?.toLocaleLowerCase() === changeType.toLocaleLowerCase()));
    if (changeData && changeData.changeTypeDetails && changeTypeData) {
      let changeTypeDetailsDto: ChangeTypeDetailsDto | undefined = changeData.changeTypeDetails.find((x: ChangeTypeDetailsDto) => x.changeTypeId === changeTypeData?.id);
      if (changeTypeDetailsDto) {
        return true;
      }
    }
    return false;
  }
  isValidPlant(materialNumber: string, changeType: string, plant: string, isDuplicateMatNumAndChangeType: boolean, changeTypeValues?: ChangeTypeValueDto[]) {
    if (plant === '') {
      return false;
    }
    if (changeTypeValues && changeTypeValues[0] && changeTypeValues[0].branchPlants?.length) {
      let index = this.existingDatabeforeUpload.findIndex((x: any) => x.materialNumber === materialNumber.toString() && x.changeType === changeType
        && x.plantOtherChangeType === plant);
      if (index < 0) {
        let branchPlantsCodes: string[] = changeTypeValues[0].branchPlants.map(x => { return x.branchPlantCode }) as string[];
        return branchPlantsCodes.includes(plant);
      }
      else if (isDuplicateMatNumAndChangeType) {
        return false;
      }
      return true;
    }
    return false;
  }
  getOldData(changeTypeValues: ChangeTypeValueDto[] | undefined) {
    let oldData: string = '';
    if (changeTypeValues && changeTypeValues.length > 0) {
      oldData = changeTypeValues[0].oldData!;
    }
    return oldData;
  }
  invalidMaterialGroupCode(materialGroupCode: string) {
    let index = this.materialGroupCodeData.findIndex(mgc => mgc.code === materialGroupCode?.trim() || mgc.name?.trim() === materialGroupCode?.trim());
    return index === -1 ? true : false;
  }
  invalidsetUnitOfMeasure(unitOfMeasure: string) {
    let index = this.uomData.findIndex(uom => uom.code === unitOfMeasure?.trim() || uom.name?.trim() === unitOfMeasure.trim());
    return index === -1 ? true : false;
  }
  MaterialGCOldAndNewDataDuplicated(item: any) {
    let newMatGCIndex = this.materialGroupCodeData.findIndex(mgc => mgc.code === item['__EMPTY_6'].toString() || mgc.name === item['__EMPTY_6'].toString());
    if (newMatGCIndex > 0) {
      let materialGroupCode = this.materialGroupCodeData[newMatGCIndex].code;
      if (item.hasOwnProperty('__EMPTY_5') && (materialGroupCode === item['__EMPTY_5'].toString()))
        return true;
      return false;
    }
    return false;
  }
  UnitOfMeasureOldAndNewDataDuplicated(item: any) {
    let newUoMIndex = this.uomData.findIndex(uom => uom.code === item['__EMPTY_6'].toString() || uom.name === item['__EMPTY_6'].toString());
    if (newUoMIndex > 0) {
      let uom = this.uomData[newUoMIndex].code;
      if (item.hasOwnProperty('__EMPTY_5') && (uom === item['__EMPTY_5'].toString()))
        return true;
      return false;
    }
    return false;
  }
  getchangeTypeValues(ChangeDataInfo: ChangeTypeDetailsDto[], changeType: string) {
    let changeTypeKey = changeType.split(' ').join('').toLocaleLowerCase();
    for (let key in ChangeDataEnumObj) {
      if (key.toLocaleLowerCase() === changeTypeKey) {
        let value: number = ChangeDataEnumObj[key];
        return ChangeDataInfo[value - 1]?.changeTypeValues;
      }
    }
    return [];
  }
  addDeletedItems() {
    if (!this.isUploadNewDataOnly) {
      let deletetequestLineList: ChangeDataItem[] = [];
      this.changeDataDetails.items.forEach((item: ChangeDataItem) => {
        if (this.updatedRequestLineIdList.indexOf(item.id) === -1) {
          deletetequestLineList.push(item)
        }
      });
      if (deletetequestLineList.length > 0) {
        for (let data of deletetequestLineList) {
          let changeDataItem = new ChangeDataDto();
          changeDataItem.id = data.id;
          changeDataItem.operation = ChangeDataOperationConst.delete;
          changeDataItem.createdBy = data.createdBy;
          changeDataItem.modifiedBy = this.commonService.getUserEmailId();
          changeDataItem.lineNumber = 0;
          changeDataItem.materialNumber = data.materialNumber;
          changeDataItem.requestId = data.requestId;
          changeDataItem.requestIssueId = data.requestIssueId;
          changeDataItem.requestIssueTypeId = data.requestIssueTypeId;
          changeDataItem.changeTypeDetails = [];
          this.changeDataList.push(changeDataItem);
        }
      }
    }

  }
  updateUploadError(rowIndex: number, message: string) {
    this.countErrors++;
    if (this.countErrors <= 8) {
      this.uploadErrors += `Row ${rowIndex + 4}: ${message}<br/>`
    }
  }
  updateSessionStorage(requestIssueId: number) {
    let sarnewaction = JSON.parse(sessionStorage.getItem('sarnewaction')!);
    sarnewaction.isNew = false;
    sarnewaction.changeType = 'Change Data';
    sarnewaction.requestIssueId = requestIssueId;
    sessionStorage.setItem('sarnewaction', JSON.stringify(sarnewaction));
  }
  DownloadItems() {
    const zip = new JSZip();
    zip.file("ChangeSAR_Raw_Data.xlsx", this.commonService.exportAsExcelFile(this.exportToExcel(), this.changeDataExcelColumns,
      'ChangeSAR_Raw_Data', true));
    this.tempActionService.fetchBlobUrl(this.excelFilePath).subscribe((responseUrl: string) => {
      this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
        const blobdownload = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
        let date = this.commonService.formatDateTo_ddmmyyyy(new Date());
        const fileName = this.requiredParams.requestNumber + '-' + this.excelFileName + '-' + date + '.xlsx';
        zip.file(fileName, blobdownload);
        this.tempActionService.fetchBlobUrl(BlobURlsDetails.changeSARExcelTemplateUserGuide).subscribe((responseUrl: string) => {
          this.commonService.fetchStaticFile(responseUrl).subscribe((data: any) => {
            const blobdownload = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });
            zip.file(BlobURlsDetails.changeSARExcelTemplateUserGuide + '.docx', blobdownload);
            zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, `${this.requiredParams.requestNumber}-ChangeSar-${date}.zip`);
            });
          })
        })
      })
    });
  }

  public exportToExcel(): any {
    let items = this.downloadAndEditData.filter((x) => x.status === 'Draft');
    let convertedArr = [];
    for (let item of items) {
      let obj = {
        mmsKey: item.id + '-' + item.lineNumber,
        lineNumber: item.lineNumber,
        materialItemNumber: item.materialNumber,
        changeType: item.changeType,
        plant: item.changeType === 'Lead Time' ? item.plantOtherChangeType : '',
        otherChangeType: item.changeType === 'Other' ? item.plantOtherChangeType : '',
        oldData: item.changeType === 'Lead Time' ? (item.oldData ? item.oldData : 0) : item.oldData,
        newData: item.newData,
      }
      convertedArr.push(obj)
    }
    return convertedArr;
  }
}
