export const requestLineConst = {
    apiName: 'api/RequestLine',
    updateLineAssignment: '/UpdateLineAssignment',
    deleteLineItems: '/DeleteLineItems',
    updateLineAssignmentForRejected: '/UpdateLineAssignmentForRejected',    
    getAssignmentDetailsByRequestLineId: '/GetAssignmentDetailsByRequestLineId',
    getEquipmentNames: '/equipment',
    getEquipmentProperty: '/equipment/calculate',
    getRecommendedStockingLocations:'/StockingLocations',
    materialNumberInRequestLine: '/MatchingStockingLocations',
    verifyIfMaterialNumberInRequestLine: '/VerifyIfMaterialNumberInRequestLine'
}