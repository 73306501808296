import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDataConst } from 'src/app/core/constants/change-data.const';
import { CommonService } from 'src/app/core/services/common.service';
import { RecommendedStockingLocationDto } from 'src/app/features/setup/models/stocking-location.model.ts/stocking-location.model';
import { of, switchMap } from 'rxjs';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialNumberJdeService } from '../services/material-number-jde.service';
import { NewActionInputParam } from '../models/common/new-action-input-param.model';
import { RequestLineService } from '../services/request-line.service';
import { InventoryLevelUpdateDto, InventoryLevelUpdateGridDataDto } from '../models/change-sar/inventory-level-update.model';
import { InventoryLevelUpdateService } from '../services/inventory-level-update.service';

@Component({
  selector: 'app-inventory-level-update-add-new-item',
  templateUrl: './inventory-level-update-add-new-item.component.html',
  styleUrls: ['./inventory-level-update-add-new-item.component.scss']
})
export class InventoryLevelUpdateAddNewItemComponent implements OnInit {
  newActionInputParam: NewActionInputParam = {};
  gridData: InventoryLevelUpdateGridDataDto[] = [];
  inventoryLevelUpdateDataErrors: InventoryLevelUpdateGridDataDto[] = [];
  materialData: string[] = [];
  itemDescription: string = '';
  recommendedStockingLocations: RecommendedStockingLocationDto[] = [];
  selectedUnitPrice: string;
  lineItem: string = '';
  unitPrices: string[] = [];
  matValidError: boolean;
  materialNumValidationError: boolean;
  disabledTypes: boolean;
  prevSelectedMaterial: any;
  selectedMaterial: number;
  requestorNotesMaxLength: number = 1000;
  requestorNotesCharachtersCount: number = 0;
  isMaterialNumberSelected: boolean = false;
  annualUsageForecast: number;
  stockCount: number = 0;
  totalValue: number = 0;
  changeInValue: number = 0;
  unitPrice: number;
  requesterNotes: string = '';
  assetName: string = '';
  id: number;
  inventoryLevelUpdateStockId: string;
  matError: boolean;
  lineItemStatus: string = '';
  annualUsageForecastError: boolean = false;
  isEditLineAction: boolean = false;
  isGridError: boolean = false;
  errorMessageInvalidMaterialNumberWithAsset: string = "For given Material Number and Asset there are no stocking location found. Please select another Material Number or Asset";
  errorMessageAlreadyExistingMaterialNumberInRequestLine: string = "Entered Material Number already exists in Request Line. Please select another Material Number";
  @ViewChild("autocomplete", { static: false })
  public autocomplete!: AutoCompleteComponent;

  constructor(private commonService: CommonService,
    private materialJdeService: MaterialNumberJdeService,
    public router: Router,
    private route: ActivatedRoute,
    private requestLineService: RequestLineService,
    private inventoryLevelUpdateService: InventoryLevelUpdateService
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.newActionInputParam.assetName = stateData['assetName'];
      this.id = stateData['id'];
      this.assetName = stateData['assetName'];
      this.selectedMaterial = stateData['materialNumber'];
      this.selectedUnitPrice = stateData['unitPrice'];
      this.itemDescription = stateData['itemDescription'];
      this.lineItemStatus = stateData['status'];
      this.annualUsageForecast = stateData['annualUsageForecast'];
      this.stockCount = stateData['stockCount'];
      this.totalValue = stateData['totalValue'];
      this.changeInValue = stateData['changeInValue'];
      this.requesterNotes = stateData['requesterNotes'] ?? '';
      this.isEditLineAction = stateData['isEditLineAction'];
      this.inventoryLevelUpdateStockId = stateData['inventoryLevelUpdateStockId'];
      this.gridData = stateData['inventoryLevelUpdateGridData'] ?? [];
      if (this.gridData !== undefined && this.gridData.length > 0) {
        this.recommendedStockingLocations = this.gridData.map(item => {
          return {
            code: item.code,
            description: item.description,
          };
        });
      }
      if (this.selectedMaterial !== undefined) {
        this.filter(this.selectedMaterial); 
      }
      
      sessionStorage.setItem('inventory-level-update-add-new-item', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('inventory-level-update-add-new-item')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.newActionInputParam.assetName = sessionState.assetName;
      }
    }
  }

  ngOnInit(): void { }

  materialChange(value: any) {
    let indexOfSelectedMaterial = this.materialData.findIndex(x => x === value.toString());
    let isMaterialNumberInLine = false;
    if (indexOfSelectedMaterial === -1) {
      this.matValidError = true;
      this.materialNumValidationError = false;
      this.commonService.showNotificationMessage(ChangeDataConst.globalErrorMessage, 'error', 'right')
      return false;
    }

    else {
      this.matValidError = false;
      this.materialNumValidationError = false;
      if (!this.commonService.isNullOrEmptyOrUndefined(value)) {
        this.matError = false;
      }

      this.requestLineService.getMatchingStockingLocationsInRequestLine(this.newActionInputParam.requestId!, value)
        .subscribe(res => {
          if (res == null || res.length === 0) {
            this.isMaterialNumberSelected = false;
            this.commonService.showNotificationMessage(this.errorMessageInvalidMaterialNumberWithAsset, 'error', 'center');
            return;
          }
          else {
            this.requestLineService
              .verifyIfMaterialNumberInRequestLine(this.newActionInputParam.requestId!, value)
              .subscribe(response => {
                isMaterialNumberInLine = response;

                if (isMaterialNumberInLine) {
                  this.isMaterialNumberSelected = false;
                  this.commonService.showNotificationMessage(this.errorMessageInvalidMaterialNumberWithAsset, 'error', 'center');
                  return;
                }
                else {
                  this.recommendedStockingLocations = res;
                  this.getItemData();
                }
              });
          }
        });
    }
  }

  getItemData() {
    this.materialJdeService
      .getInventoryLevelUpdateItemData(this.selectedMaterial.toString(), this.recommendedStockingLocations[0].code!)
      .subscribe(res => {
        if (res == null) {
          this.isMaterialNumberSelected = false;
          this.commonService.showNotificationMessage(this.errorMessageInvalidMaterialNumberWithAsset, 'error', 'center');
          return;
        }
        else {
          this.itemDescription = res.description;
          this.unitPrice = res.unitPrice
          this.setUnitPrices(this.unitPrice);
          this.isMaterialNumberSelected = true;
        }
      })
  }

  setUnitPrices(unitPrice: number) {
    for (const location of this.recommendedStockingLocations) {
      const item = `${location.code} - ${unitPrice}`;
      this.unitPrices.push(item);
    }
  }

  selectLocation(input: RecommendedStockingLocationDto[]): void {
    const newestSelectedStockingLocation = input[input.length - 1];
    if (!newestSelectedStockingLocation) {
      return;
    }

    const lastInputCode = input[input.length - 1].code;
    if (this.gridData !== undefined && this.gridData.some(item => item.code === lastInputCode)) {
      return;
    }

    this.materialJdeService.getInventoryLevelUpdateRowData(this.selectedMaterial.toString(), newestSelectedStockingLocation.code!)
      .subscribe(res => {
        const item: InventoryLevelUpdateGridDataDto = {
          id: 0,
          code: newestSelectedStockingLocation.code!,
          description: res.description,
          existingMinimum: res.existingMinimum,
          existingMaximum: res.existingMaximum,
          recommendedMinimum: 0,
          recommendedMaximum: 0,
          estimatedStockHoldingValue: 0
        };
      this.gridData.push(item);
    });
  }

  removeLocation(input: RecommendedStockingLocationDto): void {
    let matchingItems = this.gridData.filter(item => item.code === input.code);
    if (matchingItems.length !== 0) {
      matchingItems.forEach(item => {
        const index = this.gridData.indexOf(item);
        if (index !== -1) {
          this.gridData.splice(index, 1);
        }
      });
    }
    this.calculateValues();
  }

  filter(value: any) {
    if (value.toString().length >= 3) {
      this.materialJdeService.getMaterialNumbers(value.toString()).pipe(
        switchMap(result => {
          return of(result);
        })
      ).subscribe(result => {
        this.materialData = result;
        if (this.materialData.length === 1) {
            this.selectedMaterial = Number(this.materialData[0]);
            this.materialChange(this.selectedMaterial);
        }
      });
    } else {
      this.isMaterialNumberSelected = false;
      this.autocomplete.toggle(false);
    }
  }

  public unitPriceSelectionChange(value: any): void { }

  isMinMaxError(dataItem: InventoryLevelUpdateGridDataDto): boolean {
    let isError = (dataItem.recommendedMinimum ?? 0) >= (dataItem.recommendedMaximum ?? 0);
    if (dataItem.recommendedMinimum == 0 && dataItem.recommendedMaximum == 0) {
      isError = false;
    }
    if (isError) {
      this.inventoryLevelUpdateDataErrors.push(dataItem);
      this.isGridError = isError;
    }
    else {
      const index = this.inventoryLevelUpdateDataErrors.indexOf(dataItem);
      this.inventoryLevelUpdateDataErrors.splice(index, 1);
      this.isGridError = false;
    }

    return this.isGridError;
  }

  updateValue(value: any) {
    if (value == null || value == undefined || value < 0) {
      return 0
    }
    return value;
  }

  onValueChange(newValue: number, dataItem: any): void {
    dataItem.recommendedMaximum = newValue;
    this.setEstimatedStockHoldingValue(dataItem);
    this.calculateValues();
  }

  calculateValues(): void {
    this.setStockCountValue();
    this.setTotalValue();
    this.setChangeInValue();
  }

  setEstimatedStockHoldingValue(dataItem: any): void {
    const index = this.gridData.findIndex(item => item === dataItem);
    if (index !== -1) {
      this.gridData[index].estimatedStockHoldingValue = (dataItem.recommendedMaximum ?? 0) * this.unitPrice;
    }
  }

  setStockCountValue(): void {
    this.stockCount = this.gridData.reduce((sum, dataItem) => sum + (dataItem.recommendedMaximum ?? 0), 0);
  }

  setTotalValue(): void {
    this.totalValue = this.stockCount * this.unitPrice;
  }

  setChangeInValue(): void {
    let sumRecommendedMaximum = this.gridData.reduce((sum, dataItem) => sum + (dataItem.recommendedMaximum ?? 0), 0);
    let sumExistingMaximum = this.gridData.reduce((sum, dataItem) => sum + (dataItem.existingMaximum ?? 0), 0);
    this.changeInValue = (sumExistingMaximum * this.unitPrice) - (sumRecommendedMaximum * this.unitPrice);
  }

  public requestorNotesValueChange(value: any): void {
    this.requestorNotesCharachtersCount = value.length;
  }

  public navToBack(): void {
    this.router.navigate(['./' + 'newaction'], {
      state: {
        isNew: this.newActionInputParam.isNew,
        requestId: this.newActionInputParam.requestId,
        requestIssueId: this.newActionInputParam.requestIssueId,
        changeType: 'Inventory Level Update',
        requestNumber: this.newActionInputParam.requestNumber,
        actionNumber: this.newActionInputParam.actionNumber,
        assetName: this.newActionInputParam.assetName
      }, relativeTo: this.route.parent
    });
  }

  public validate(): boolean {
    if (this.commonService.isNullOrEmptyOrUndefined(this.annualUsageForecast)) {
      this.commonService.showNotificationMessage('Please fill the highlighted field', 'error', 'right')
      this.annualUsageForecastError = true;
      return false;
    }
    else {
      this.annualUsageForecastError = false;
    }

    if (this.isGridError) {
      this.commonService.showNotificationMessage('Please fill the highlighted field', 'error', 'right')
      return false;
    }

    return true;
  }

  public AddAndUpdate(): void {
    if (!this.validate()) {
      return;
    }
    const inventoryLevelUpdateIssueTypeId: number = 6;
    let item: InventoryLevelUpdateDto = {
      createdBy: this.commonService.getUserEmailId(),
      lineNumber: 0,
      id: 0,
      requestId: this.newActionInputParam.requestId!,
      requestIssueTypeId: inventoryLevelUpdateIssueTypeId,
      requestIssueId: this.commonService.isNullOrEmptyOrUndefined(this.newActionInputParam.requestIssueId)
        ? 0 : this.newActionInputParam.requestIssueId,
      materialNumber: this.selectedMaterial,
      itemDescription: this.itemDescription,
      asset: this.newActionInputParam.assetName!,
      requesterNotes: this.requesterNotes,
      annualUsageForecast: this.annualUsageForecast,
      stockCount: this.stockCount,
      totalValue: this.totalValue,
      changeInValue: this.changeInValue,
      status: 'Change Sar',
      modifiedBy: '',
      unitPrice: this.unitPrice,
      inventoryLevelUpdateGridData: this.gridData
    }

    if (this.isEditLineAction !== undefined && this.isEditLineAction) {
      item.id = this.id;
      item.inventoryLevelUpdateStockId = this.inventoryLevelUpdateStockId;
      this.inventoryLevelUpdateService.updateItem(item).subscribe(res => {
        this.commonService.showNotificationMessage('Request line succesfully updated', 'success', 'right')
        this.router.navigate(['./' + 'newaction'], {
          state: {
            isNew: false, 
            requestId: res.requestId,
            requestLineId: res.requestLineId,
            requestIssueId: res.requestIssueId,
            changeType: 'Inventory Level Update',
            requestNumber: this.newActionInputParam.requestNumber,
            actionNumber: this.newActionInputParam.actionNumber,
            assetName: this.newActionInputParam.assetName
          }, relativeTo: this.route.parent
        });
      });
    }
    else {
      this.inventoryLevelUpdateService.addNewItem(item).subscribe(res => {
        this.commonService.showNotificationMessage('Request line succesfully created', 'success', 'right')
        this.router.navigate(['./' + 'newaction'], {
          state: {
            isNew: false, 
            requestId: res.requestId,
            requestLineId: res.requestLineId,
            requestIssueId: res.requestIssueId,
            changeType: 'Inventory Level Update',
            requestNumber: this.newActionInputParam.requestNumber,
            actionNumber: this.newActionInputParam.actionNumber,
            assetName: this.newActionInputParam.assetName
          }, relativeTo: this.route.parent
        });
      });
    }
    
  }
}

