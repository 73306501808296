import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { Router } from '@angular/router';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { InventoryLevelUpdateDto, InventoryLevelUpdateGridDataDto } from '../../../models/change-sar/inventory-level-update.model';
import { InventoryLevelUpdateService } from '../../../services/inventory-level-update.service';
import { SelectionEvent } from '@progress/kendo-angular-grid';
import { CommonService } from 'src/app/core/services/common.service';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { ApprovalMMDActionDetail, ApprovalSAActionDetail } from '../../../models/approval-details/action-detail.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ApprovalAssignmentComponent } from '../approval-assignment/approval-assignment.component';
import { ConsultNewActionDto } from '../../../models/add-new-action/consult-new-action.model';
import { AddNewActionService } from '../../../services/add-new-action.service';
import { forkJoin } from 'rxjs';
import { QACodeDto } from 'src/app/features/setup/models/qacode/qacode.model';
import { GroupDto } from 'src/app/features/setup/models/userandrole/group.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { Location } from '@angular/common';
import { BulkActionComponent } from 'src/app/core/components/bulk-action/bulk-action.component';

@Component({
  selector: 'app-inventory-level-update-approval',
  templateUrl: './inventory-level-update-approval.component.html',
  styleUrls: ['./inventory-level-update-approval.component.scss']
})
export class InventoryLevelUpdateApprovalComponent {

  @Input() public requiredParams: NewActionInputParam = {};
  @Input() public currentActionName: string = '';
  @Input() public dummyInput: number = 0; // approach for triggering "ngOnChanges()" to refresh the item list

  @Output() saActionEvent = new EventEmitter<ApprovalSAActionDetail>();
  @Output() mmdActionEvent = new EventEmitter<ApprovalMMDActionDetail>();
  @Output() mmdBulkActionEvent = new EventEmitter<ApprovalMMDActionDetail[]>();
  @Output() saBulkActionEvent = new EventEmitter<ApprovalSAActionDetail[]>();

  private abuName: string = "ABU";

  mainGridData: InventoryLevelUpdateDto[] = [];
  nestedGridData: InventoryLevelUpdateGridDataDto[] = this.mainGridData.length > 0 ? this.mainGridData[0].inventoryLevelUpdateGridData : [];
  showGridSection = false;
  isBulk: boolean = false;
  mySelection: number[] = [];
  consultAction: ConsultNewActionDto = { GroupId: -1 };
  consultClicked: boolean = false;
  userGroupSelectDisabled: boolean = true;
  qaCodes: QACodeDto[] = [];
  groups: GroupDto[] = [];
  buName: string = '';
  consultErrorDialog = false;
  consultBtnDisabled = true;
  selectedUserGroup?: UserDto;
  selectedGroup?: GroupDto;
  selectedQaCode?: QACodeDto;
  groupUsers: UserDto[] = [];

  qaCodeSelectPlaceHolder: QACodeDto = {
    name: "Select QA code",
    id: -1,
  };

  userGroupSelectPlaceHolder: UserDto = {
    name: "Select user group...",
    id: -1,
    provisionId: undefined,
    email: undefined,
    cai: undefined,
    userName: undefined,
    externalId: undefined,
    businessUnitId: undefined,
    groupIds: undefined,
    roleIds: undefined,
    roleDtos: undefined,
    groupDtos: undefined,
    businessUnitname: undefined
  };

  groupSelectPlaceHolder: GroupDto = {
    name: "Select group...",
    id: -1,
    description: undefined,
    businessunitId: undefined,
    groupTypeId: undefined,
    displayOrder: undefined,
    businessunitName: undefined,
    groupTypeName: undefined,
    isActive: undefined,
    createdBy: undefined,
    createdDateTime: undefined,
    modifiedBy: undefined,
    modifiedDateTime: undefined
  };

  constructor(
    public router: Router,
    private inventoryLevelUpdateService: InventoryLevelUpdateService,
    private addNewActionService: AddNewActionService,
    private location: Location,
    private dialogService: DialogService,
    public commonService: CommonService,
  ) { }

  ngOnChanges(): void {
    this.buName = this.commonService.getUserBUName();
    this.getInventoryLevelUpdateData();
  }

  downloadExcel(): void {
  }

  bulk(): void {
    let isComplete = false;
    if (this.currentActionName === 'mmd-action') {
      for (let index of this.mySelection) {
        if ((this.commonService.isNullOrEmptyOrUndefined(this.mainGridData[index].materialNumber) || !Number(this.mainGridData[index].materialNumber))) {
          isComplete = true;
          break;
        }
      }
    }
    const dialog: DialogRef = this.dialogService.open({
      content: BulkActionComponent
    });
    const inc = dialog.content.instance as BulkActionComponent;
    inc.isMMD = this.currentActionName === 'mmd-action' ? true : false;
    inc.isCompleteDisable = isComplete;
    inc.changeType = this.requiredParams.changeType!;
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.action) || r.action === 'Close') {
        return false;
      }
      this.bulkAction(r.action, r.lineItemDtail);
    });
  }

  bulkAction(actionType: string, lineItemDtail: any) {
    if (this.currentActionName === 'mmd-action') {
      let approvalMMDActionDetail: ApprovalMMDActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.mainGridData[selectedValue];
        if (dataItem.status !== 'Pending MMD') {
          continue;
        }
        let approvalActionDetail = this.getmmdValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.mmdAction = 3;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.mmdAction = 1;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.mmdAction = 2;
        }
        approvalMMDActionDetail.push(approvalActionDetail);
      }
      this.mmdBulkActionEvent.emit(approvalMMDActionDetail);
    }
    if (this.currentActionName === 'sa-action') {
      let approvalSAActionDetail: ApprovalSAActionDetail[] = [];
      for (let selectedValue of this.mySelection) {
        let dataItem = this.mainGridData[selectedValue];
        if (dataItem.status !== 'Pending Stocking Approval') {
          continue;
        }
        let approvalActionDetail = this.getsaValues(dataItem);
        if (actionType === 'Cancel') {
          approvalActionDetail.stockApproverAction = 3;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Reject') {
          approvalActionDetail.stockApproverAction = 1;
          approvalActionDetail.assignedGroupId = null;
          approvalActionDetail.assignedUserId = null;
        }
        if (actionType === 'Complete') {
          approvalActionDetail.stockApproverAction = 2;
          approvalActionDetail.assignedGroupId = lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = lineItemDtail.assignedUserId;
        }
        approvalSAActionDetail.push(approvalActionDetail);
      }
      this.saBulkActionEvent.emit(approvalSAActionDetail);
    }
  }

  getInventoryLevelUpdateData() {
    this.inventoryLevelUpdateService
      .getInventoryLevelUpdateData(this.requiredParams.requestId!)
      .subscribe((data) => {
        if (data[0].totalValue === 0) {
          this.showGridSection = true;
        }
        this.mainGridData = data;
        this.nestedGridData = this.mainGridData.length > 0 ? this.mainGridData[0].inventoryLevelUpdateGridData : [];
      });
  }

  selectionChange(e: SelectionEvent): void {
    this.isBulk = this.mySelection.length > 1;
  }

  getLocationsCodes(data?: InventoryLevelUpdateGridDataDto[]): string {
    return data?.map(item => item.code).join(',') ?? '';
  }

  getSAButtonText(): string {
    if (this.currentActionName === 'sa-action') {
      return 'Approve';
    }
    else {
      return 'Complete';
    }
  }

  consult(dataItem: InventoryLevelUpdateDto): void {
    this.consultAction.Id = dataItem.id
    this.consultAction.RequestLineId = dataItem.id;
    this.consultAction.Status = dataItem.itemStatus;
    this.consultAction.Comment = '';
    this.getConsultItems();
    this.consultClicked = true;
  }

  getConsultItems() {
    const getAllQACodes$ = this.addNewActionService.getAllQACodes();
    const getGroups$ = this.addNewActionService.getGroups();

    forkJoin([getAllQACodes$, getGroups$]).subscribe(([qaCodes, groups]) => {
      this.qaCodes = qaCodes;
      this.groups = groups.filter(x => {
        if ((this.buName === this.abuName && x.businessunitName === this.abuName) || this.buName !== this.abuName) {
          return true;
        }
      });
      this.userGroupSelectDisabled = true;
    });
  }

  closeConsultError() {
    this.consultErrorDialog = false;
  }

  consultActionClick(): void {
    if (this.selectedUserGroup == undefined) {
      this.consultErrorDialog = true;
      return;
    }

    this.consultAction.GroupId = this.selectedGroup!.id;
    this.consultAction.UserGroupId = this.selectedUserGroup?.id;

    this.addNewActionService
      .updateOnConsultAction(this.consultAction)
      .subscribe(() => {
        this.consultClicked = false;
        this.location.back();
      })
  }

  closeDialog(): void {
    this.consultClicked = false;
    this.consultBtnDisabled = true;
  }

  qaCodeSelectionChange(value: QACodeDto): void {
    if (value != undefined) {
      this.selectedQaCode = value;
    }
  }

  userGroupSelectionChange(value: UserDto): void {
    if (value != undefined) {
      this.selectedUserGroup = value;
    }
  }

  groupSelectionChange(value: GroupDto): void {
    if (value != undefined && value.id != -1) {
      this.selectedGroup = value;
      this.addNewActionService.getUserGroups(value.id).subscribe(
        (result) => {
          this.groupUsers = result;
          this.userGroupSelectDisabled = false;
          this.consultBtnDisabled = false;
        }
      );
    }
  }

  close(): void {
    this.consultClicked = false;
  }

  reject(dataItem: InventoryLevelUpdateDto): void {
    this.commonService.showConfirmation('Rejection', ApprovalDetailsConstants.rejectMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          if (this.currentActionName === 'mmd-action') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 1;
            this.mmdActionEvent.emit(approvalActionDetail);
          }
          if (this.currentActionName === 'sa-action') {
            let approvalActionDetail = this.getsaValues(dataItem);
            approvalActionDetail.assignedGroupId = null;
            approvalActionDetail.assignedUserId = null;
            approvalActionDetail.stockApproverAction = 1;
            this.saActionEvent.emit(approvalActionDetail);
          }
        }
      });
  }

  complete(dataItem: InventoryLevelUpdateDto): void {
    if (this.currentActionName === 'mmd-action') {
      this.commonService.showConfirmation('Complete', ApprovalDetailsConstants.completeMessage)
        .subscribe((res: any) => {
          if (res.action === 'yes') {
            let approvalActionDetail = this.getmmdValues(dataItem);
            approvalActionDetail.mmdAction = 2;
            this.mmdActionEvent.emit(approvalActionDetail);
          }
        });
    }

    if (this.currentActionName === 'sa-action') {
      let approvalActionDetail = this.getsaValues(dataItem);
      const dialog: DialogRef = this.dialogService.open({
        content: ApprovalAssignmentComponent
      });
      dialog.result.subscribe((r: any) => {
        if (r.actionType == 's') {
          approvalActionDetail.assignedGroupId = r.lineItemDtail.assignedGroupId;
          approvalActionDetail.assignedUserId = this.commonService.isNullOrEmptyOrUndefined(
            r.lineItemDtail.assignedUserId) ? null : r.lineItemDtail.assignedUserId;
          approvalActionDetail.stockApproverAction = 2;
          this.saActionEvent.emit(approvalActionDetail);
        }
      });
    }
  }

  private getmmdValues(dataItem: InventoryLevelUpdateDto): ApprovalMMDActionDetail {
    let approvalMMDActionDetail: ApprovalMMDActionDetail = new ApprovalMMDActionDetail()
    approvalMMDActionDetail.comment = '';
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.enrichedDescription = '';
    approvalMMDActionDetail.materialNumber = '';
    approvalMMDActionDetail.shortDescription = ''
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 6;
    approvalMMDActionDetail.requestLineId = dataItem.id!;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

  private getsaValues(dataItem: InventoryLevelUpdateDto): ApprovalSAActionDetail {
    let approvalMMDActionDetail: ApprovalSAActionDetail = new ApprovalSAActionDetail()
    approvalMMDActionDetail.comment = '';
    approvalMMDActionDetail.createdBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.modifiedBy = this.commonService.getUserEmailId();
    approvalMMDActionDetail.requestIssueId = this.requiredParams.requestIssueId!;
    approvalMMDActionDetail.requestIssueTypeId = 6;
    approvalMMDActionDetail.requestLineId = dataItem.id!;
    approvalMMDActionDetail.requestId = this.requiredParams.requestId!;
    return approvalMMDActionDetail;
  }

}
