<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navToBack()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request</span>
            <span class="in-active"> {{newActionInputParam.requestNumber}}</span>
            <span class="active" *ngIf="newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>New
                Item </span>
            <span class="active" *ngIf="!newActionInputParam.isNew"><i class="fa fa-fw fa-angle-right"></i>
                Item {{lineItem}}
            </span>
        </h1>
        <div>
            <button kendoButton class="border-button ml-20 f-size-150" (click)="navToBack()">Cancel</button>
            <button kendoButton class="primary-button ml-20 f-size-150" (click)="AddAndUpdate()">Save and exit</button>
        </div>
    </div>

    <section>
        <div class="mt-10 w-25">
            <kendo-label text="Material JDE Number"></kendo-label><span class="mandatory">*</span> <br>
            <kendo-autocomplete [data]="materialData" #autocomplete [(ngModel)]="selectedMaterial" [filterable]="true"
                (filterChange)="filter($event)" (valueChange)="materialChange($event)"
                placeholder="Type 3 or more digits" class="mt-5"></kendo-autocomplete>
            <span *ngIf="matError" class="noValue"> Material number fieled cannot be empty</span>
            <span *ngIf="matValidError" class="noValue"> Please select valid material number</span>
            <span *ngIf="materialNumValidationError" class="noValue"> Selected Material number does not belongs to
                current BU</span>
        </div>
    </section>

    <div *ngIf="isMaterialNumberSelected">
        <div class="mt-10">
            <fieldset>
                <legend for="">Item Details</legend>
                <div>
                    <kendo-label text="Item Description"></kendo-label>
                    <br>
                    <kendo-textarea [(ngModel)]="itemDescription" class="mt-5" [rows]="6" [cols]="60" resizable="none">
                    </kendo-textarea>
                </div>
                <div class="mt-5 d-w-b qj-c-s g-10">
                    <div>
                        <kendo-label text="Unit Price"></kendo-label>
                        <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedUnitPrice" [data]="unitPrices"
                            [defaultItem]="unitPrices[0]" (selectionChange)="unitPriceSelectionChange($event)">
                        </kendo-dropdownlist>
                    </div>
                    <div class="w-25">
                        <kendo-label text="Enter Unit Price(AUD)"></kendo-label>
                        <kendo-textbox class="mt-5" [(ngModel)]="unitPrice" [disabled]="true"></kendo-textbox>
                    </div>
                </div>
                <div class="mt-10 mx-5 w-50">
                    <kendo-label text="Recommended Stocking Locations"></kendo-label><br>
                    <kendo-multiselect [data]="recommendedStockingLocations" textField="code" valueField="code"
                        (removeTag)="removeLocation($event.dataItem)" (valueChange)="selectLocation($event)"
                        placeholder="-- Please Select At Least One Branch --">
                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                            <span>
                                <button class="autoCompleteItem">
                                    {{ dataItem.code }}
                                </button>
                            </span>
                        </ng-template>
                    </kendo-multiselect>
                </div>
                <div class="mt-10 mx-5">
                    <kendo-grid [data]="gridData" [resizable]="true">
                        <kendo-grid-column title="Branch Plant Code" field="code" [width]="120"></kendo-grid-column>
                        <kendo-grid-column title="Description" field="description" [width]="150"></kendo-grid-column>
                        <kendo-grid-column title="Existing Minumum" field="existingMinimum" [width]="100"></kendo-grid-column>
                        <kendo-grid-column title="Recommended Minumum" field="recommendedMinimum" [width]="120">
                            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                <kendo-numerictextbox [(ngModel)]="dataItem.recommendedMinimum"
                                    [ngModelOptions]="{standalone: true}" class="grid-input" format="##" [min]="0"
                                    [spinners]="false"></kendo-numerictextbox>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Existing Maximum" field="existingMaximum" [width]="100"></kendo-grid-column>
                        <kendo-grid-column title="Recommended Maximum" field="recommendedMaximum" [width]="120">
                            <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                let-columnIndex="columnIndex">
                                Recommended Maximum
                                <span kendoTooltip tooltipClass="tooltip-design" id="recommendedMaximum"
                                    title="Setting the MIN = 0 and MAX = 1 will mean when we use one, we will go and order another.
                                A setting of MIN = 1 and MAX = 4 will mean when our stock level falls to 0, we will order 4 more to restore our max stock level."
                                    class="fa fa-question-circle primary-color title-tool ml-5"></span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem let-column="column">
                                <kendo-numerictextbox class="grid-input" format="##" [min]="0"
                                    [(ngModel)]="dataItem.recommendedMaximum" [ngModelOptions]="{standalone: true}"
                                    [ngStyle]="{'border-color': isMinMaxError(dataItem) ? 'red' : 'rgba(0, 0, 0, 0.08)'}"
                                    (ngModelChange)="onValueChange($event, dataItem)"
                                    (blur)="dataItem.recommendedMaximum = updateValue(dataItem.recommendedMaximum)"
                                    [spinners]="false"></kendo-numerictextbox>
                                <span style="color:red" *ngIf="isMinMaxError(dataItem)">
                                    Maximum must be greater than the Minimum
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Estimated Stock Holding Value" field="estimatedStockHoldingValue" [width]="200">
                            <ng-template class="status-column" kendoGridHeaderTemplate let-column
                                let-columnIndex="columnIndex">
                                Estimated Stock Holding Value
                                <span kendoTooltip tooltipClass="tooltip-design" id="recommendedMaximum"
                                    title="Maximum x Quoted Price"
                                    class="fa fa-question-circle primary-color title-tool ml-5"></span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </fieldset>
        </div>

        <div class="mt-10 mx-5 w-50">
            <kendo-label text="Annual Usage Forecast"></kendo-label><span class="mandatory">*</span><br>
            <kendo-numerictextbox [(ngModel)]="annualUsageForecast" class="mt-5" name="annualUsageForecast"
                [style.width.px]="180" format="n0" [decimals]="0">
            </kendo-numerictextbox>
            <div>
                <span *ngIf="annualUsageForecastError" class="noValue"> Annual Usage Forecast cannot be empty</span>
            </div>
          </div>

        <div class="mt-10 mx-5 w-50 d-flex j-c-s">
            <div>
                <kendo-label text="Stock count"></kendo-label><br>
                <kendo-label text="{{stockCount}}" style="font-weight: bold;"></kendo-label><br>
            </div>
            <div>
                <kendo-label text="Total value (AUD)"></kendo-label><br>
                <kendo-label text="{{totalValue}}" style="font-weight: bold;"></kendo-label><br>
            </div>
            <div>
                <kendo-label text="Change in Value (AUD)"></kendo-label><br>
                <kendo-label text="{{changeInValue}}" style="font-weight: bold;"></kendo-label><br>
            </div>
        </div>

        <section class="w-75 d-flex flex">
            <div class="w-75 mx-5">
                <br>
                <div class="d-flex j-c-s">
                    <kendo-label text="Requestor Notes :"></kendo-label>
                    <kendo-label>Characters Left: {{requestorNotesMaxLength - requestorNotesCharachtersCount}}</kendo-label>
                </div>
                <kendo-textarea [(ngModel)]="requesterNotes" [rows]="3" [maxlength]="requestorNotesMaxLength" [cols]="200" resizable="both"
                    (valueChange)="requestorNotesValueChange($event)">
                </kendo-textarea>
            </div>
        </section>
    </div>
</div>