import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { FormsModule } from '@angular/forms';
import { ManageSARComponent } from "../components/manage-sar/manage-sar.component";
import { ViewSARComponent } from "../components/view-sar/view-sar.component";
import { SarDashboardComponent } from "../components/sar-dashboard/sar-dashboard.component";
import { AddNewActionComponent } from "../components/add-new-action/add-new-action.component";
import { KendoModule } from "src/app/modules/kendo.module";
import { AddNewItemComponent } from "../components/add-new-item/add-new-item.component";
import { AddRequestComponent } from "../components/add-request/add-request.component";
import { ChangeDataComponent } from "../components/change-sar/change-data/change-data.component";
import { ExtnMRPUpdateComponent } from "../components/change-sar/extn-mrp-update/extn-mrp-update.component";
import { SarNewActionComponent } from "../components/sar-new-action/sar-new-action.component";
import { ChangeDataAddNewItemComponent } from "../components/change-sar/change-data-add-new-item/change-data-add-new-item.component";
import { ExtnMRPUpdateAddNewItemComponent } from "../components/change-sar/extn-mrp-update-add-new-item/extn-mrp-update-add-new-item.component";
import { ExtnMrpUpdateExcelTemplateComponent } from "../components/change-sar/extn-mrp-update-excel-template/extn-mrp-update-excel-template.component";
import { AddNewItemApprovalComponent } from "../components/approval-management/add-new-item-approval/add-new-item-approval.component";
import { ChangeDataApprovalComponent } from "../components/approval-management/change-data-approval/change-data-approval.component";
import { ExtnMrpUpdateApprovalComponent } from "../components/approval-management/extn-mrp-update-approval/extn-mrp-update-approval.component";
import { ViewRequestorActionComponent } from "../components/approval-management/view-requestor-action/view-requestor-action.component";
import { AddNewEntryComponent } from "../components/add-new-entry/add-new-entry.component";
import { ReplaceCommaPipe } from "../Pipe/approval-request.pipe";
import { ApprovalAssignmentComponent } from "../components/approval-management/approval-assignment/approval-assignment.component";
import { ReAssignItemComponent } from "../components/approval-management/re-assign-item/re-assign-item.component";
import { ReActivateComponent } from "../components/change-sar/re-activate/re-activate.component";
import { InventoryLevelUpdateComponent } from "../components/inventory-level-update/inventory-level-update.component";
import { InventoryLevelUpdateAddNewItemComponent } from "../inventory-level-update-add-new-item/inventory-level-update-add-new-item.component";
import { InventoryLevelUpdateGenerateExcelComponent } from "../components/inventory-level-update-generate-excel/inventory-level-update-generate-excel.component";
import { InventoryLevelUpdateApprovalComponent } from "../components/approval-management/inventory-level-update-approval/inventory-level-update-approval.component";

@NgModule({
    declarations: [
        SarDashboardComponent,
        ManageSARComponent,
        ViewSARComponent,
        ExtnMRPUpdateAddNewItemComponent,
        AddNewActionComponent,
        AddNewItemComponent,
        InventoryLevelUpdateComponent,
        InventoryLevelUpdateAddNewItemComponent,
        InventoryLevelUpdateGenerateExcelComponent,
        SarNewActionComponent,
        ExtnMRPUpdateComponent,
        ChangeDataComponent,
        AddRequestComponent,
        ChangeDataAddNewItemComponent,
        ExtnMrpUpdateExcelTemplateComponent,
        ViewRequestorActionComponent,
        ChangeDataApprovalComponent,
        ExtnMrpUpdateApprovalComponent,
        AddNewItemApprovalComponent,
        AddNewEntryComponent,
        ReplaceCommaPipe,
        AddNewItemApprovalComponent,
        AddNewEntryComponent,
        ApprovalAssignmentComponent,
        ReAssignItemComponent,
        ReActivateComponent,
        InventoryLevelUpdateApprovalComponent,
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        KendoModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        ManageSARComponent,
        ViewSARComponent,
        SarDashboardComponent,
        ExtnMRPUpdateAddNewItemComponent,
        AddNewActionComponent,
        AddNewItemComponent,
        SarNewActionComponent,
        ExtnMRPUpdateComponent,
        ChangeDataComponent,
        AddRequestComponent,
        ChangeDataAddNewItemComponent,
        ExtnMrpUpdateExcelTemplateComponent,
        ViewRequestorActionComponent,
        ChangeDataApprovalComponent,
        ExtnMrpUpdateApprovalComponent,
        AddNewItemApprovalComponent,
        InventoryLevelUpdateAddNewItemComponent,
        AddNewEntryComponent,
        ApprovalAssignmentComponent,
        ReAssignItemComponent,
        ReActivateComponent
    ],
    providers: [
    ],
})
export class SARModule { }
