<div class="sar-contentBox">
  <div class="content-top d-flex j-c-s">
      <h1><a class="c-left" (click)="navToBack()"><span class="fa fa-fw fa-angle-left"></span></a>
          <span class="in-active">Stock Authorization Request</span>
          <span class="in-active"> {{newActionInputParam.requestNumber}}</span>
          <span class="active"><i class="fa fa-fw fa-angle-right"></i>Bulk Items Generate Excel</span>
      </h1>
      <div>
          <button kendoButton class="border-button ml-20 f-size-150" (click)="navToBack()">Cancel</button>
          <button [disabled]="!itemsValidated" kendoButton class="border-button ml-5 f-size-210" (click)="downloadExcelTemplate()">Download and Close</button>
      </div>
  </div>
  <section class="w-75 d-flex flex">
      <div class="w-75 mx-5">
        <div>
          <kendo-label text="JDE Item Number"></kendo-label>
        </div>
        <kendo-textarea resizable="vertical" rounded="small" [style.width.px]="500" [rows]="10"
                        [(ngModel)]="enteredMaterialNumbers">
        </kendo-textarea>
        <button kendoButton class="primary-button f-size-50 mx-5" [disabled]="!enteredMaterialNumbers" (click)="onValidate()">
          Validate
        </button> 
      </div>
  </section>
  <br />
  <section class="w-75 d-flex flex">
    <div class="w-75 mx-5 form-container">
      <kendo-formfield showHints="always">
        <kendo-label text="Select validated JDE numbers">
          <span style="color:red"> * </span>
          <i kendoTooltip title="Use CTRL + A to select all items" class="icon-max-height k-icon k-i-information"></i>
        </kendo-label>
          <div>
            <kendo-multiselect 
                [data]="validMaterialNumbers"
                [value]="selectedMaterialNumbers"
                [(ngModel)]="selectedMaterialNumbers"
                >
            </kendo-multiselect>
          </div>
      </kendo-formfield>
    </div>
  </section>
</div>