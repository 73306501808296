<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1> <a class="c-left" (click)="navViewSAR()"> <span class="fa fa-fw fa-angle-left"></span></a>
            <span class="in-active"> Stock Authorization Request
                <span>{{newActionInputParam.requestNumber}}</span>
            </span>
            <span class="active"><i class="fa fa-fw fa-angle-right"></i>
                <span *ngIf="newActionInputParam.isNew">New Action</span>
                <span *ngIf="!newActionInputParam.isNew">Action {{newActionInputParam.actionNumber}}</span></span>
        </h1>
        <div *ngIf="!newActionInputParam.isNew">
            <button kendoButton class="border-button ml-5 f-size-150" (click)="cancel()"> Cancel </button>
            <div *ngIf="isDraftavailable && !isInventoryLevelUpdate">
                <span kendoTooltip position="bottom" tooltipClass="tooltip-design-caret" class="bottom">
                    <button kendoButton title='To keep the items in "Draft" as Draft' class="primary-button ml-5 f-size-150"
                        (click)="saveDraft()"> Save Draft </button></span>
            </div>
            
            <span kendoTooltip position="bottom" tooltipClass="tooltip-design-caret" class="bottom">
                <button kendoButton title='Save the SAR item and Exit'
                    class="primary-button ml-5 f-size-150" (click)="saveAndExit()"> Save and Exit </button></span>
        </div>
    </div>
    <section>
        <div class="text-box">
            <div class="mt-10">
                <kendo-label text="Select Action Type"></kendo-label> <span style="color:red"> *</span>
                <select (change)="actionTypeChange(this.selectedActionType)" [disabled]='!newActionInputParam.isNew'
                    [(ngModel)]="selectedActionType" class="c-select">
                    <option value="0">Select Action Type</option>
                    <option value="1">Add New Item</option>
                    <option value="2">Change SAR</option>
                </select>
            </div>
            <div class="mt-10 ml-20" *ngIf="isChangeSAR">
                <!-- Drop down -->
                <kendo-label text="Select Change Type"></kendo-label> <span style="color:red"> *</span>
                <select [(ngModel)]="selectedChangeType" (change)="actionSubTypeChange(this.selectedChangeType)"
                    [disabled]='!newActionInputParam.isNew' class="c-select">
                    <option value="0">Select Change Data Type</option>
                    <option value="1">Change Data</option>
                    <option *ngIf="!isABU" value="2">Extension MRP Update</option>
                    <option *ngIf="isABU" value="3">Inventory Level Update</option>
                </select>
            </div>
            <div class="mt-10 ml-20" *ngIf="equipmentSelect && isABU">
                <kendo-label text="Select Equipment Tag">
                    <kendo-multiselect class="dropdown" 
                        [data]="data" 
                        [filterable]="true" 
                        placeholder="Type to get equipment..." 
                        (filterChange)="onFilterChange($event)"
                        [(ngModel)]="selectedEquipments"
                        (valueChange)="onValueChange($event)"
                        [value]="selectedEquipments">
                    </kendo-multiselect>  
                </kendo-label>
                <kendo-chip *ngFor="let item of selectedEquipments" [label]="item" (remove)="onRemove($event)">
                </kendo-chip>
            </div>
            <div class="mt-10 mx-5" *ngIf="isABU && isEquipmentShown"> 
                <kendo-label text="Equipment Criticality"></kendo-label><br>
                <kendo-label [text]="equipmentCriticality"></kendo-label><br>
            </div>
            <div class="mt-10 mx-5" *ngIf="isABU && isEquipmentShown">
                <kendo-label text="Item Part Ranking"></kendo-label><br>
                <kendo-label [text]="itemPartRanking"></kendo-label><br>
            </div>
        </div>
    </section>    
    <app-add-new-action *ngIf="selectedActionType==='1'" [requiredParams]="newActionInputParam"
        (tempActionRequestEvent)="addTempActionRequest($event)" (lineAssignmentEvent)="addLineAssignment($event)"
        (isDraftavailableEvent)="setDraftAvailability($event)" (cancelEvent)="cancelAction($event)"
        [isGoupSelected]="isGoupSelected"></app-add-new-action>
    <app-change-data *ngIf="selectedChangeType==='1'" [requiredParams]="newActionInputParam"
        (TempActionRequestEvent)="addTempActionRequest($event)" (lineAssignmentEvent)="addLineAssignment($event)"
        (isDraftavailableEvent)="setDraftAvailability($event)" (cancelEvent)="cancelAction($event)"
        [isGoupSelected]="isGoupSelected"></app-change-data>
    <app-extn-mrp-update *ngIf="selectedChangeType==='2'" [requiredParams]="newActionInputParam"
        (TempActionRequestEvent)="addTempActionRequest($event)" (lineAssignmentEvent)="addLineAssignment($event)"
        (isDraftavailableEvent)="setDraftAvailability($event)" (cancelEvent)="cancelAction($event)"
        [isGoupSelected]="isGoupSelected"></app-extn-mrp-update>
    <app-inventory-level-update *ngIf="selectedChangeType==='3' && selectedActionType==='2'" [requiredParams]="newActionInputParam"
        (TempActionRequestEvent)="addTempActionRequest($event)" (lineAssignmentEvent)="addLineAssignment($event)"
        (isDraftavailableEvent)="setDraftAvailability($event)" (cancelEvent)="cancelAction($event)"></app-inventory-level-update>
</div>