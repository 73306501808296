<div class="sar-contentBox add-request">
  <div class="content-top d-flex j-c-s">
    <h1>
      <a class="c-left" (click)="navViewSar()"><span class="fa fa-fw fa-angle-left"></span></a>
      <span class="in-active">
        Stock Authorization Request {{ requestNumber }}
      </span>
      <span class="active"><i class="fa fa-fw fa-angle-right"></i> Action {{ actionNumber }}</span>
    </h1>
  </div>

  <section class="d-flex j-c-s">
    <section class="left-timeline">
      <div class="d-flex j-c-s">
        <div>
          <p class="m-0">Action {{ actionNumber }}</p>
          <h6 class="font-size-base m-0">{{ action }}</h6>
        </div>
        <div>
          <p class="action-btn">
            <span class="tab"> {{ priorityName }} </span>
            <a class="action-edit" *ngIf="accessForEdit()" (click)="editSARDetails()">
              <i class="fa fa-fw fa-pencil fa-lg"></i>
            </a>
          </p>
        </div>
      </div>

      <div class="d-flex mt-10"
        [ngClass]="currentStatus === 'Completed' ? 'greenBTN' : currentStatus === 'Rejected' ? 'redBTN' : currentStatus === 'Cancelled' ? 'grayBTN' :'' ">
        <p class="font-size-xs m-0 border-black-button mr-10">
          {{currentStatus}}
        </p>
        <small>
          updated at {{modifiedDate | date: dateFormatWithTime}}
        </small>
      </div>
      <!-- info 3  justification-details-data-->
      <div class="info-3 mt-10">
        <div class="justification-details light-bg">
          <div class="head dark-bg">
            <p class="m-0">JUSTIFICATION DETAILS</p>
          </div>
          <div class="body">
            <p> {{ justificationDetails }} </p>
          </div>
        </div>
      </div>

      <div class="d-flex mt-10 j-c-s">
        <h6 class="font-size-base m-0">Item List</h6>
        <div>
          <button kendoButton class="upload-button" (click)="viewapproval('summary')">
            Summary
          </button>
          <button kendoButton class="upload-button ml-5" (click)="viewapproval('sa-action')"
            *ngIf="showSAActionForChangeData && isSAActionActive">
            SA Action
          </button>
          <button kendoButton class="upload-button ml-5 primary-btn" (click)="viewapproval('mmd-action')"
            *ngIf="isMMDActionActive">
            MMD Action
          </button>
        </div>
      </div>
      <section>

        <div class="tabs">
          <div class="tab mt-10 j-c-s" *ngFor="let item of itemsList; let i = index">
            <input type="checkbox" attr.id="chck{{i}}">
            <label class="tab-label" for="chck{{i}}">
              <p class="m-0">{{ item?.lineNumber }}
                <span *ngIf="item?.materialNumber">
                  (Matl. No. {{ item?.materialNumber }})
                </span>
              </p>
              <div class="d-flex a-center status-box" [ngClass]="item?.status === 'Completed' ? 'greenBTN' : 
              item?.status === 'Rejected' ? 'redBTN' :  item?.status === 'Cancelled' ? 'grayBTN' :'' ">
                <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                  {{ item?.status }}
                </p>
              </div>
            </label>

            <div class="tab-content">
              <div class="divider"></div>
              <div>
                <small>Assigned To</small>
                <p class="m-0 mb-10">
                  <b>{{ item?.assignedTo }}</b>
                </p>
              </div>
              <div>
                <small>Item Description</small>
                <p class="m-0 mb-10">
                  <b>{{ item?.description }}</b>
                </p>
              </div>
              <!-- change UI according to, "request-issue-type-ID" -->
              <div [ngSwitch]="item.requestIssueTypeId">
                <ng-container *ngSwitchCase="'1'">
                  <ng-container *ngTemplateOutlet="ID_1; context: { link: item }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'2'">
                  <ng-container *ngTemplateOutlet="ID_2; context: { link: item }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'3'">
                  <ng-container *ngTemplateOutlet="ID_3; context: { link: item }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'6'">
                  <ng-container *ngTemplateOutlet="ID_6; context: { link: item }"></ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
    <section class="main-timeline">
      <section class="timeline light-bg">
        <div class="d-flex mt-10 j-c-s">
          <h6 class="font-size-base m-0">Timeline</h6>
          <div>
            <button kendoButton class="upload-button" (click)="addNewEntry()" *ngIf="hideShowAddNewEntry()">
              Add New Entry
            </button>
          </div>
        </div>

        <div class="example-wrapper mt-10 mb-10">
          <kendo-multiselect [data]="listItems" id="multiSelect-dropdown" #multiselect
            placeholder="-- Please Select Line Item(s) --" (keyup)="searchLine($event)"
            (valueChange)="selectItemList($event)" (open)="onOpen($event)" (close)="onClose($event)" (click)="toggle()">
          </kendo-multiselect>
          <i class="fa fa-times close-icon dropdown-add-icon" *ngIf="!isLineSelected" aria-hidden="true"></i>
        </div>

        <!--timeline  -->
        <section class="d-flex j-c-s timeline-box" *ngFor="let timeline of timelineDetails; let i = index">
          <div class="d-flex a-center timeline-box-item">
            <div class="line"></div>
            <h2 class="user-info"> {{ timeline?.shortName }} </h2>
          </div>
          <div class="timeline-content">
            <div class="d-flex j-c-s a-center">
              <div class="timeline-left-content">
                <p class="m-0">
                  <b>{{ timeline.createdby }}<br>{{ timeline.groupName }}</b>
                </p>
                <small>
                  Added on {{timeline?.createdDate | date: dateFormatWithTime}}
                </small>
                <p class="m-0 mt-20" *ngIf="timeline?.isGeneralComment">
                  <b class="mt-10">
                    General Comment
                  </b>
                </p>

                <p class="m-0 mt-20" *ngIf="timeline?.lineItems.length">
                  <i>
                    <b class="mt-10">
                      Line Items: {{ timeline?.lineItemsCount }}
                    </b>
                  </i>
                </p>
                <small [ngClass]="timeline?.lineItems.length > 1 ? 'overflowItems' : ''">
                  {{ timeline?.lineItems | replaceComma }}
                </small>
              </div>

              <div class="timeline-right-content">
                <span>
                  <p class="m-0">
                    <b>Comment Added:</b>
                  </p>
                  <small> {{ timeline.commentsAdded }} </small>
                </span>
                <span *ngIf="timeline?.assignedTo">
                  <p class="m-0 mt-15">
                    <b>Assigned To:</b>
                  </p>
                  <small>{{ timeline.assignedTo }}</small>
                </span>

                <span *ngIf="timeline.statusSetTo">
                  <p class="m-0 mt-15">
                    <b>Status Set To: </b>
                  </p>
                  <small>{{ timeline.statusSetTo }}</small>
                </span>
                <!-- attachemenets -->
                <span class="attachemenets-row" *ngIf="timeline.attachments.length">
                  <p class="m-0 mt-15">
                    <b>Attachments: </b>
                    <small id="downloadBTN" (click)="downloadAll(i)">Download All</small>
                  </p>
                  <kendo-grid #myGrid [data]="timeline.attachments" [hideHeader]="true" class="list-container"
                    scrollable="none">
                    <kendo-grid-column>
                      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                        <span>{{rowIndex+1}}.</span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="400">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{dataItem.fileName}}</span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button kendoButton (click)="downloadFile(i,rowIndex)" look="flat" [icon]="'download'"
                          class="download-button"></button>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <button kendoButton (click)="removeFile(i,rowIndex)" look="flat" [icon]="'delete'"
                          class="delete-button"></button>
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </span>
              </div>
            </div>
          </div>
        </section>
      </section>
    </section>
  </section>
</div>

<ng-template #ID_1 let-link="link">
  <div>
    <div>
      <small>Total value</small>
      <p class="m-0 mb-10">
        <b> {{ link?.totalValue }} </b>
      </p>
    </div>
    <div>
      <small>Change Value </small>
      <p class="m-0 mb-10">
        <b> {{ link?.changeValue }} </b>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #ID_3 let-link="link">
  <div class="item-details">
    <div>
      <div>
        <small>Manufacturer: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.manufacturer }} </b>
        </p>
      </div>
      <div>
        <small>Unit of Measure: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.unitOfMeasure }} </b>
        </p>
      </div>
    </div>
    <div>
      <div>
        <small> Manufacturer Part Number: </small>
        <p class="m-0 mb-10">
          <b>{{ link?.manufacturerPartNumber }} </b>
        </p>
      </div>
      <div>
        <small>Material Group Code: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.materialGroupCode }} </b>
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ID_2 let-link="link">
  <div>
    <div>
      <small>Change Type</small>
      <p class="m-0 mb-10">
        <b> {{ link?.changeType }} </b>
      </p>
    </div>
    <div>
      <small>Old Data </small>
      <p class="m-0 mb-10">
        <b> {{ link?.oldData }} </b>
      </p>
    </div>
    <div>
      <small>New data </small>
      <p class="m-0 mb-10">
        <b>{{ link?.newData }}</b>
      </p>
    </div>
  </div>
</ng-template>

<ng-template #ID_6 let-link="link">
  <div class="item-details">
    <div>
      <div>
        <small>Branch Plant: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.inventoryLevelUpdateGridData !== undefined ? link?.inventoryLevelUpdateGridData[0].code : '' }}
          </b>
        </p>
      </div>
      <div>
        <small>Unit Price: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.unitPrice }} </b>
        </p>
      </div>
    </div>
    <div>
      <div>
        <small> Annual Usage Forecast: </small>
        <p class="m-0 mb-10">
          <b>{{ link?.annualUsageForecast }} </b>
        </p>
      </div>
      <div>
        <small>Change In Value: </small>
        <p class="m-0 mb-10">
          <b> {{ link?.changeInValue }} </b>
        </p>
      </div>
    </div>
  </div>
  <kendo-grid style="height:auto" scrollable="none" [data]="link?.inventoryLevelUpdateGridData">
    <kendo-grid-column field="code" title="Branch">
    </kendo-grid-column>
    <kendo-grid-column field="recommendedMinimum" title="Min"></kendo-grid-column>
    <kendo-grid-column field="recommendedMaximum" title="Max"></kendo-grid-column>
    <kendo-grid-column title="Est Value">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{(dataItem.recommendedMaximum ?? 0) * link?.unitPrice}}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</ng-template>