import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { InventoryLevelUpdateService } from '../../services/inventory-level-update.service';
import { InventoryLevelUpdateDto, InventoryLevelUpdateGridDataDto } from '../../models/change-sar/inventory-level-update.model';
import { AddNewActionItemsAttachmentDetails, AddNewActionItemsAttachmentDetailsDisplay } from '../../models/common/action-attachment.model';
import { DeleteLineItemDto } from '../../models/common/delete-line-item.model';
import { FileConstant } from 'src/app/core/constants/commonConst/file.const';
import { TempActionRequestDto } from '../../models/temp-actions/temp-action-request.module';
import { LineAssignmentDto } from '../../models/common/line-assignment.model';
import { UserDto } from 'src/app/features/setup/models/userandrole/user.model';
import { SarUsersService } from 'src/app/features/setup/services/sar-users.service';
import { Group } from 'src/app/features/setup/models/group/group.model';
import { CommonService } from 'src/app/core/services/common.service';
import { TempActionService } from '../../services/temp-action.service';
import { GroupService } from 'src/app/features/setup/services/group.service';
import { RoleConstant } from 'src/app/core/constants/role.constants';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { RequestLineService } from '../../services/request-line.service';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { MMDActionDto } from '../../models/approval-details/mmd-approval-caction.mode';
import { ApprovalDetailService } from '../../services/approvalDetail/approval-details.service';

@Component({
  selector: 'app-inventory-level-update',
  templateUrl: './inventory-level-update.component.html',
  styleUrls: ['./inventory-level-update.component.scss']
})
export class InventoryLevelUpdateComponent implements OnInit {
  @Input() public requiredParams: NewActionInputParam = {};
  isBoth: boolean = false;
  isDraftavailable: boolean = false;
  mainGridData: InventoryLevelUpdateDto[] = [];
  nestedGridData: InventoryLevelUpdateGridDataDto[] = this.mainGridData.length > 0 ? this.mainGridData[0].inventoryLevelUpdateGridData : [];
  @Output() isDraftavailableEvent = new EventEmitter<boolean>();
  attachmentDetails?: AddNewActionItemsAttachmentDetails[] = [];
  actionAttachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  quoteAttachmentDetailsDisplay: AddNewActionItemsAttachmentDetailsDisplay[] = [];
  @Output() cancelEvent = new EventEmitter<DeleteLineItemDto>();
  comment: string | undefined = '';
  businessUnitId: number = 0;
  requestIssueTypeId: number = 0;
  deleteLineItem: DeleteLineItemDto = new DeleteLineItemDto();
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  @ViewChild('fileInput') fileInput: any;
  tempActionRequest!: TempActionRequestDto;
  @Output() tempActionRequestEvent = new EventEmitter<TempActionRequestDto>();
  lineAssignment: LineAssignmentDto = new LineAssignmentDto();
  @Output() lineAssignmentEvent = new EventEmitter<LineAssignmentDto>();
  assignToGroups: Group[] = [];
  assignToUsers: UserDto[] = [];
  enabledAssignToUser: boolean = false;
  selectedUser = 0;
  isNew: boolean = true;
  inventoryLevelUpdateSarIssueTypeId: number = 6;
  changeSarIssueTypeId: number = 5;
  showGridSection = false;
  @Input() public isGroupSelected: boolean = true;;
  public defaultAssignToGroup: { name: string; id: number } = {
    id: 29,
    name: "ABU Stocking Approver"
  };
  public defaultAssignToUser: { name: string; id: number } = {
    id: 0,
    name: "-"
  };

  constructor(
    public router: Router,
    public requestLineService: RequestLineService,
    public approvalDetailService: ApprovalDetailService,
    private route: ActivatedRoute,
    private inventoryLevelUpdateService: InventoryLevelUpdateService,
    private sarUsersService: SarUsersService,
    private groupService: GroupService,
    private commonService: CommonService,
    private tempActionService: TempActionService) {
  }

  ngOnInit(): void {
    this.getInventoryLevelUpdateData();
    this.setLineAssignment();
    this.setTempActionRequest();
    this.setAssignmentGroups();
  }

  public import(): void {
  }

  navGenerateTemplate() {
    this.router.navigate(["../" + 'inventory-level-update-generate-excel'], {
      state: {
        isNew: true,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId,
        requestIssueTypeId: this.changeSarIssueTypeId,
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber,
        changeType: 'Inventory Level Update',
        assetName: this.requiredParams.assetName,
        lineItem: 0,
        requestLineId: 0
      },
      relativeTo: this.route
    });
  }

  getInventoryLevelUpdateData() {
    this.inventoryLevelUpdateService
      .getInventoryLevelUpdateData(this.requiredParams.requestId!)
      .subscribe((data) => {
        if ((data && data.length > 0 && !this.requiredParams.isNew && !this.requiredParams.isEdit) || 
            (data && data.length > 0 && data[0].totalValue == 0 && !this.requiredParams.isNew && !this.requiredParams.isEdit)) {
          this.showGridSection = true;
        }
        else {
          this.showGridSection = false;
        }
        
        this.mainGridData = data;
        this.nestedGridData = this.mainGridData.length > 0 ? this.mainGridData[0].inventoryLevelUpdateGridData : [];
      });
  }

  setAssignmentGroups() {
    const abuStockingApprover: number = 29;
    this.groupService.getGroupById(abuStockingApprover).subscribe((res: Group) => {
      this.assignToGroups.push(res);
    });
  }

  setTempActionRequest() {
    this.tempActionRequest = new TempActionRequestDto();
    this.tempActionRequest.requestId = this.requiredParams.requestId;
    this.tempActionRequest.requestIssueId = this.requiredParams.requestIssueId;
    this.tempActionRequest.requestIssueTypeId = this.requestIssueTypeId;
    this.tempActionRequest.assetName = this.requiredParams.assetName;
    this.tempActionRequest.attachments = [];
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequest.createdBy = this.commonService.getUserEmailId();
    this.tempActionRequest.modifiedBy = this.commonService.getUserEmailId();
  }

  getAssignmentUsers() {
    if (this.lineAssignment.assignedGroupId) {
      this.sarUsersService.getAssignedUsers(this.lineAssignment.assignedGroupId).subscribe((data: any) => {
        this.assignToUsers = data;
      })
    }
  }

  selectAssignToGroup(group: Group) {
    this.lineAssignment.assignedGroupId = group.id;
    this.lineAssignment.assignedUserId = 0;
    this.selectedUser = 0;
    this.lineAssignment.isMixedValues = true;
    this.getAssignmentUsers();
    this.enabledAssignToUser = group.id ? true : false;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  selectAssignToUser(user: UserDto) {
    this.lineAssignment.assignedUserId = user.id;
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  accessForEditAction(dataItem: InventoryLevelUpdateDto) {
    if (this.commonService.getUserRoleName() === RoleConstant.ESAR_Admin) {
      return true;
    }
    else if (this.commonService.getUserRoleName() === RoleConstant.ESAR_ReadOnly) {
      return false;
    }
    else {
      if (this.commonService.getUserEmailId() === dataItem.createdBy) {
        return true;
      }
      return false;
    }
  }

  editLineItem(dataItem: InventoryLevelUpdateDto, index: number) {
    this.router.navigate(["../" + 'inventory-level-update-add-new-item'], {
      state: {
        isNew: false,
        id: dataItem.id, 
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId, 
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber, 
        changeType: this.requiredParams.changeType, 
        lineItem: dataItem.lineNumber,
        lineItemStatus: dataItem.status,
        requestLineId: this.requiredParams.requestLineId, 
        materialNumber: dataItem.materialNumber, 
        status: dataItem.status,
        unitPrice: dataItem.unitPrice,
        itemDescription: dataItem.itemDescription,
        annualUsageForecast: dataItem.annualUsageForecast,
        stockCount: dataItem.stockCount,
        totalValue: dataItem.totalValue,
        changeInValue: dataItem.changeInValue,
        requesterNotes: dataItem.requesterNotes,
        inventoryLevelUpdateGridData: dataItem.inventoryLevelUpdateGridData,
        inventoryLevelUpdateStockId: dataItem.inventoryLevelUpdateStockId,
        assetName: this.requiredParams.assetName,
        isEditLineAction: true,
        buId: this.businessUnitId
      },
      relativeTo: this.route
    },
    );
  }

  rejectLineItem(dataItem: InventoryLevelUpdateDto, index: number) {
    this.commonService.showConfirmation('Delete', ApprovalDetailsConstants.deleteMessage)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let delItem = new DeleteLineItemDto();
          delItem.requestIssueId = this.requiredParams.requestIssueId!;
          delItem.requestLineIds = [];
          delItem.requestLineIds.push(dataItem.id!);
          this.deleteLineItem = delItem;
          this.requestLineService.DeleteLineItems(this.deleteLineItem).subscribe((response) => {
            this.getInventoryLevelUpdateData();
            this.commonService.showNotificationMessage(SharedConst.deleteLineItem, 'info', 'right');
          })
        }
      });
  }

  cancelLineItems(dataItem: InventoryLevelUpdateDto, index: number, comment: string) {
    this.commonService.showConfirmation('Cancel', ApprovalDetailsConstants.cancelledMessage, true)
      .subscribe((res: any) => {
        if (res.action === 'yes') {
          let mmd: MMDActionDto[] = [];
          mmd.push(this.getmmdAction(dataItem, index, comment))
          this.mmdBulkAction(mmd);
        }
      });
  }

  getmmdAction(dataItem: InventoryLevelUpdateDto, index: number, comment: string) {
    let mmdAction: MMDActionDto = new MMDActionDto();
    mmdAction.comment = comment;
    mmdAction.createdBy = this.commonService.getUserEmailId();
    mmdAction.modifiedBy = this.commonService.getUserEmailId();
    mmdAction.requestIssueId = dataItem.requestIssueId
    mmdAction.requestIssueTypeId = dataItem.requestIssueTypeId
    mmdAction.requestLineId = dataItem.id;
    mmdAction.MMDAction = 3;
    mmdAction.requestId = dataItem.requestId;
    mmdAction.isEditLineItem = false
    return mmdAction;
  }

  mmdBulkAction(mmdAction: MMDActionDto[]) {
    this.approvalDetailService.bulkmmdCancelAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage(SharedConst.cancelLineItem, 'success', 'right');
      this.getInventoryLevelUpdateData();
    });
  }

  downloadActionFile(index: number) {
    if (this.actionAttachmentDetailsDisplay && this.actionAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.actionAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }

  downloadQuoteFile(index: number) {
    if (this.quoteAttachmentDetailsDisplay && this.quoteAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.quoteAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.downLoadAttachment(attachment.blobUrI!).subscribe((response: Blob) => {
          this.commonService.downloadFile(response, attachment.fileName!);
        });
      }
      else {
        const blob = new Blob([attachment.file!], { type: attachment.file!.type });
        this.commonService.downloadFile(blob, attachment.fileName!);
      }
    }
  }

  removeActionFile(index: number) {
    if (this.actionAttachmentDetailsDisplay && this.actionAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.actionAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.actionAttachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.actionAttachmentDetailsDisplay!.splice(index, 1);
    }
  }

  removeQuoteFile(index: number) {
    if (this.quoteAttachmentDetailsDisplay && this.quoteAttachmentDetailsDisplay[index]) {
      let attachment: AddNewActionItemsAttachmentDetailsDisplay = this.quoteAttachmentDetailsDisplay[index];
      if (attachment.attachmentId !== 0) {
        this.tempActionService.deleteTempAction(this.requiredParams.requestIssueId!, attachment.attachmentId!).subscribe(Response => { });
      }
      else {
        let oldAttachments: AddNewActionItemsAttachmentDetailsDisplay[] = this.quoteAttachmentDetailsDisplay.filter((x: AddNewActionItemsAttachmentDetailsDisplay) => (x.attachmentId !== 0));
        let attachmentsIndex: number = index - oldAttachments.length;
        this.tempActionRequest.attachments?.splice(attachmentsIndex, 1);
        this.lineAssignment.attachments?.splice(attachmentsIndex, 1);
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      this.quoteAttachmentDetailsDisplay!.splice(index, 1);
    }
  }

  onChangeComment() {
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.assignedGroupId = this.defaultAssignToGroup.id;
    this.lineAssignment.isMixedValues = true;
    this.tempActionRequest.comment = this.comment;
    this.tempActionRequestEvent.emit(this.tempActionRequest);
    this.lineAssignmentEvent.emit(this.lineAssignment);
  }

  onActionAttachmentsFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.actionAttachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  onQuoteAttachmentsFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `Please select a valid file (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = this.setNewAttachment(files[0]);
        this.tempActionRequest.attachments!.push(files[0]);
        this.lineAssignment.attachments?.push(files[0]);
        this.quoteAttachmentDetailsDisplay.push(newAttachment);
        this.fileInput.nativeElement.value = '';
        this.tempActionRequestEvent.emit(this.tempActionRequest);
        this.lineAssignmentEvent.emit(this.lineAssignment);
      }
      else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  setNewAttachment(file: File): AddNewActionItemsAttachmentDetailsDisplay {
    const newAttachment: AddNewActionItemsAttachmentDetailsDisplay = new AddNewActionItemsAttachmentDetailsDisplay();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  setLineAssignment() {
    this.lineAssignment.requestId = this.requiredParams.requestId;
    this.lineAssignment.requestIssueId = this.requiredParams.requestIssueId;
    this.lineAssignment.requestIssueTypeId = this.inventoryLevelUpdateSarIssueTypeId;
    this.lineAssignment.businessUnitId = this.businessUnitId;
    this.lineAssignment.assignedGroupId = 0;
    this.lineAssignment.assignedUserId = 0;
    this.lineAssignment.state = 0;
    this.lineAssignment.isGeneralComment = false;
    this.lineAssignment.comment = this.comment;
    this.lineAssignment.createdBy = this.commonService.getUserEmailId();
    this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
    this.lineAssignment.attachments = [];
  }

  AddNewItem() {
    let isDataExit = false;
    this.router.navigate(["../" + 'inventory-level-update-add-new-item'], {
      state: {
        isNew: true,
        requestId: this.requiredParams.requestId,
        requestIssueId: this.requiredParams.requestIssueId,
        requestIssueTypeId: this.changeSarIssueTypeId,
        requestNumber: this.requiredParams.requestNumber,
        actionNumber: this.requiredParams.actionNumber,
        changeType: 'Inventory Level Update',
        assetName: this.requiredParams.assetName,
        lineItem: 0,
        requestLineId: 0,
        isDataExist: isDataExit
      },
      relativeTo: this.route
    },
    );
  }
}
