<section>
  <div class="mt-20 text-align-right">
    <button kendoButton class="upload-button ml-20" *ngIf="isDraftavailable" (click)="editAndDownload()"> Download & Edit Items </button>
    <button kendoButton class="upload-button ml-20" (click)="import()"> Upload Excel </button>
    <button kendoButton class="upload-button ml-20" (click)="navGenerateTemplate()"> Generate Excel </button>
    <button kendoButton class="upload-button ml-20" (click)="AddExtMRPNewItem()"> Add New Item </button>
    <button kendoButton class="upload-button ml-20" [disabled]="selectedItems.length===0" (click)="bulkCancel()">Bulk Action </button>
  </div>
</section>
<section *ngIf="requestExtMRPUpdateActionItemDetails">
  <h3>Item List</h3>
  <!-- Grid -->
  <kendo-grid id="extnGriddata" kendoGridSelectBy="requestLineId" [(selectedKeys)]="selectedItems" [selectable]="true"
    [data]="requestExtMRPUpdateActionItemDetails!.items" [rowClass]="rowCallback" scrollable="none" style="height:auto">
    <kendo-grid-checkbox-column [width]="50">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" id="checkbox-{{rowIndex}}"
          *ngIf=" !containsReject() && accessForEditAction(dataItem) && (dataItem.status ==='Rejected' || dataItem.status ==='Draft')"
          [disabled]="containsReject() && (dataItem.status!=='Rejected' || dataItem.status!=='Draft')"
          [kendoGridSelectionCheckbox]="rowIndex" />
      </ng-template>
      <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
        <input kendoGridSelectAllCheckbox type="checkbox" [disabled]="containsReject() && accessForEditAction(dataItem)"
          id="headerCheckbox" />
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="lineNumber" title="Line Number">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

        {{dataItem.lineNumber }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="materialItemNumber" title="Material Number"></kendo-grid-column>
    <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    <kendo-grid-column field="totalValue" title="Total Value(USD)"></kendo-grid-column>
    <kendo-grid-column field="changeInValue" title="ChangeIn Value(USD)"></kendo-grid-column>
    <kendo-grid-column title="Status" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngClass]="{'default-button status-btn':true,'draftbttn':dataItem.status === 'Draft',
                'rejectedbttn':dataItem.status === 'Rejected', 'completedbttn':dataItem.status === 'Completed',
                'cancelledbttn':dataItem.status === 'Cancelled'}"> {{dataItem.status}}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title=" " [width]="20">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngIf="(dataItem.status === 'Draft' || dataItem.status === 'Rejected') && accessForEditAction(dataItem) ">
          <div kendoPopoverAnchor [popover]="myPopover">
            <span class="k-icon k-i-more-vertical"></span>
          </div>
          <kendo-popover #myPopover position="left">
            <ng-template kendoPopoverBodyTemplate>
              <div class="popover-content">
                <a href="javascript:void();" (click)="editLineItem(dataItem,rowIndex)">
                  Edit Line
                  Item
                </a>
                <hr class="separator ml-5 mr-5" />
                <a href="javascript:void();" *ngIf="dataItem.status === 'Draft'"
                  (click)="rejectLineItem(dataItem,rowIndex)">Delete Line Item</a>
                <a href="javascript:void();" *ngIf="dataItem.status === 'Rejected'"
                  (click)="cancelLineItems(dataItem,rowIndex,'')">Cancel Line Item</a>
              </div>
            </ng-template>
          </kendo-popover>
        </div>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridDetailTemplate let-dataItem>
      <section *ngIf="dataItem.extMRPUpdateActionItemStorageLocations">
        <kendo-grid [data]="dataItem.extMRPUpdateActionItemStorageLocations" scrollable="none" #itemdetail
          style="height:auto">
          <kendo-grid-column title="Plant">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.plantName}}</span>
              <br>
              <span *ngIf="dataItem.isPlantForDeletion" class="mandatory">Flagged for deletion</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="unitPrice" title="Unit price"></kendo-grid-column>
          <kendo-grid-column field="specialProcurementKey" title="Special Procurement Key"></kendo-grid-column>
          <kendo-grid-column field="storageLocatinName" title="Storage Location">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.storageLocatinName}}</span>
              <br>
              <span *ngIf="dataItem.isStorageLocationForDeletion" class="mandatory">
                Flagged for
                deletion
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Recommended ROP (Min)">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.minimum}}
              <br />
              Existing ROP (Min)
              <br />
              {{dataItem.existingMinimum?dataItem.existingMinimum:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Recommended  Max">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.maximum}}
              <br />
              Existing Max
              <br />
              {{dataItem.existingMaximum?dataItem.existingMaximum:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Estimated Stock Holding Value (USD)">
            <ng-template kendoGridCellTemplate let-dataItem>
              ${{dataItem.estimatedInventoryValue?dataItem.estimatedInventoryValue:0}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="bin" title="Bin"></kendo-grid-column>
          <kendo-grid-column field="valuationType" title="Valuation Type">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem.valuationType}}</span>
              <br>
              <span *ngIf="dataItem.isValuationTypeForDeletion" class="mandatory">
                Flagged for
                deletion
              </span>
            </ng-template>
          </kendo-grid-column>

        </kendo-grid>
      </section>
    </ng-template>
  </kendo-grid>
</section>
<div *ngIf="requestExtMRPUpdateActionItemDetails && requestExtMRPUpdateActionItemDetails!.items.length !== 0"
  class="separator  mb-20"></div>
<div *ngIf="isDraftavailable">
  <fieldset *ngIf="requestExtMRPUpdateActionItemDetails && requestExtMRPUpdateActionItemDetails!.items.length !== 0"
    kendoTooltip tooltipClass="tooltip-design-caret"
    title="Resolution details and attachment is only applicable for the items under “Draft” status"
    class="w-75 mb-20 fieldset">
    <legend>Attachments </legend>

    <kendo-label text="Action Attachments"></kendo-label><br>
    <div class="fileuploadContainer">
      <p class="text">Drop Files or Click to Upload</p>
      <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)" accept="accptedFilesStr" />
    </div>
    <kendo-grid *ngIf="attachmentDetailsDisplay && attachmentDetailsDisplay.length" #myGrid
      [data]="attachmentDetailsDisplay" [hideHeader]="true" class="list-container" scrollable="none"
      [style.width.px]="500">
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
            class="download-button"></button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="400">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.fileName}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <button kendoButton (click)="removeFile(rowIndex)" look="flat" [icon]="'delete'"
            class="delete-button"></button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </fieldset>
  <fieldset *ngIf="requestExtMRPUpdateActionItemDetails && requestExtMRPUpdateActionItemDetails!.items.length !== 0"
    class="w-75 fieldset">
    <legend>Assignment Details </legend>
    <div>
      <kendo-label text="Comment"></kendo-label><br>
      <kendo-textarea (change)="onChangeComment()" [(ngModel)]="comment"></kendo-textarea>
    </div>
    <!--  section 6 -->
    <section *ngIf="!isBoth" class="d-flex">
      <div class="mt-10 mx-5 w-50">
        <kendo-label text="Assign To Group"><span style="color:red"> *</span></kendo-label><br>
        <kendo-dropdownlist class="mt-5" [data]="assignToGroups" textField="name" valueField="id"
          (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignToGroup" [valuePrimitive]="true">
        </kendo-dropdownlist>
        <span *ngIf="!isGoupSelected" class="noValue">
          Required field!
          Please select group
        </span>
        <br />
      </div>
      <div class="mt-10 mx-5 w-50">
        <kendo-label text="Assign To User"></kendo-label><br>
        <kendo-dropdownlist [(ngModel)]="selectedUser" [disabled]="!enabledAssignToUser" class="mt-5"
          [data]="assignToUsers" [defaultItem]="defaultAssignToUser" textField="name" valueField="id"
          (selectionChange)="selectAssignToUser($event)" [valuePrimitive]="true">
        </kendo-dropdownlist>
      </div>
    </section>
    <section *ngIf="isBoth">
      <div class="note">
        <b> Note: <br /></b>
        As there are actionable items on both Stocking approver and MMD,the items would be assigned to respective groups
        based on their Min/Max values.
      </div>
      <kendo-buttongroup selection="single">
        <button class="c-button-gorup" kendoButton [toggleable]="true" (click)="tab('psa')" title="Align-left"
          [selected]="true">Stocking
          Approver</button>
        <button class="c-button-gorup" kendoButton [toggleable]="true" (click)="tab('mmd')"
          title="Align-center">MMD</button>
      </kendo-buttongroup>
      <fieldset class="mt-10">
        <legend>Line Numbers</legend>
        <ul class="line-numbers" *ngFor="let line of currentLineMumbers">
          <li>Line {{line}},</li>
        </ul>
      </fieldset>
    </section>
  </fieldset>
</div>
