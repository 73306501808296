import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { ExtensionMRPUpdateConst } from 'src/app/core/constants/extensionMRPupdate.const';
import { PlantDetailsDto } from '../models/common/plant-detail.model';
import { RequestExtMRPUpdateActionItemDetailsDto } from '../models/change-sar/extension-MRP-update-Item-list.model';
import { ExistingROPMinMaxDto, ExtensionMRPUpdate, ExtensionMRPUpdateDto } from '../models/change-sar/extension-MRP-update-item.model';
import { ExtnMRPActionDetailsDto } from '../models/change-sar/extn-MRP-storage-location-details.model';

@Injectable({
    providedIn: 'root'
})
export class ExtensionMRPUpdateService {

    constructor(public httpUtilityService: HttpUtilityService) {
    }

    addNewItem(data: ExtensionMRPUpdateDto) {
        return this.httpUtilityService.post(ExtensionMRPUpdateConst.requestLineAPI + ExtensionMRPUpdateConst.addNewItemAPIpath, data);
    }

    updateExtenionMRPItem(data: ExtensionMRPUpdateDto) {
        return this.httpUtilityService.put(ExtensionMRPUpdateConst.requestLineAPI + ExtensionMRPUpdateConst.updateNewItemAPIpath, data);
    }

    getPlantDetail(BUname: string) {
        return this.httpUtilityService.getByAll<PlantDetailsDto[]>(
            ExtensionMRPUpdateConst.requestAPI + ExtensionMRPUpdateConst.PlantDetails + '/' + BUname)
    }

    getMaterialDescription(materialNumber: number) {
        return this.httpUtilityService.get<string>(
            ExtensionMRPUpdateConst.matInfoApi + ExtensionMRPUpdateConst.getMaterialDescription + '/' + materialNumber)
    }

    getMRPUpdateDetail(requestId: number, requestIssueId: number) {
        return this.httpUtilityService.getByAll<RequestExtMRPUpdateActionItemDetailsDto>(
            ExtensionMRPUpdateConst.requestIssueAPI + ExtensionMRPUpdateConst.getExtnMRPUpdateActionItemDetailAPI
            + '/' + requestId + '&' + requestIssueId)
    }
    getMRPUpdateEditDetail(requestLineId: number) {
        return this.httpUtilityService.getByAll<ExtnMRPActionDetailsDto>(
            ExtensionMRPUpdateConst.requestLineAPI + ExtensionMRPUpdateConst.getExtnMRPUpdateActionItemDetailEditAPI
            + '/' + requestLineId)
    }
    importExtensionMRPUpdateAction(actionItems: ExtensionMRPUpdate[]) {
        return this.httpUtilityService.post(ExtensionMRPUpdateConst.requestLineAPI + ExtensionMRPUpdateConst.importExtensionMRPUpdateAction, actionItems);
    }

    gGetPlantUnitPrice(plantCode: string, materialNumber: number) {
        return this.httpUtilityService.get<string>(
            ExtensionMRPUpdateConst.matInfoApi + ExtensionMRPUpdateConst.getPlantUnitPrice + '/' + plantCode + '/' + materialNumber)
    }

    getExistingROPMinAndMaxValue(plantCode: string, storageLocationCode: string, materialNumber: number) {
        return this.httpUtilityService.get<ExistingROPMinMaxDto>(
            ExtensionMRPUpdateConst.matInfoApi + ExtensionMRPUpdateConst.getExistingROPMinAndMaxValue + '/' + plantCode + '/' + storageLocationCode + '/' + materialNumber)
    }

    getPlantDeactivationDetails(buName: string, materialNumber: number) {
        return this.httpUtilityService.get<any>(
            ExtensionMRPUpdateConst.requestAPI + ExtensionMRPUpdateConst.getPlantDeactivationDetails + '/' + buName + '/' + materialNumber)
    }

    getStorageLocationDeactivationDetails(buName: string, plantCode: string, materialNumber: number) {
        return this.httpUtilityService.get<any>(
            ExtensionMRPUpdateConst.requestAPI + ExtensionMRPUpdateConst.getStorageLocationDeactivationDetails + '/' + buName + '/' + materialNumber + '/' + plantCode)
    }

    updateExtenionMRPItemAssignment(data: ExtensionMRPUpdateDto) {
        return this.httpUtilityService.put(ExtensionMRPUpdateConst.requestLineAPI + ExtensionMRPUpdateConst.updateNewItemAPIpath, data);
    }

}
