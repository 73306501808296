import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApprovalDetailsConstants } from 'src/app/core/constants/approval-details.constants';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { GetItemList, GetTimelines } from '../../models/approval-details/get-approval-details.model';
import { MMDActionDto } from '../../models/approval-details/mmd-approval-caction.mode';
import { StockApproverActionDto } from '../../models/approval-details/stock-approver-action.model';
import { InventoryLevelUpdateDto } from '../../models/change-sar/inventory-level-update.model';

@Injectable({
  providedIn: 'root'
})
export class ApprovalDetailService {
  constructor(private httpUtilityService: HttpUtilityService) {
  }

  //   to get all the time-lines for respective requested SAR
  getTimelines(requestIssueId: number): Observable<GetTimelines> {
    return this.httpUtilityService.get(ApprovalDetailsConstants.getTimelines + `${requestIssueId}`);
  }

  //   to get all the time-lines for respective requested SAR
  getItemList(requestId: number, requestIssueId: number): Observable<GetItemList[]> {
    return this.httpUtilityService.get(ApprovalDetailsConstants.getItemList + `${requestId}&${requestIssueId}`);
  }

  getItemListForInventory(requestId: number, requestIssueId: number): Observable<InventoryLevelUpdateDto[]> {
    return this.httpUtilityService.get(ApprovalDetailsConstants.getItemList + `${requestId}&${requestIssueId}`);
  }

  // to get SAR details based on ID 
  getSARDetailsById(requestId: number) {
    return this.httpUtilityService.get(ApprovalDetailsConstants.sarDetails + `${requestId}`);
  }

  // to get all the groups 
  getAssignedBUNames(businessUnit: any) {
    return this.httpUtilityService.get(ApprovalDetailsConstants.assignedGroup + `${businessUnit}`);
  }

  // to get all the users under specific group 
  getAssignedUsersList(requestId: number) {
    return this.httpUtilityService.get(ApprovalDetailsConstants.assignedUser + `${requestId}`);
  }

  addNewEntry(data: any) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.addNewEntry, data);
  }

  deleteTimelineAttachement(requestIssueId: number, attachmentId: number): Observable<any> {
    return this.httpUtilityService.delete(ApprovalDetailsConstants.deleteTimelineAttachment + `${requestIssueId}&${attachmentId}`);
  }

  downloadAllAttachements(filename: string, data: any) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.downloadAllAttachements + `?zipFileName=${filename}`, data, { responseType: 'arraybuffer' });
  }

  mmdAction(data: MMDActionDto) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.mmdApproverAPI + ApprovalDetailsConstants.approveOrReject, data);
  }

  bulkmmdAction(data: MMDActionDto[]) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.mmdApproverAPI + ApprovalDetailsConstants.bulkApprove, data);
  }

  bulkmmdCancelAction(data: MMDActionDto[]) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.mmdApproverAPI + ApprovalDetailsConstants.bulkCancel, data);
  }

  saAction(data: StockApproverActionDto) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.stockApproverAPI + ApprovalDetailsConstants.approveOrReject, data);
  }

  bulksaAction(data: StockApproverActionDto[]) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.stockApproverAPI + ApprovalDetailsConstants.bulkApprove, data);
  }
  getUnitPriceList(matList: any[], buName: string) {
    return this.httpUtilityService.post(ApprovalDetailsConstants.GetUnitPriceDetails + '/' + buName, matList);
  }
}