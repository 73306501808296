import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AddNewItemConstant } from 'src/app/core/constants/add-new-item.constant';
import { requestIssueConst } from 'src/app/core/constants/request-Issue.const';
import { SharedConst } from 'src/app/core/constants/shared.constant';
import { CommonService } from 'src/app/core/services/common.service';
import { MaterialGroupCode } from 'src/app/features/setup/models/materialgroupcode/materialgroupcode.model';
import { MaterialStatus } from 'src/app/features/setup/models/materialstatus/materialstatus.model';
import { MaterialType } from 'src/app/features/setup/models/materialtype/materialtype.model';
import { QACodeDto } from 'src/app/features/setup/models/qacode/qacode.model';
import { SegmentDto } from 'src/app/features/setup/models/segment/segment.model';
import { SPKDto } from 'src/app/features/setup/models/spk/spk.model';
import { StorageConditionDto } from 'src/app/features/setup/models/storagecondition/storagecondition.model';
import { UnitOfMeasure } from 'src/app/features/setup/models/unitofmeasure/unitofmeasure.model';
import { MaterialGroupCodeService } from 'src/app/features/setup/services/material-group-code.service';
import { MaterialStatusService } from 'src/app/features/setup/services/material-status.service';
import { MaterialTypeService } from 'src/app/features/setup/services/material-type.service';
import { QACodeService } from 'src/app/features/setup/services/qacode.service';
import { SegmentService } from 'src/app/features/setup/services/segment.service';
import { SPKService } from 'src/app/features/setup/services/spk.service';
import { StorageCondtionService } from 'src/app/features/setup/services/storagecondition.service';
import { UnitOfMeasureService } from 'src/app/features/setup/services/unit-of-measure.service';
import { AddNewItem } from '../../models/add-new-item/add-new-item.model';
import { GetAddNewItem } from '../../models/add-new-item/get-add-new-item-action.model';
import { RequestLineQACode } from '../../models/add-new-item/request-line-qa-code.model';
import { StorageLocationDto } from '../../models/add-new-item/storage-location.model';
import { NewActionInputParam } from '../../models/common/new-action-input-param.model';
import { PlantDetailsDto } from '../../models/common/plant-detail.model';
import { AddNewItemService } from '../../services/add-new-item.service';
import { ExtensionMRPUpdateService } from '../../services/extensionMRPupdate.service';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { AddNewItemOperationConst } from 'src/app/core/constants/commonConst/add-new-item-operation.const';
import { SupplierService } from 'src/app/features/setup/services/supplier.service';
import { SupplierDto } from 'src/app/features/setup/models/supplier/supplier.model';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { LineAssignmentDto } from '../../models/common/line-assignment.model';
import { ActionsLayout, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ReAssignItemComponent } from '../approval-management/re-assign-item/re-assign-item.component';
import { RequestLineService } from '../../services/request-line.service';
import { EquipmentDto } from '../../models/common/equipment.model';
import { RecommendedStockingLocationDto, ItemStockingLocationDto } from 'src/app/features/setup/models/stocking-location.model.ts/stocking-location.model';

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.scss']
})
export class AddNewItemComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  lineItem: any;
  materialNumber: any;
  requestLineId: any;
  selectedSupplierName: string | undefined;
  selectedSupplierId: number | undefined;
  selectedSupplier?: SupplierDto;
  supplierBinding: string | undefined;
  isDataExist: boolean = false;
  itemStatus: string = '';
  lineAssignment?: LineAssignmentDto = new LineAssignmentDto();
  buId: number = 0;
  locationChecked: boolean = false;
  locationDialogShow: boolean = false;
  locationDialogActionsLayout: ActionsLayout = "end"
  showLocationSection: boolean = false;
  stockingLocationDataErrors: ItemStockingLocationDto[] = [];
  recommendedStockingLocations: RecommendedStockingLocationDto[] = [];
  mmdAbuGroup: { name: string; id: number } = { id: 28, name: 'MMD - ABU' };
  abuStockingApprover: { name: string; id: number } = { id: 29, name: 'ABU Stocking Approver' };
  public defaultAssignToGroup: { name: string; id: number };

  constructor(private materialTypeService: MaterialTypeService,
    private unitOfMeasureService: UnitOfMeasureService,
    private materialGroupCodeService: MaterialGroupCodeService,
    private segmentService: SegmentService,
    private materialStatusService: MaterialStatusService,
    private storageConditionService: StorageCondtionService,
    private qaCodeService: QACodeService,
    private commonService: CommonService,
    private extensionMRPUpdateService: ExtensionMRPUpdateService,
    private spkService: SPKService,
    private supplierService: SupplierService,
    private addNewItemService: AddNewItemService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
    private dialogService: DialogService,
    private requestLineService: RequestLineService
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;

    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.newActionInputParam.equipmentCriticality = stateData['equipmentCriticality'] ?? '';
      this.newActionInputParam.itemPartRanking = stateData['itemPartRanking'] ?? '';
      this.newActionInputParam.equipmentTags = stateData['equipmentTags'] ?? [];
      this.newActionInputParam.assetName = stateData['assetName'] ?? [];
      this.lineItem = stateData['lineItem'];
      this.isDataExist = stateData['isDataExist'];
      this.requestLineId = stateData['requestLineId'];
      this.materialNumber = stateData['materialNumber'];
      this.itemStatus = stateData['itemStatus'];
      this.buId = stateData['buId'];
      this.addNewItemForm.get('equipmentCriticality')!.setValue(this.newActionInputParam.equipmentCriticality);
      this.addNewItemForm.get('itemPartRanking')!.setValue(this.newActionInputParam.itemPartRanking);
      this.addNewItemForm.get('equipmentTags')!.setValue(this.newActionInputParam.equipmentTags?.map(item => item).join(", "));
      sessionStorage.setItem('addNewItem', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('addNewItem')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.newActionInputParam.equipmentCriticality = sessionState.equipmentCriticality ?? '';
        this.newActionInputParam.itemPartRanking = sessionState.itemPartRanking ?? '';
        this.newActionInputParam.equipmentTags = sessionState.equipmentTags;
        this.newActionInputParam.assetName = sessionState.assetName;
        this.lineItem = sessionState.lineItem;
        this.isDataExist = sessionState.isDataExist;
        this.requestLineId = sessionState.requestLineId;
        this.materialNumber = sessionState.materialNumber;
        this.itemStatus = sessionState.itemStatus;
        this.buId = sessionState.buId;
      }
    }   

    if (this.isABU)
      this.requestLineService.getRecommendedStockingLocations(stateData['requestId']).subscribe(data => {
        this.recommendedStockingLocations = data;
      });
  }

  @ViewChild("supplierAutocomplete", { static: false })
  public supplierAutocomplete!: AutoCompleteComponent;
  @ViewChild("multiselect", { static: true }) public multiselect: any;
  public isOpen: boolean = false;
  public editItem: AddNewItem | undefined;
  public newActionInputParam: NewActionInputParam = {};
  public virtual: any = { itemHeight: 50 };
  public value = 0;
  public descMaxlength = 2500;
  public descCharachtersCount = 0;
  public reqNotesMaxLength = 2500;
  public reqNotesCharachtersCount = 0;
  public materialTypeData: MaterialType[] = [];
  public qaCodeData: QACodeDto[] = [];
  public qaCode: QACodeDto[] = [];
  public locationGridData: ItemStockingLocationDto[] = [];
  public existingQACodes: RequestLineQACode[] = [];
  public existingQACodeIds: number[] = [];
  public deselectedQACodeIds: number[] = [];
  public selectedQACodeIds: number[] = [];
  public existingStorageLocationIds: number[] = [];
  public deletedStorageLocationIds: number[] = [];
  public uomData: UnitOfMeasure[] = [];
  public materialGroupCodeData: MaterialGroupCode[] = [];
  public segmentData: SegmentDto[] = [];
  public materialStatusData: MaterialStatus[] = [];
  public storageConditionData: StorageConditionDto[] = [];
  public hasDescriptionError: boolean = false;
  public hasMaterialTypeError: boolean = false;
  public hasUOMError: boolean = false;
  public hasChemAlertNumberError: boolean = false;
  public hasMaterialGroupCodeError: boolean = false;
  public hasSegmentError: boolean = false;
  public hasMaterialStatusError: boolean = false;
  public hasStorageConditionError: boolean = false;
  public hasMRPError: boolean = false;
  public hasUnitPriceError: boolean = false;
  public hasLeadTimeError: boolean = false;
  public hasError: boolean = false;
  addNewItemForm = new FormGroup({
    description: new FormControl(<string | undefined>(''), Validators.required),
    manufacturerName: new FormControl(<string | undefined>('')),
    manufacturerPartNumber: new FormControl(<string | undefined>('')),
    materialType: new FormControl(<number | undefined>(undefined), Validators.required),
    unitOfMeasure: new FormControl(<number | undefined>(undefined), Validators.required),
    chemalertStockNumber: new FormControl(<number | undefined | null>(0)),
    estimatedAnnualUsage: new FormControl(<number | undefined | null>(0)),
    quantityPerTask: new FormControl(<number | undefined | null>(0)),
    materialGroupCode: new FormControl(<number | undefined>(undefined)),
    segment: new FormControl(<number | undefined>(undefined)),
    grossWeight: new FormControl(<number | undefined>(0), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)),
    dimensions: new FormControl(<string | undefined>('')),
    materialStatus: new FormControl(<number | undefined>(undefined)),
    storageCondition: new FormControl(<number | undefined>(undefined)),
    isHazardous: new FormControl(<boolean | undefined>(false)),
    isCritical: new FormControl(<boolean | undefined>(false)),
    isCAARequired: new FormControl(<boolean | undefined>(false)),
    isRepairable: new FormControl(<boolean | undefined>(false)),
    isModelUpdateRequired: new FormControl(<boolean | undefined>(false)),
    isEPURequired: new FormControl(<boolean | undefined>(false)),
    isEPLUpdateRequired: new FormControl(<boolean | undefined>(false)),
    isHydrocarbonValve: new FormControl(<boolean | undefined>(false)),
    isPPEquipment: new FormControl(<boolean | undefined>(false)),
    isMRp: new FormControl(<boolean | undefined>(undefined)),
    unitPrice: new FormControl(<number | undefined>(0), [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]),
    leadTime: new FormControl(<number | undefined>(0), [Validators.required, Validators.pattern(/^-?([1-9]\d*)?$/)]),
    qaCode: new FormControl(<QACodeDto[] | undefined>(undefined)),
    supplierPartNumber: new FormControl(<string | undefined>('')),
    requestorNotes: new FormControl(<string | undefined>('')),
    equipmentCriticality: new FormControl(<string | undefined>('')),
    itemPartRanking: new FormControl(<string | undefined>('')),
    equipmentTags: new FormControl(<string | undefined>('')),
    stockingLocations: new FormControl(<RecommendedStockingLocationDto[] | undefined>([])),
  });
  public requestItems: StorageLocationDto[] = [];
  public deletedStorageLocations: StorageLocationDto[] = [];
  public finalStorageLocations: StorageLocationDto[] = [];
  public tempArray: any = [];
  public gridData = [''];
  public plantList: PlantDetailsDto[] = [];
  public SPKList: SPKDto[] = [];
  public equipments: EquipmentDto[] = [];
  public supplierList: SupplierDto[] = [];
  public supplierData: SupplierDto[] = [];
  public selectedMaterialTypeId: number | undefined;
  public selectedUnitOfMeasureId: number | undefined;
  public selectedMaterialGroupCodeId: number | undefined;
  public selectedSegmentId: number | undefined;
  public selectedMaterialStatusId: number | undefined;
  public selectedStorageConditionId: number | undefined;
  public selectedQACodes: RequestLineQACode[] = [];
  public deletedQACodes: RequestLineQACode[] = [];
  public finalQACodes: RequestLineQACode[] = [];
  public isGOMBU: boolean = false;
  public isMCBU: boolean = false;
  public isRBU: boolean = false;
  public isSJVBU: boolean = false;
  public isABU: boolean = false;
  public defaultPlantItem: { displayName: string; plantName: string; plantDiscription: string } = {
    displayName: "Select Plant",
    plantName: "",
    plantDiscription: ""
  };
  public defaultSPKItem: { name: string; id: number } = {
    id: 0,
    name: "Select SPK"
  };
  public defaultSLItem: { storageLocation: string; storageLocationDiscription: string, displayName: string } = {
    storageLocation: '',
    storageLocationDiscription: '',
    displayName: "Select Storage Location"
  };

  public get isChemalertRequired(): boolean | undefined | null {
    return this.addNewItemForm.get('isCAARequired')?.value;
  }

  ngOnInit(): void {
    this.isGOMBU = this.commonService.getUserBUName() === 'GOMBU';
    this.isMCBU = this.commonService.getUserBUName() === 'MCBU';
    this.isRBU = this.commonService.getUserBUName() === 'RBU';
    this.isSJVBU = this.commonService.getUserBUName() === 'SJVBU';
    this.isABU = this.commonService.getUserBUName() === 'ABU';
    this.disableFields();
    this.getDropdownFields();
    if (this.isABU) {
      this.addControlsChangeEvents();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  disableFields() {
    this.addNewItemForm.get('storageCondition')?.disable();
    this.addNewItemForm.get('materialStatus')?.disable();
    this.addNewItemForm.get('qaCode')?.disable();
    this.addNewItemForm.get('isCritical')?.disable();
    this.addNewItemForm.get('supplierPartNumber')?.disable();

    if (!this.isABU) {
      this.addNewItemForm.get('segment')?.disable();
      this.addNewItemForm.get('isHazardous')?.disable();
      this.addNewItemForm.controls['isMRp'].setValidators([Validators.required]);
      this.addNewItemForm.controls['materialGroupCode'].setValidators([Validators.required]);
    } else {
      this.defaultAssignToGroup = this.mmdAbuGroup;
      this.addNewItemForm.get('supplierPartNumber')?.enable();
      this.addNewItemForm.controls['isCAARequired'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isRepairable'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isModelUpdateRequired'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isEPURequired'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isEPLUpdateRequired'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isHydrocarbonValve'].setValidators([Validators.required]);
      this.addNewItemForm.controls['isPPEquipment'].setValidators([Validators.required]);
    }

    if (this.isGOMBU) {
      this.addNewItemForm.get('segment')?.enable();
      this.addNewItemForm.get('storageCondition')?.enable();
      this.addNewItemForm.get('materialStatus')?.enable();
      this.addNewItemForm.get('qaCode')?.enable();
      this.addNewItemForm.get('isHazardous')?.enable();
      this.addNewItemForm.controls['segment'].setValidators([Validators.required]);
    }
    if (this.isMCBU || this.isRBU || this.isSJVBU) {
      this.addNewItemForm.get('isCritical')?.enable();
      this.addNewItemForm.get('supplierPartNumber')?.enable();
    }
  }

  private addControlsChangeEvents(): void {
    this.addNewItemForm.get('isCAARequired')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.addNewItemForm.controls['chemalertStockNumber'].setValidators([Validators.required]);
        }
        else {
          this.addNewItemForm.controls['chemalertStockNumber'].reset();
          this.addNewItemForm.controls['chemalertStockNumber'].clearValidators();
          this.addNewItemForm.controls['chemalertStockNumber'].updateValueAndValidity();
        }
      });
  }

  addStorageLocation() {
    if (this.newActionInputParam.isNew === true) {
      this.addNewRow();
    }
    else if (this.newActionInputParam.isNew === false) {
      this.getAddNewItemAction()
    }
  }

  getAddNewItemAction() {
    this.addNewItemService.getAddNewItemAction(this.requestLineId).subscribe((response: GetAddNewItem) => {
      this.setFormValues(response);
      this.addNewRow(false, response.storageLocations);
      if (response.stockingLocations?.length)
        this.fillStockingLocations(response.stockingLocations);
    });
  }

  setFormValues(response: GetAddNewItem) {
    if (response && response.qaCodes && response.qaCodes.length > 0) {
      const qaCodes = response.qaCodes;
      this.qaCode = this.qaCodeData.filter((elem) => {
        return qaCodes.some((ele) => {
          return elem.id === ele.qaCodeId;
        });
      });
    }

    this.existingQACodes = <RequestLineQACode[]>response.qaCodes;
    this.qaCode.forEach(item => this.existingQACodeIds.push(item.id));
    response.storageLocations?.forEach(item => this.existingStorageLocationIds.push(<number>item.id));

    this.qaCodeData.forEach(qaCode => {
      this.existingQACodeIds.includes(qaCode.id) ? qaCode.status = true : qaCode.status = false;
    })
    this.addNewItemForm.patchValue({
      description: response.description,
      manufacturerName: response.manufactureName,
      manufacturerPartNumber: response.manufacturePartNumber,
      materialType: response.materialTypeId,
      unitOfMeasure: response.unitOfMeasureId,
      chemalertStockNumber: response.chemalertStockNumber,
      estimatedAnnualUsage: response.estimatedAnnualUsage,
      quantityPerTask: response.quantityPerTask,
      materialGroupCode: response.materialGroupCodeId,
      segment: response.segmentId,
      grossWeight: response.grossWeight,
      dimensions: response.dimensions,
      materialStatus: response.materialStatusId,
      storageCondition: response.storageConditionId,
      isHazardous: response.isHazardous,
      isCritical: response.isCritical,
      isCAARequired: response.isCAARequired,
      isRepairable: response.isRepairable,
      isModelUpdateRequired: response.isModelUpdateRequired,
      isEPURequired: response.isEPURequired,
      isEPLUpdateRequired: response.isEPLUpdateRequired,
      isHydrocarbonValve: response.isHydrocarbonValve,
      isPPEquipment: response.isPPEquipment,
      isMRp: response.isMRp,
      unitPrice: response.unitPrice,
      leadTime: response.leadTime,
      qaCode: this.qaCode,
      supplierPartNumber: response.supplierPartNumber,
      requestorNotes: response.requestorNotes,
      equipmentCriticality: this.newActionInputParam?.equipmentCriticality ?? '',
      itemPartRanking: this.newActionInputParam?.itemPartRanking ?? '',
      equipmentTags: this.newActionInputParam?.equipmentTags?.map(item => item).join(", ") ?? '',
      stockingLocations: response.stockingLocations?.map(s => ({ code: s.code, description: s.description } as RecommendedStockingLocationDto)),
    });

    this.descCharachtersCount = <number>response.description?.length || 0;
    this.reqNotesCharachtersCount = <number>response.requestorNotes?.length || 0;
    this.selectedUnitOfMeasureId = response.unitOfMeasureId;
    this.selectedMaterialTypeId = response.materialTypeId;
    this.selectedMaterialGroupCodeId = response.materialGroupCodeId;
    this.selectedMaterialStatusId = response.materialStatusId;
    this.selectedStorageConditionId = response.storageConditionId;
    this.selectedSupplierId = response.recommendedSupplierId;
    this.setSupplier(response.supplierPartName)
    this.selectedSegmentId = response.segmentId;
    response.qaCodes?.map(el => {
      el.createdBy = this.commonService.getUserEmailId(),
        el.modifiedBy = this.commonService.getUserEmailId()
    })

    this.selectedQACodes = <RequestLineQACode[]>response.qaCodes;
  }

  setSupplier(partName?: string) {
    if (partName) {
      this.selectedSupplier = this.supplierList.filter(x => x.supplierName === partName)[0];
      this.selectedSupplierId = this.selectedSupplier.id;
      this.selectedSupplierName = this.selectedSupplier.supplierName;
      this.supplierBinding = this.selectedSupplier.displayName;
      return;
    }
    this.selectedSupplierId = undefined;
    this.selectedSupplierName = undefined;
    this.supplierBinding = undefined;
  }

  getStorageLocationsByPlant(plantName: string) {
    let index = this.plantList.findIndex((x: PlantDetailsDto) => x.plantName === plantName);
    return this.plantList[index].storageLocationDetails
  }

  public descValueChange(ev: string): void {
    this.descCharachtersCount = ev.length;
  }

  public reqNotesValueChange(ev: string): void {
    this.reqNotesCharachtersCount = ev.length;
  }

  getDropdownFields() {
    const materialType = this.materialTypeService.getMaterialType();
    const qACodes = this.qaCodeService.getQAAll();
    const uom = this.unitOfMeasureService.getUnitOfMeasure();
    const materialGroupCode = this.materialGroupCodeService.getMaterialGroupCode();
    const segment = this.segmentService.getsegments();
    const materialStatus = this.materialStatusService.getMaterialStatus();
    const storageCondition = this.storageConditionService.getStorageConditionAll();
    const BUName = this.commonService.getUserBUName();
    const plantList = this.extensionMRPUpdateService.getPlantDetail(BUName);
    const spkList = this.spkService.getSPKAll();
    const supplier = this.supplierService.getAllSupplier(this.isABU);
    const stockingLocations = this.requestLineService.getRecommendedStockingLocations(this.newActionInputParam.requestId!);

    let requests = [materialType, qACodes, uom, materialStatus, storageCondition, plantList, spkList, supplier] as Observable<any[]>[];

    if (!this.isABU) {
      requests.push(materialGroupCode);
      requests.push(segment);
    }
    else {
      requests.push(stockingLocations);
    }

    combineLatest(requests).subscribe(results => {
      const isNew = this.newActionInputParam.isNew;
      this.materialTypeData = this.addDisplayName(results[0]);
      this.setDefaultValueFromList("materialType", results[0][0].id);
      this.selectedMaterialTypeId = results[0][0].id;
      this.qaCodeData = Object.assign([], results[1]);
      this.qaCodeData.forEach(el => {
        el.displayName = `${el.code} : ${el.name}`
        el.operation = isNew ? AddNewItemOperationConst.create : AddNewItemOperationConst.update
      })
      this.uomData = this.addDisplayName(results[2].filter(x => x.isActive === true));
      this.materialStatusData = this.addDisplayName(results[3].filter(x => x.isActive === true));
      this.storageConditionData = this.addDisplayName(results[4].filter(x => x.isActive === true));
      this.plantList = Object.assign([], results[5]);
      this.plantList.forEach(el => el.displayName = `${el.plantName} : ${el.plantDiscription}`)
      this.SPKList = Object.assign([], results[6].filter(x => x.isActive === true));
      this.supplierList = Object.assign([], results[7].filter(i => !!i.supplierName));

      if (!this.isABU) {
        this.materialGroupCodeData = this.addDisplayName(results[8].filter(x => x.isActive === true));
        this.segmentData = Object.assign([], results[9].filter(x => x.isActive === true));
      }
      else {
        this.recommendedStockingLocations = results[8];
      }

      this.supplierList.forEach(el => el.displayName = `${el.supplierNumber} : ${el.supplierName}`);
      this.supplierData = this.supplierList;
      this.addStorageLocation()
    });

  }

  setDefaultValueFromList(propName: string, value: unknown): void {
    this.addNewItemForm.get(propName)?.setValue(value);
    this.addNewItemForm.get(propName)?.markAsTouched();
  }

  addDisplayName(data: MaterialType[] | QACodeDto[] | UnitOfMeasure[] | MaterialStatus[] | MaterialGroupCode[] | StorageConditionDto[]): any {
    if (data.length > 0) {
      data.forEach(el => el.displayName = `${el.code} : ${el.name}`)
      return data;
    }
    return [];

  }

  filter(value: any) {
    if (value.length > 0) {
      this.supplierData = this.supplierList.filter(x => x.displayName?.toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
    else {
      this.supplierAutocomplete.toggle(false);
    }
  }

  focus() {
    if (this.isMCBU || this.isRBU || this.isSJVBU || this.isABU) {
      this.supplierAutocomplete.toggle(true);
    }
  }

  blur() {
    this.supplierAutocomplete.toggle(false);
  }

  supplierChange(val: any) {
    if (val.length > 0) {
      this.selectedSupplier = this.supplierList.filter(x => x.displayName?.toLocaleLowerCase().includes(val.toLowerCase()))[0];
      this.selectedSupplierId = this.selectedSupplier.id;
      this.selectedSupplierName = this.selectedSupplier.supplierName;
    }
    else {
      this.selectedSupplierId = undefined;
      this.selectedSupplierName = undefined;
    }
  }

  plantChange(value: string | undefined, rowIndex: number) {
    let index = this.plantList.findIndex((x: PlantDetailsDto) => x.plantName === value);
    if (index > -1) {
      this.plantList[index].storageLocationDetails.forEach(el => el.displayName = `${el.storageLocation} : ${el.storageLocationDiscription}`)
      this.tempArray[rowIndex] = this.plantList[index].storageLocationDetails;
      this.requestItems[rowIndex].plantName = this.plantList[index].plantName;
      this.requestItems[rowIndex].plantDescription = this.plantList[index].plantDiscription;
      this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocation;
    }
    else {
      this.tempArray[rowIndex] = this.defaultSLItem;
      this.requestItems[rowIndex].plantName = this.defaultPlantItem.plantName;
      this.requestItems[rowIndex].plantDescription = this.defaultPlantItem.plantDiscription;
      this.requestItems[rowIndex].storageLocatinName = this.defaultSLItem.storageLocation;
      this.requestItems[rowIndex].storageLocationDescription = this.defaultSLItem.storageLocationDiscription;
      this.requestItems[rowIndex].minimum = 0;
      this.requestItems[rowIndex].maximum = 0;
      this.requestItems[rowIndex].roundingValue = 0;
      this.requestItems[rowIndex].estimatedInventoryValue = 0;
      this.requestItems[rowIndex].isDuplicateError = false;
      this.requestItems[rowIndex].isMinMaxError = false;
      this.requestItems[rowIndex].isPlantEmptyError = false;
      this.isSJVBU ? this.requestItems[rowIndex].spkId = undefined : '';
    }
  }

  storageLocationChange(value: any, index: number) {
    this.requestItems[index].storageLocatinName = value;
  }

  selectMaterialType(val: MaterialType) {
    this.selectedMaterialTypeId = val.id;
  }

  selectUOM(val: UnitOfMeasure) {
    this.selectedUnitOfMeasureId = val.id;
  }

  selectMaterialGroupCode(val: MaterialGroupCode) {
    this.selectedMaterialGroupCodeId = val.id;
  }

  selectSegment(val: SegmentDto) {
    this.selectedSegmentId = val.id;
  }

  selectMaterialStatus(val: MaterialStatus) {
    this.selectedMaterialStatusId = val.id;
  }

  selectStorageCondition(val: StorageConditionDto) {
    this.selectedStorageConditionId = val.id;
  }

  selectQACode(val: QACodeDto[]) {
    this.selectedQACodes = [];
    this.qaCode = val;

    val.forEach(el => {
      this.selectedQACodeIds.push(el.id);
      let item = new RequestLineQACode();
      item.id = this.newActionInputParam.isNew ? 0 : this.getExistingQACodeId(el.id) //this.existingQACodeIds.includes(el.id) ? el.id : 0;
      item.qaCodeId = el.id;
      item.requestLineId = this.newActionInputParam.isNew ? 0 : this.requestLineId;
      item.operation = this.newActionInputParam.isNew ? AddNewItemOperationConst.create : this.existingQACodeIds.includes(el.id) ? AddNewItemOperationConst.update : AddNewItemOperationConst.create;
      item.createdBy = this.commonService.getUserEmailId();
      item.modifiedBy = this.commonService.getUserEmailId();
      this.selectedQACodes.push(item);
    })

    this.qaCodeData.forEach((element: any) => {
      if (val.findIndex((x: any) => x.id === element.id) !== -1) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }

  getExistingQACodeId(qaCodeId: number): number {
    this.existingQACodeIds.includes(qaCodeId);
    const qaCode = this.existingQACodes.filter(item => {
      if (item.qaCodeId === qaCodeId) {
        return item.id
      }
      return 0;
    });
    return qaCode.length > 0 ? <number>qaCode[0].id : 0;
  }

  removeQACode(val: any) {
    if (!this.deselectedQACodeIds.includes(val.dataItem.id) && this.existingQACodeIds.includes(val.dataItem.id)) {
      this.deselectedQACodeIds.push(val.dataItem.id);
      let item = new RequestLineQACode();
      item.id = this.getExistingQACodeId(val.dataItem.id);
      item.qaCodeId = val.dataItem.id;
      item.requestLineId = this.requestLineId;
      item.operation = AddNewItemOperationConst.delete;
      item.createdBy = this.commonService.getUserEmailId();
      item.modifiedBy = this.commonService.getUserEmailId();
      this.deletedQACodes.push(item);
    }
  }

  getFinalQACodes() {
    this.finalQACodes = [];
    this.finalQACodes = [...this.selectedQACodes];
    this.deletedQACodes.forEach(code => {
      if (this.existingQACodeIds.includes(<number>code.qaCodeId)) this.finalQACodes.push(code);
    })
  }

  addNewRow(isNew?: boolean, storageLocations?: StorageLocationDto[]) {
    if (isNew === false) {

      this.tempArray.push({});
      if (storageLocations && storageLocations.length > 0) {
        storageLocations.map((currentValue, index) => {

          this.requestItems.push({
            minimum: currentValue?.minimum || 0,
            maximum: currentValue?.maximum || 0,
            roundingValue: currentValue?.roundingValue || 0,
            id: this.existingStorageLocationIds.includes(<number>currentValue.id) ? currentValue?.id : 0,
            estimatedInventoryValue: currentValue?.estimatedInventoryValue,
            requestLineId: this.requestLineId,
            plantName: currentValue.plantName,
            plantDescription: currentValue.plantDescription,
            spkId: currentValue.spkId,
            operation: this.existingStorageLocationIds.includes(<number>currentValue.id) ? AddNewItemOperationConst.update : AddNewItemOperationConst.create,
            createdBy: this.commonService.getUserEmailId(),
            modifiedBy: this.commonService.getUserEmailId(),
            isPlantEmptyError: false,
            isDuplicateError: false,
            isMinMaxError: false,
          });

          let plantIndex = this.plantList.findIndex((x: PlantDetailsDto) => x.plantName === currentValue.plantName);
          if (index > -1) {
            this.plantList[plantIndex].storageLocationDetails.forEach(el => el.displayName = `${el.storageLocation} : ${el.storageLocationDiscription}`)
            this.tempArray[index] = this.plantList[plantIndex].storageLocationDetails;
            this.requestItems[index].storageLocatinName = this.defaultSLItem.storageLocation;
            this.requestItems[index].storageLocatinName = currentValue.storageLocatinName;
            this.requestItems[index].storageLocationDescription = currentValue.storageLocationDescription;
          }
        })
        return;
      }
      else {
        this.addSLo();
        return;
      }
    }
    this.addSLo()
  }

  addSLo() {
    this.tempArray.push({});
    this.requestItems.push({
      createdBy: this.commonService.getUserEmailId(),
      minimum: 0,
      maximum: 0,
      roundingValue: 0,
      id: 0,
      spkId: undefined,
      estimatedInventoryValue: 0,
      requestLineId: this.newActionInputParam.isNew ? 0 : this.requestLineId,
      operation: AddNewItemOperationConst.create,
      isPlantEmptyError: false,
      isDuplicateError: false,
      isMinMaxError: false,
    });

  }

  removeRow(index: number) {
    const splicedObject = this.requestItems.splice(index, 1)
    if (this.existingStorageLocationIds.includes(<number>splicedObject[0].id) && !this.deselectedQACodeIds.includes(<number>splicedObject[0].id)) {
      splicedObject[0].operation = AddNewItemOperationConst.delete;
      this.deletedStorageLocations.push(splicedObject[0])
      this.deletedStorageLocationIds.push(<number>splicedObject[0].id)
    }
    if (this.requestItems.length == 0) {
      this.addNewRow()
    }
  }

  getFinalStorageLocations() {
    this.finalStorageLocations = [];
    this.requestItems = this.requestItems.filter(item => item.plantName != undefined)
    this.finalStorageLocations = [...this.requestItems];
    this.deletedStorageLocations.forEach(location => {
      location.operation = AddNewItemOperationConst.delete;
      if (this.existingStorageLocationIds.includes(<number>location.id)) this.finalStorageLocations.push(location);
    })
  }

  calculateEstimatedValue(max: any, roundingValue: any, item: StorageLocationDto) {
    const unitPrice = <number>this.addNewItemForm.get('unitPrice')?.value;
    if (max == undefined || roundingValue == undefined || unitPrice == undefined) {
      item.estimatedInventoryValue = 0;
    }
    else {
      item.estimatedInventoryValue = (max + roundingValue) * unitPrice
    }
  }

  updateEstimatedValue() {
    const unitPrice = <number>this.addNewItemForm.get('unitPrice')?.value;
    if (unitPrice > 0) {
      this.requestItems.forEach(item => {
        if (item.maximum == undefined || item.roundingValue == undefined || unitPrice == undefined) {
          item.estimatedInventoryValue = 0;
        }
        item.estimatedInventoryValue = (item.maximum! + item.roundingValue!) * unitPrice
      })
    }
  }

  updateValue(value: any) {
    if (value == null || value == undefined || value < 0) {
      return 0
    }
    return value;
  }

  SPKChange(value: any, index: number) {
    this.requestItems[index].spkId = value;
  }

  add() {
    this.clearError();
    this.getFinalQACodes();
    this.getFinalStorageLocations()

    const item = new AddNewItem();
    item.id = this.newActionInputParam.isNew ? 0 : this.requestLineId;
    item.requestId = this.newActionInputParam.requestId;
    item.requestIssueTypeId = 3;
    item.requestIssueId = this.commonService.isNullOrEmptyOrUndefined(this.newActionInputParam.requestIssueId) ? 0 : this.newActionInputParam.requestIssueId,
      item.lineNumber = 0;
    item.manufacturerPartNumber = <string>this.addNewItemForm.get('manufacturerPartNumber')?.value;
    item.manufacturer = <string>this.addNewItemForm.get('manufacturerName')?.value;
    item.chemalertStockNumber = <number>this.addNewItemForm.get('chemalertStockNumber')?.value;
    item.estimatedAnnualUsage = <number>this.addNewItemForm.get('estimatedAnnualUsage')?.value;
    item.quantityPerTask = <number>this.addNewItemForm.get('quantityPerTask')?.value;
    item.description = <string>this.addNewItemForm.get('description')?.value;
    item.unitOfMeasureId = this.selectedUnitOfMeasureId;
    item.materialTypeId = this.selectedMaterialTypeId;
    item.materialGroupCodeId = this.selectedMaterialGroupCodeId;
    item.materialStatusId = this.selectedMaterialStatusId;
    item.sizeOrDimensions = <string>this.addNewItemForm.get('dimensions')?.value;
    item.grossWeight = <number>this.addNewItemForm.get('grossWeight')?.value;
    item.storageConditionId = this.selectedStorageConditionId;
    item.isHazardous = <boolean | undefined>this.addNewItemForm.get('isHazardous')?.value;
    item.isCritical = <boolean | undefined>this.addNewItemForm.get('isCritical')?.value;
    item.isCAARequired = <boolean | undefined>this.addNewItemForm.get('isCAARequired')?.value;
    item.isRepairable = <boolean | undefined>this.addNewItemForm.get('isRepairable')?.value;
    item.isModelUpdateRequired = <boolean | undefined>this.addNewItemForm.get('isModelUpdateRequired')?.value;
    item.isEPURequired = <boolean | undefined>this.addNewItemForm.get('isEPURequired')?.value;
    item.isEPLUpdateRequired = <boolean | undefined>this.addNewItemForm.get('isEPLUpdateRequired')?.value;
    item.isHydrocarbonValve = <boolean | undefined>this.addNewItemForm.get('isHydrocarbonValve')?.value;
    item.isPPEquipment = <boolean | undefined>this.addNewItemForm.get('isPPEquipment')?.value;
    item.isMRp = <boolean | undefined>this.addNewItemForm.get('isMRp')?.value;
    item.unitPrice = <number>this.addNewItemForm.get('unitPrice')?.value;
    item.leadTime = <number>this.addNewItemForm.get('leadTime')?.value;
    item.supplierPurchasingId = this.selectedSupplierId;
    item.supplierPurchasing = this.selectedSupplierName;
    item.supplierPartNumber = <string>this.addNewItemForm.get('supplierPartNumber')?.value;
    item.requestorNotes = <string>this.addNewItemForm.get('requestorNotes')?.value;
    item.segmentId = this.selectedSegmentId;
    item.createdBy = this.commonService.getUserEmailId();
    item.modifiedBy = this.commonService.getUserEmailId();
    item.addNewItemActionQACodes = this.finalQACodes;
    item.addNewActionItemStorageLocation = this.finalStorageLocations;
    item.equipmentCriticality = <string>this.addNewItemForm.get('equipmentCriticality')?.value;
    item.itemPartRanking = <string>this.addNewItemForm.get('itemPartRanking')?.value;
    item.equipmentTags = this.newActionInputParam.equipmentTags
    item.stockingLocations = this.locationGridData;

    if (!this.isFormValid()) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
      if (this.requestItems.length === 0) {
        this.addSLo();
      }
    }
    else if (!this.isStorageLocationValid()) {
      this.hasError = true;
    }
    else {
      if (this.newActionInputParam.isNew == true) {
        this.addNewItemService.createAddNewActionItem(item).subscribe((response: any) => {
          this.commonService.showNotificationMessage(AddNewItemConstant.successMessage, 'success', 'right')
          this.router.navigate(['./' + requestIssueConst.newAction], {
            state: {
              isNew: false,
              requestId: response.requestId,
              requestIssueId: response.requestIssueId,
              requestNumber: this.newActionInputParam.requestNumber,
              actionNumber: this.newActionInputParam.actionNumber,
              changeType: 'Add New Item',
              equipmentCriticality: this.newActionInputParam.equipmentCriticality,
              itemPartRanking: this.newActionInputParam.itemPartRanking,
              equipmentTags: this.newActionInputParam.equipmentTags,
              defaultAssignToGroup: this.defaultAssignToGroup
            }, relativeTo: this.route.parent
          });
        });
      }
      else {
        if (this.itemStatus === 'Rejected') {
          this.openreAssignment(item);
        }
        else {
          this.update(item);
        }
      }
    }
  }

  update(item: any) {
    this.addNewItemService.updateAddNewActionItem(item).subscribe((response: any) => {
      if (this.itemStatus === 'Rejected') {
        this.saveAndAssign();
        return false;
      }
      this.commonService.showNotificationMessage(AddNewItemConstant.updateSuccessMessage, 'success', 'right')
      this.router.navigate(['./' + requestIssueConst.newAction], {
        state: {
          isNew: false,
          requestId: response.requestId,
          requestIssueId: response.requestIssueId,
          requestNumber: this.newActionInputParam.requestNumber,
          actionNumber: this.newActionInputParam.actionNumber,
          changeType: 'Add New Item',
          defaultAssignToGroup: this.defaultAssignToGroup
        }, relativeTo: this.route.parent
      });
    });
  }

  saveAndAssign() {
    if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!) && !this.lineAssignment?.isMixedValues) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'center');
    }
    else {
      let userId: any = this.lineAssignment!.assignedUserId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedUserId!.toString())) {
        userId = null;
      }
      let groupId: any = this.lineAssignment!.assignedGroupId!;
      if (this.commonService.isNullOrEmptyOrUndefined(this.lineAssignment!.assignedGroupId!)) {
        groupId = null;
      }
      const formData = new FormData();
      formData.append('requestId', this.lineAssignment!.requestId!.toString());
      formData.append('requestIssueId', this.lineAssignment!.requestIssueId!.toString());
      formData.append('requestIssueTypeId', this.lineAssignment!.requestIssueTypeId!.toString());
      formData.append('businessUnitId', this.lineAssignment!.businessUnitId!.toString());
      formData.append('assignedGroupId', groupId);
      formData.append('assignedUserId', userId);
      formData.append('state', this.lineAssignment!.state!.toString());
      formData.append('comment', this.lineAssignment!.comment!);
      formData.append('isGeneralComment', this.lineAssignment!.isGeneralComment!.toString());
      formData.append('createdBy', this.lineAssignment!.createdBy!);
      formData.append('modifiedBy', this.lineAssignment!.modifiedBy!);
      for (let attachment of this.lineAssignment!.attachments!) {
        formData.append('attachments', attachment);
      }
      for (let id of this.lineAssignment!.requestLineIds!) {
        formData.append('requestLineIds', id.toString());
      }
      this.requestLineService.updateLineAssignmentForRejected(formData).subscribe(response => {
        this.commonService.showNotificationMessage(AddNewItemConstant.updateSuccessMessage, 'success', 'right')
        this.router.navigate(['./' + 'newaction'], {
          state: {
            isNew: false, requestId: this.lineAssignment!.requestId!,
            requestIssueId: this.lineAssignment!.requestIssueId!, changeType: 'Add New Item',
            requestNumber: this.newActionInputParam.requestNumber,
            actionNumber: this.newActionInputParam.actionNumber
          }, relativeTo: this.route.parent
        });
      })
    }
  }
  openreAssignment(item: any) {
    let grouptype = 1
    if (this.requestItems!.reduce((sum, current) => sum + current.maximum!, 0) > 0) {
      grouptype = 2
    }

    const dialog: DialogRef = this.dialogService.open({
      content: ReAssignItemComponent
    });
    const inc = dialog.content.instance as ReAssignItemComponent;
    inc.requestIssueId = this.newActionInputParam.requestIssueId!;
    inc.lineItemId = this.requestLineId!;
    inc.groupType = grouptype;

    inc.listItems = []
    dialog.result.subscribe((r: any) => {
      if (this.commonService.isNullOrEmpty(r.actionType) || r.actionType === 'Close') {
        return false;
      }
      this.lineAssignment = r.lineItemDtail as LineAssignmentDto;
      this.lineAssignment.requestId = this.newActionInputParam.requestId;
      this.lineAssignment.requestIssueId = this.newActionInputParam.requestIssueId;
      this.lineAssignment.requestIssueTypeId = 3;
      this.lineAssignment.businessUnitId = this.buId;
      this.lineAssignment.state = 0;
      this.lineAssignment.isGeneralComment = false;
      this.lineAssignment.comment = '';
      this.lineAssignment.createdBy = this.commonService.getUserEmailId();
      this.lineAssignment.modifiedBy = this.commonService.getUserEmailId();
      this.lineAssignment.attachments = [];
      this.lineAssignment.requestLineIds = []
      this.lineAssignment.requestLineIds.push(this.requestLineId!)
      this.update(item);
    });
  }

  clearError() {
    this.hasDescriptionError = false;
    this.hasMaterialTypeError = false;
    this.hasUOMError = false;
    this.hasMaterialGroupCodeError = false;
    this.hasSegmentError = false;
    this.hasMaterialStatusError = false;
    this.hasStorageConditionError = false;
    this.hasMRPError = false;
    this.hasUnitPriceError = false;
    this.hasLeadTimeError = false;
    this.hasError = false;
    this.hasChemAlertNumberError = false;
    this.requestItems.forEach(element => {
      element.isDuplicateError = false;
      element.isMinMaxError = false;
      element.isPlantEmptyError = false;
    })
  }

  isFormValid(): boolean {
    if (this.addNewItemForm.get('description')?.invalid) {
      this.addNewItemForm.get('description')?.markAsTouched();
      this.hasDescriptionError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('materialType')?.invalid) {
      this.addNewItemForm.get('materialType')?.markAsTouched();
      this.hasMaterialTypeError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('unitOfMeasure')?.invalid) {
      this.addNewItemForm.get('unitOfMeasure')?.markAsTouched();
      this.hasUOMError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('materialGroupCode')?.invalid) {
      this.addNewItemForm.get('materialGroupCode')?.markAsTouched();
      this.hasMaterialGroupCodeError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('segment')?.invalid && !this.isABU) {
      this.addNewItemForm.get('segment')?.markAsTouched();
      this.hasSegmentError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('isMRp')?.invalid) {
      this.addNewItemForm.get('isMRp')?.markAsTouched();
      this.hasMRPError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('unitPrice')?.invalid) {
      this.addNewItemForm.get('unitPrice')?.markAsTouched();
      this.hasUnitPriceError = true;
      this.hasError = true;
    }
    if (this.addNewItemForm.get('leadTime')?.invalid) {
      this.addNewItemForm.get('leadTime')?.markAsTouched();
      this.hasLeadTimeError = true;
      this.hasError = true;
    }
    if (<number>this.addNewItemForm.get('unitPrice')?.value < 1) {
      this.addNewItemForm.get('unitPrice')?.markAsTouched();
      this.hasUnitPriceError = true;
      this.hasError = true;
    }
    if (<number>this.addNewItemForm.get('leadTime')?.value < 1) {
      this.addNewItemForm.get('leadTime')?.markAsTouched();
      this.hasLeadTimeError = true;
      this.hasError = true;
    }
    if (this.isABU) {
      if (this.addNewItemForm.get('isCAARequired')?.value && !this.addNewItemForm.get('chemalertStockNumber')?.value) {
        this.addNewItemForm.get('chemalertStockNumber')?.markAsTouched();
        this.hasChemAlertNumberError = true;
        this.hasError = true;
      }
    }

    if (this.hasError || this.stockingLocationDataErrors.length) {
      return false;
    }
    return true;
  }

  isStorageLocationValid(): boolean {
    let i = 0;
    for (let obj of this.requestItems) {
      this.requestItems[i].createdBy = this.commonService.getUserEmailId();
      this.requestItems[i].modifiedBy = this.commonService.getUserEmailId();
      this.requestItems[i].requestLineId = this.requestLineId;
      if (obj.storageLocatinName === this.defaultSLItem.storageLocationDiscription) {
        obj.storageLocatinName = undefined;
      }
      if (!this.commonService.isNullOrEmptyOrUndefined(obj.minimum) || !this.commonService.isNullOrEmptyOrUndefined(obj.maximum) ||
        !this.commonService.isNullOrEmptyOrUndefined(obj.roundingValue) || !this.commonService.isNullOrEmptyOrUndefined(obj.spkId)) {
        if (this.commonService.isNullOrEmptyOrUndefined(obj.plantName)) {
          this.requestItems[i].isPlantEmptyError = true;
        }
      }
      if (!this.commonService.isNullOrEmptyOrUndefined(obj.plantName)) {
        if (this.commonService.isNullOrEmptyOrUndefinedOnly(obj.minimum) || this.commonService.isNullOrEmptyOrUndefinedOnly(obj.maximum) || !(obj.minimum! <= obj.maximum!)) {
          this.requestItems[i].isMinMaxError = true;
        }
      }
      var lengthOfDuplicateData = this.requestItems.filter((x: StorageLocationDto) => x.plantName === obj.plantName && x.storageLocatinName === obj.storageLocatinName).length;
      if (i != 0 && !this.commonService.isNullOrEmptyOrUndefined(obj.plantName) && !this.commonService.isNullOrEmptyOrUndefined(obj.storageLocatinName) && lengthOfDuplicateData > 1) {
        this.requestItems[i].isDuplicateError = true;
      }
      i++;
    }
    if (this.requestItems.findIndex(x => x.isDuplicateError === true || x.isMinMaxError === true || x.isPlantEmptyError === true) !== -1) {
      this.commonService.showNotificationMessage(SharedConst.validationMessage, 'error', 'right')
      return false;
    }
    this.requestItems = this.requestItems.filter(item => item.plantName != undefined)
    return true;
  }

  navBack() {
    if (this.isDataExist) {
      this.newActionInputParam.isNew = false;
    }
    this.router.navigate(['./' + requestIssueConst.newAction], {
      state: {
        isNew: this.newActionInputParam.isNew,
        requestId: this.newActionInputParam.requestId,
        requestIssueId: this.newActionInputParam.requestIssueId,
        requestNumber: this.newActionInputParam.requestNumber,
        actionNumber: this.newActionInputParam.actionNumber,
        changeType: 'Add New Item',
      }, relativeTo: this.route.parent
    });
  }

  onOpen(event: any) {
    let multiselect;
    multiselect = this.el.nativeElement.querySelector("#multiselect");
    this.renderer.addClass(multiselect, 'opened')
  }

  onClose(event: any) {
    let multiselect;
    multiselect = this.el.nativeElement.querySelector("#multiselect");
    this.isOpen = false;
    this.renderer.removeClass(multiselect, 'opened');

  }

  toggle() {
    this.isOpen = !this.isOpen;
    this.multiselect.toggle(this.isOpen);
    let multiselect;
    multiselect = this.el.nativeElement.querySelector("#multiselect");
    if (this.isOpen) {
      this.renderer.addClass(multiselect, 'opened')
    }
    else {
      this.renderer.removeClass(multiselect, 'opened');
    }
  }

  showLocationDialog(checked: boolean): void {
    this.locationDialogShow = checked;

    if (this.showLocationSection) {
      this.showLocationSection = false;
    }

    if (!checked) {
      this.locationGridData = [];
      this.defaultAssignToGroup = this.mmdAbuGroup;
    }
  }

  closeLocationDialog(): void {
    this.locationChecked = false;
    this.locationDialogShow = false;
  }

  proceedLocationInfo(): void {
    this.showLocationSection = true;
    this.locationDialogShow = false;
    this.defaultAssignToGroup = this.abuStockingApprover;
  }

  selectLocation(input: RecommendedStockingLocationDto[]): void {
    input.filter(i => !this.locationGridData.some(l => l.code === i.code)).map(m => (
      {
        code: m.code,
        description: m.description,
        min: 0,
        max: 0,
        createdBy: this.commonService.getUserEmailId(),
      } as ItemStockingLocationDto
    )).forEach(item => {
      this.locationGridData = this.locationGridData.filter(l => l.code !== item.code).concat(item);
    })
  }

  removeLocation(item: RecommendedStockingLocationDto): void {
    this.locationGridData = this.locationGridData.filter(l => l.code !== item.code);
  }

  calculateStockHolding(dataItem: ItemStockingLocationDto): number {
    const unitPrice = <number>this.addNewItemForm.get('unitPrice')?.value;
    if (!dataItem.max || !unitPrice)
      return 0;

    dataItem.stockHolding = unitPrice * (dataItem.max ?? 0);

    return unitPrice * (dataItem.max ?? 0);
  }

  isMinMaxError(dataItem: ItemStockingLocationDto): boolean {
    const isError = (dataItem.min ?? 0) >= (dataItem.max ?? 0);
    if (isError)
      this.stockingLocationDataErrors.push(dataItem);
    else {
      const index = this.stockingLocationDataErrors.indexOf(dataItem);
      this.stockingLocationDataErrors.splice(index, 1);
    }

    return isError;
  }

  private fillStockingLocations(locations: ItemStockingLocationDto[]): void {
    this.locationChecked = true;
    this.showLocationSection = true;
    this.locationGridData = locations;
  }
}
