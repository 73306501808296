<section>
    <div class="mt-20 text-align-right">
        <button kendoButton class="upload-button ml-20" (click)="downloadExcel()"> Download Excel </button>
        <button kendoButton class="primary-bulk-button ml-20" (click)="bulk()" [disabled]="!isBulk"> Bulk Action
        </button>
    </div>
</section>

<section>
    <h3>Item List</h3>
    <kendo-grid [data]="mainGridData" (selectionChange)="selectionChange($event)" #item [selectable]="true"
        kendoGridSelectBy [(selectedKeys)]="mySelection" style="height:auto">
        <kendo-grid-column [width]="30"></kendo-grid-column>
        <kendo-grid-checkbox-column [width]="50" [showSelectAll]="true">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input *ngIf="currentActionName !== 'summary' &&
                (dataItem.itemStatus !=='Cancelled' && dataItem.itemStatus !=='Rejected' && dataItem.itemStatus !=='Completed')"
                    type="checkbox" id="checkbox-{{rowIndex}}" [kendoGridSelectionCheckbox]="rowIndex" />
            </ng-template>
            <ng-template kendoGridHeaderTemplate let-dataItem let-rowIndex="rowIndex">
                <input kendoGridSelectAllCheckbox type="checkbox" id="headerCheckbox" />
            </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column title="Line No." field="lineNumber" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="IDE Item Number" field="materialNumber" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Unit Price" field="unitPrice" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Annual Usage Forecast" field="annualUsageForecast" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Total Value" field="totalValue" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Increase In $ Value" field="stockCount" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Requestor Notes" field="requesterNotes" [width]="100"></kendo-grid-column>
        <kendo-grid-column title="Recommended Stock Holding Locations" [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{getLocationsCodes(dataItem?.inventoryLevelUpdateGridData)}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Action/Status" [width]="250">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div
                    *ngIf="currentActionName !== 'summary' && (dataItem.itemStatus !=='Cancelled' && dataItem.itemStatus !=='Rejected' && dataItem.itemStatus !=='Completed')">
                    <button kendoButton class="border-button ml-5 f-size-150 cancelButton" (click)="consult(dataItem)">
                        Consult
                    </button>
                    <kendo-dialog title="Consult" *ngIf="consultClicked" (close)="close()" [height]="300" [width]="500">
                        <div class="consult-item-container">
                            <div>
                                <kendo-label text="Assign to group"><span style="color:red"> *</span></kendo-label>
                                <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedGroup" [data]="groups"
                                    [defaultItem]="groupSelectPlaceHolder" textField="name" valueField="id"
                                    (selectionChange)="groupSelectionChange($event)">
                                </kendo-dropdownlist>
                            </div>
                            <div>
                                <kendo-label text="Assign to sub group / user"></kendo-label>
                                <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedUserGroup" [data]="groupUsers"
                                    [defaultItem]="userGroupSelectPlaceHolder" textField="name" valueField="id"
                                    (selectionChange)="userGroupSelectionChange($event)"
                                    [disabled]="userGroupSelectDisabled">
                                </kendo-dropdownlist>
                            </div>
                        </div>
                        <br>
                        <br>
                        <kendo-label text="QA Codes"></kendo-label>
                        <kendo-dropdownlist class="dropdown" [(ngModel)]="selectedQaCode" [data]="qaCodes"
                            [defaultItem]="qaCodeSelectPlaceHolder" textField="name" valueField="id"
                            (selectionChange)="qaCodeSelectionChange($event)">
                        </kendo-dropdownlist>
                        <kendo-dialog-actions>
                            <button kendoButton (click)="closeDialog()">Cancel</button>
                            <button kendoButton (click)="consultActionClick()" class="primary-button"
                                [disabled]="consultBtnDisabled">Consult</button>
                        </kendo-dialog-actions>
                    </kendo-dialog>
                    <kendo-dialog class="error-dialog" title="Error" *ngIf="consultErrorDialog"
                        (close)="closeConsultError()" [minWidth]="250" [width]="100">
                        <p style="text-align: center;">
                            Please select the assigned user group. In case you want assign it only to the group, select
                            sub-group
                        </p>
                    </kendo-dialog>
                    <button kendoButton class="primary-button ml-5 f-size-150 rejectButton" (click)="reject(dataItem)">
                        Reject
                    </button>
                    <button kendoButton class="primary-button ml-5 f-size-150 successButton"
                        (click)="complete(dataItem)">
                        {{getSAButtonText()}}
                    </button>
                </div>
                <div
                    *ngIf="dataItem.itemStatus ==='Cancelled' || dataItem.itemStatus ==='Rejected' || dataItem.itemStatus ==='Completed' 
                            || (currentActionName === 'summary' && (dataItem.itemStatus ==='Pending Stocking Approval' || dataItem.itemStatus ==='Pending MMD'))">
                    <div *ngIf="dataItem.itemStatus==='Completed'" class="d-flex a-center status-box"
                        [ngClass]="dataItem?.itemStatus === 'Completed' ? 'greenBTN' : 
                            dataItem?.itemStatus === 'Rejected' ? 'redBTN' : dataItem?.itemStatus === 'Cancelled' ? 'grayBTN' : '' ">
                        <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                            {{ dataItem?.itemStatus }}
                        </p>
                    </div>
                    <div *ngIf="dataItem.itemStatus==='Cancelled'" class="d-flex a-center status-box"
                        [ngClass]="dataItem?.itemStatus === 'Completed' ? 'greenBTN' : 
                            dataItem?.itemStatus === 'Rejected' ? 'redBTN' : dataItem?.itemStatus === 'Cancelled' ? 'grayBTN' : '' ">
                        <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                            {{ dataItem?.itemStatus }}
                        </p>
                    </div>
                    <div *ngIf="dataItem.itemStatus==='Rejected'" class="d-flex a-center status-box"
                        [ngClass]="dataItem?.itemStatus === 'Completed' ? 'greenBTN' : 
                                dataItem?.itemStatus === 'Rejected' ? 'redBTN' : dataItem?.itemStatus === 'Cancelled' ? 'grayBTN' : '' ">
                        <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                            {{ dataItem?.itemStatus }}
                        </p>
                    </div>
                    <div *ngIf="dataItem.itemStatus !=='Cancelled' && dataItem.itemStatus !=='Rejected' && dataItem.itemStatus !=='Completed'"
                        class="d-flex a-center status-box">
                        <p class="font-size-xs m-0 border-black-button mr-5 w-160 text-center">
                            {{ dataItem?.itemStatus }}
                        </p>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridDetailTemplate let-dataItem>
            <section>
                <div class="d-i-flex j-c-s g-10">
                    <div>
                        <h4>Item Description</h4>
                        <kendo-textarea class="mt-5" [(ngModel)]="mainGridData[0].itemDescription" [rows]="9"
                            [cols]="50" [readonly]="true" [resizable]="'none'"></kendo-textarea>
                    </div>
                    <div>
                        <h4>Recommended Stock Holding</h4>
                        <kendo-grid style="height:auto; width:700px" scrollable="none" [data]="nestedGridData">
                            <kendo-grid-column field="code" title="Branch Plant Code">
                            </kendo-grid-column>
                            <kendo-grid-column field="existingMinimum" title="Existing Minimum"></kendo-grid-column>
                            <kendo-grid-column field="recommendedMinimum"
                                title="Recommended Minimum"></kendo-grid-column>
                            <kendo-grid-column field="existingMaximum" title="Existing Maximum"></kendo-grid-column>
                            <kendo-grid-column field="recommendedMaximum"
                                title="Recommended Maximum"></kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </section>
        </ng-template>
    </kendo-grid>
</section>