import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';

import { Observable } from 'rxjs';
import { MaterialNumberDto } from '../models/change-sar/material-number.model';
import { MmsChangeDataDto } from '../models/change-sar/change-data-generate-excel-item.model';
import { TypeOfChangeGridData } from '../models/change-sar/change-data-new-item-detail.model';
import { HttpParams } from '@angular/common/http';
import { MaterialJdeCodeConstant } from 'src/app/core/constants/material-jde.const';
import { InventoryLevelUpdateItemDto, InventoryLevelUpdateRowDataDto } from '../models/change-sar/inventory-level-update.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialNumberJdeService {
  constructor(private httpUtilityService: HttpUtilityService) { }

  validateMaterialNumber(data: string[]): Observable<MaterialNumberDto> {
    const materialNumbersQueryParam = data.map(tag => encodeURIComponent(tag)).join(',');
    const url = `${MaterialJdeCodeConstant.materialJde}/validate?materialNumbers=${materialNumbersQueryParam}`;
    return this.httpUtilityService.get<MaterialNumberDto>(url);
  }
  
  getMaterialNumbers(phrase: string): Observable<string[]> {
    return this.httpUtilityService.getAll(`${MaterialJdeCodeConstant.materialJde}/number` + `?phrase=${phrase}`);
  }

  getMmsChangeData(data: TypeOfChangeGridData[]): Observable<MmsChangeDataDto[]> {
    let params = new HttpParams();
    data.forEach((item, index) => {
      params = params.append(`data[${index}]`, JSON.stringify(item));
    });

    const url = `${MaterialJdeCodeConstant.materialJde}/change-data`;
    return this.httpUtilityService.get<MmsChangeDataDto[]>(url, { params });
  }

  getInventoryLevelUpdateItemData(materialNumber: string, stockingLocation: string): Observable<InventoryLevelUpdateItemDto> {
    const url = `${MaterialJdeCodeConstant.materialJde}/item-data` + `?materialNumber=${materialNumber}&stockingLocation=${stockingLocation}`;
    return this.httpUtilityService.get<InventoryLevelUpdateItemDto>(url);
  }

  getInventoryLevelUpdateRowData(materialNumber: string, stockingLocation: string): Observable<InventoryLevelUpdateRowDataDto> {
    const url = `${MaterialJdeCodeConstant.materialJde}/existing-row` + `?materialNumber=${materialNumber}&stockingLocation=${stockingLocation}`;
    return this.httpUtilityService.get<InventoryLevelUpdateRowDataDto>(url);
  }
}

